import { yupResolver } from "@hookform/resolvers/yup"
import {
  IonBackButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonRouterLink
} from "@ionic/react"
import FaButton from "@workoutgen/design-system/FaButton"
import FaGlitchTitle from "@workoutgen/design-system/FaGlitchTitle"
import FaVerticalTitle from "@workoutgen/design-system/FaVerticalTitle"
import {
  ApiResponse,
  type LoginForm
} from "@workoutgen/global-typings/kiosk-types"
import clsx from "clsx"
import { eye, eyeOff } from "ionicons/icons"
import { SyntheticEvent, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import * as yup from "yup"
import { usePostAuthLocalMutation } from "../../../api/WorkoutGenApi"
import { APP_ROUTES } from "../../../commons/constants"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import useAuth from "../../hooks/useAuth"

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const LoginPage = () => {
  const [authLocal, { isLoading }] = usePostAuthLocalMutation()

  const { login } = useAuth()

  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, "L'email doit être valide")
      .email("L'email doit être valide")
      .required("L'email est requis"),
    password: yup.string().required("Le mot de passe est requis")
  })

  const {
    handleSubmit,
    control,

    formState: { errors, isDirty, isValid }
  } = useForm<LoginForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  })

  const onSubmit = async ({ email, password }: LoginForm): Promise<void> => {
    try {
      const payload = {
        body: { identifier: email, password }
      }

      const response = await authLocal(payload)

      if ("error" in response) {
        const apiError = response as ApiResponse

        console.log(apiError.error.data.error)

        throw new Error(apiError.error.data.error.message)
      }

      await login(response.data)
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    }
  }

  const handelTogglePassword = (e: SyntheticEvent) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  return (
    <FaPageLayout
      padding={false}
      paddingHorizontal
      header={
        <IonButtons slot="start">
          <IonBackButton defaultHref={APP_ROUTES.TIMELINE} text="" />
        </IonButtons>
      }
      content={
        <div
          className={clsx(
            "flex flex-col justify-center h-[calc(100dvh-50px)]",
            "max-w-[400px] mx-auto"
          )}
        >
          <div>
            <div className="centered-flex-col gap-3 mb-8">
              <FaGlitchTitle text="WKG." />

              <h1 className="text-xl">Connection</h1>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <IonItem lines="full">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <IonInput
                      label="Email"
                      labelPlacement="floating"
                      type="email"
                      inputmode="email"
                      onIonInput={field.onChange}
                      required
                      {...field}
                    />
                  )}
                />
              </IonItem>

              <div className="h-[30px] flex items-center">
                {errors?.email?.message && (
                  <p className="text-danger text-xs ml-5" slot="error">
                    {errors?.email?.message}
                  </p>
                )}
              </div>

              <IonItem lines="full">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <IonInput
                      label="Mot de passe"
                      labelPlacement="floating"
                      type={showPassword ? "text" : "password"}
                      onIonInput={field.onChange}
                      required
                      {...field}
                    />
                  )}
                />

                <button
                  onClick={handelTogglePassword}
                  className="h-full centered-flex-col mt-[30px]"
                >
                  <IonIcon
                    slot="end"
                    className="text-black"
                    icon={showPassword ? eyeOff : eye}
                  />
                </button>
              </IonItem>

              <div className="h-[30px] flex items-center">
                {errors?.password?.message && (
                  <p className="text-danger text-xs mt-2 ml-5" slot="error">
                    {errors?.password?.message}
                  </p>
                )}
              </div>

              <div className="mt-4 relative flex flex-col items-center justify-center text-center">
                <FaButton
                  type="submit"
                  loading={isLoading}
                  disabled={!isDirty || !isValid || isLoading}
                  animated={isValid}
                  className={clsx(
                    "mb-10 mt-4",
                    isValid && "animate-blue-purple-pink-cycle-animation-fast"
                  )}
                >
                  Se connecter
                </FaButton>

                <IonRouterLink
                  routerLink={APP_ROUTES.RESET_PASSWORD_REQUEST}
                  routerDirection="root"
                  className="text-subtitle text-xs underline"
                >
                  Mot de passe oublié ?
                </IonRouterLink>
              </div>
            </form>
          </div>

          <FaVerticalTitle />
        </div>
      }
    />
  )
}

export default LoginPage
