import { ReactNode } from "react"
import { Redirect } from "react-router"
import { APP_ROUTES } from "../../constants"
import { useAppIsReady } from "../../hooks/useAppIsReady"

interface PublicRouteProps {
  children: ReactNode
  noCheck?: boolean
}

export const PublicRoute = ({ children, noCheck }: PublicRouteProps) => {
  const isAuthenticated = useAppIsReady()

  if (noCheck) return children

  return !isAuthenticated ? children : <Redirect to={APP_ROUTES.TIMELINE} />
}
