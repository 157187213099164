import { useCountDown } from "ahooks";
import clsx from "clsx";
import { memo } from "react";

export interface FaCounterProps {
  darkMode?: boolean;
  fullDate?: boolean;
  leftTime: number;
  onCounterEnd?: () => void;
}

const FaCounter = (props: FaCounterProps) => {
  const { onCounterEnd, leftTime, fullDate, darkMode } = props;

  const [countdown, formatRes] = useCountDown({
    leftTime: leftTime * 1000,
    onEnd: () => {
      onCounterEnd?.();
    },
  });

  const displayTime = fullDate
    ? formatRes.seconds >= 0
    : formatRes.minutes >= 0;

  return (
    <div
      className={clsx(
        "flex flex-col justify-center items-center font-title",
        fullDate ? "min-h-[32px]" : "min-h-[92px]",
      )}
    >
      {displayTime && (
        <div
          className={clsx(
            fullDate ? "text-xl" : "text-sm",
            "tracking-normal flex gap-1",
            !darkMode ? "ui-player-text-element" : "text-black",
          )}
        >
          {formatRes.days > 0 && (
            <div className="centered-flex-col min-w-[40px] gap-0.5">
              <span className="leading-none">{formatRes.days}</span>
              <span className="text-[0.6rem] leading-none font-text">j</span>
            </div>
          )}

          {formatRes.hours > 0 && (
            <div className="centered-flex-col min-w-[40px] gap-0.5">
              <span className="leading-none">{formatRes.hours}</span>
              <span className="text-[0.6rem] leading-none font-text">h</span>
            </div>
          )}

          {formatRes.minutes > 0 && (
            <div className="centered-flex-col min-w-[40px] gap-0.5">
              <span className="leading-none">{formatRes.minutes}</span>
              <span className="text-[0.6rem] leading-none font-text">m</span>
            </div>
          )}

          <div className="centered-flex-col min-w-[40px] gap-0.5">
            <span className="leading-none">{formatRes.seconds}</span>
            <span className="text-[0.6rem] leading-none font-text">s</span>
          </div>
        </div>
      )}

      {!fullDate && (
        <span
          className={clsx(
            "text-7xl lg:text-9xl",
            !darkMode ? "ui-player-text-element" : "text-black",
          )}
        >
          {Math.round(countdown / 1000)}
        </span>
      )}
    </div>
  );
};

export default memo(FaCounter);
