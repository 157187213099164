import FaNumberPadField, {
  NumberPadFieldChangeEvent
} from "@workoutgen/design-system/FaNumberPadField"
import { useState } from "react"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import { useValidateScrollStep } from "../../../../commons/hooks/useValidateScrollStep"
import { selectRegisterForm } from "../../../redux/registerSlice"

export const SizeStep = () => {
  const { size } = useAppSelector(selectRegisterForm)

  const [{ isValid, isDirty, value }, setCurrentValue] =
    useState<NumberPadFieldChangeEvent>({
      value: size,
      isValid: size !== undefined,
      isDirty: size !== undefined
    } as NumberPadFieldChangeEvent)

  const loading = useValidateScrollStep({
    isValid,
    isDirty,
    method: "size",
    formValue: value,
    step: 7
  })

  const handleSetValue = (value: NumberPadFieldChangeEvent) => {
    setCurrentValue(value)
  }

  return (
    <FaNumberPadField
      defaultValue={String(size ?? "")}
      onChange={handleSetValue}
      contextInfo="cm"
      loading={loading}
      rules={[
        {
          message: "Taille requise",
          rule: (value: number) => value !== 0
        },
        {
          message: `Indiquez une taille valide 120 cm`,
          rule: (value: number) => value >= 120
        },
        {
          message: `Entrez une taille valide 250 cm`,
          rule: (value: number) => value <= 250
        }
      ]}
    />
  )
}
