import AnimatedNumber from "@crossfox/react-animated-number";
import { useEffect, useState } from "react";

interface FaAnimatedNumberProps {
  value: number;
}

const FaAnimatedNumber = ({ value }: FaAnimatedNumberProps) => {
  const [currentValue, setCurrentValue] = useState(1);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return <AnimatedNumber duration={500} value={currentValue} suffix="&nbsp;" />;
};

export default FaAnimatedNumber;
