import { APP_ROUTES } from "../../commons/constants"
import RegisterPage from "../pages/RegisterPage/RegisterPage"

const registerRoutes = [
  {
    path: APP_ROUTES.GENERATE_WKG_WORKOUT,
    component: <RegisterPage />
  }
]

export default registerRoutes
