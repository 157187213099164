import {
  ApiWKGWorkout,
  FinalWorkoutSession,
  UserTracking,
} from "@workoutgen/global-typings/engine-types";
import { FaTrainingSlide } from "../FaTrainingSlide/FaTrainingSlide";
import { TimelineProvider } from "../providers/TimelineProvider";

interface FaTimelineSlideProps {
  userTracking: UserTracking[];
  lastUserTracking: UserTracking;
  session: FinalWorkoutSession;
  userWorkout: ApiWKGWorkout;
  currentDate: string;
  currentSlideIndex: number;
  isCurrentSession: boolean;
}

const FaTimelineSlide = ({
  userTracking,
  lastUserTracking,
  session,
  userWorkout,
  currentDate,
  currentSlideIndex,
  isCurrentSession,
}: FaTimelineSlideProps) => (
  <TimelineProvider
    userTracking={userTracking}
    lastUserTracking={lastUserTracking}
    session={session}
    userWorkout={userWorkout}
    currentDate={currentDate}
    isCurrentSession={isCurrentSession}
    currentSlideIndex={currentSlideIndex}
  >
    <FaTrainingSlide />
  </TimelineProvider>
);

export default FaTimelineSlide;
