import { FinalSessionExerciseParamsWithExercise } from "@workoutgen/global-typings/engine-types";
import { motion } from "framer-motion";
import Markdown from "react-markdown";
import { capitalizeFirstLetter } from "../../../utils/text";

interface FaDesktopInfosProps {
  currentExercises: FinalSessionExerciseParamsWithExercise;
}

const FaDesktopInfos = ({ currentExercises }: FaDesktopInfosProps) => {
  if (!currentExercises) return null;

  const exerciseMetaInfos = [
    ...(currentExercises?.exercise_bodyparts ?? []),
    ...(currentExercises?.exercise_categories ?? []),
    currentExercises?.is_poly_articular
      ? "poly articulaire"
      : "mono articulaire",
  ];

  return (
    <motion.div
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.3 }}
      className="flex-1 py-10 xl:pb-12 flex flex-col justify-center overflow-auto w-full lg:max-w-[700px] min-h-screen"
    >
      <div>
        <div className="flex flex-wrap gap-2 xl:gap-4">
          {exerciseMetaInfos.map((element) => (
            <div
              key={element}
              className="bg-secondary/5 px-3 py-1 xl:py-2 rounded-full"
            >
              <span className="text-sm !font-text">
                {capitalizeFirstLetter(element)}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="relative wkg-cms mt-5">
        <Markdown>{currentExercises.execution}</Markdown>
      </div>
    </motion.div>
  );
};

export default FaDesktopInfos;
