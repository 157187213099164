import { ExercisePlayerCard } from "@workoutgen/global-typings/kiosk-types";
import { useCountDown } from "ahooks";
import clsx from "clsx";
import { useEffect, useState } from "react";
import FaButton from "../../commons/FaButton/FaButton";

interface FaRecupCountdownProps {
  exercise: ExercisePlayerCard;
  onCounterEnd: () => void;
}

const FaRecupCountdown = ({
  exercise,
  onCounterEnd,
}: FaRecupCountdownProps) => {
  const initialDuration =
    exercise.category === "exercisesRecup"
      ? exercise.exerciseRecup * 1000
      : exercise.category === "setRecup"
        ? exercise.setsRecups * 1000
        : 0;

  const [leftTime, setLeftTime] = useState(initialDuration);

  const [countdown, formattedRes] = useCountDown({
    leftTime,
    onEnd: () => {
      onCounterEnd();
    },
  });

  const seconds = Math.round(countdown / 1000);

  const handleDecrease = () => {
    setLeftTime((seconds - 10) * 1000);
  };

  const handleIncrease = () => {
    if (seconds > 290) return;

    setLeftTime((seconds + 10) * 1000);
  };

  useEffect(() => {
    if (seconds <= 0) onCounterEnd();
  }, [seconds]);

  return (
    <div
      className={clsx(
        "h-full relative z-[9999] bg-white",
        "flex items-center justify-center flex-col",
      )}
    >
      <div className="flex items-center justify-center flex-col min-h-dvh">
        <h3
          className={clsx(
            "text-xl xl:text-3xl",
            formattedRes.minutes > 0 && "mb-2",
          )}
        >
          {exercise.name}
        </h3>
        {formattedRes.minutes > 0 && (
          <p className="text-sm">
            {formattedRes.minutes}m {formattedRes.seconds}s
          </p>
        )}
        <h4 className="text-7xl tracking-tight my-4">{seconds}</h4>

        <div className="grid grid-cols-2 gap-4">
          <FaButton onClick={handleDecrease}>
            <span>- 10s</span>
          </FaButton>

          <FaButton
            className={clsx(seconds > 290 && "opacity-50 cursor-not-allowed")}
            onClick={handleIncrease}
          >
            <span>+ 10s</span>
          </FaButton>
        </div>
      </div>
    </div>
  );
};

export default FaRecupCountdown;
