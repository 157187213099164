import FaNumberPadField, {
  NumberPadFieldChangeEvent
} from "@workoutgen/design-system/FaNumberPadField"
import { useState } from "react"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import { useValidateScrollStep } from "../../../../commons/hooks/useValidateScrollStep"
import { selectRegisterForm } from "../../../redux/registerSlice"

export const WeightStep = () => {
  const { weight } = useAppSelector(selectRegisterForm)

  const [{ isValid, isDirty, value }, setCurrentValue] =
    useState<NumberPadFieldChangeEvent>({
      value: weight,
      isValid: weight !== undefined,
      isDirty: weight !== undefined
    } as NumberPadFieldChangeEvent)

  const loading = useValidateScrollStep({
    isValid,
    isDirty,
    method: "weight",
    formValue: value,
    step: 6
  })

  const handleSetValue = (value: NumberPadFieldChangeEvent) => {
    setCurrentValue(value)
  }

  return (
    <FaNumberPadField
      defaultValue={String(weight ?? "")}
      onChange={handleSetValue}
      contextInfo="kg"
      loading={loading}
      rules={[
        {
          message: "Poids requis",
          rule: (value: number) => value !== 0
        },
        {
          message: `Indiquez un poids valide 40 kg`,
          rule: (value: number) => value >= 40
        },
        {
          message: `Entrez un poids valide 250 kg`,
          rule: (value: number) => value <= 250
        }
      ]}
    />
  )
}
