import clsx from "clsx";
import { useState } from "react";
import { SelectImageProps } from "./FaSelectImage.types";

const FaSelectImage = <T,>(props: SelectImageProps<T>) => {
  const { items, currentValue, onChange } = props;

  const [localeValue, setLocaleValue] = useState<T | undefined>(currentValue);

  const handleChangeLocale = async (newValue: T | undefined): Promise<void> => {
    setLocaleValue(newValue);
  };

  const handleChange = async (newValue: T | undefined): Promise<void> => {
    onChange(newValue);
  };

  return (
    <div className="relative">
      <div className="grid gap-4 grid-cols-3">
        {items.map(({ title, img, value }) => (
          <button
            key={title}
            onMouseEnter={() => handleChangeLocale(value)}
            onMouseLeave={() => handleChangeLocale(currentValue)}
            className={clsx(
              "transition-all duration-300 ease-in-out",
              "flex flex-col items-center justify-center",
              "rounded-3xl",
              "py-4 hover:scale-105 border-2",
              currentValue && currentValue === value
                ? "bg-secondary/20"
                : "bg-secondary/5",
            )}
            onClick={() => {
              handleChange(value);
            }}
          >
            <img
              className={clsx(
                "object-cover",
                "transition-all duration-200 ease-in-out hover:scale-105 hover:grayscale-0",
                "transform",
                currentValue === value && "scale-105",
                currentValue && currentValue !== value && "grayscale",
              )}
              src={img}
              alt={title}
            />
          </button>
        ))}
      </div>

      {items.map(({ description, value }, index) => (
        <div
          key={index}
          className={clsx(
            "text-center absolute mt-7",
            value === localeValue ? "opacity-100" : "opacity-0 h-0 absolute",
          )}
        >
          {description}
        </div>
      ))}
    </div>
  );
};

export default FaSelectImage;
