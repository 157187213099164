import { yupResolver } from "@hookform/resolvers/yup"
import { IonIcon, IonInput, IonItem } from "@ionic/react"
import FaButton from "@workoutgen/design-system/FaButton"
import FaSurface from "@workoutgen/design-system/FaSurface"
import {
  ApiResponse,
  type LoginForm
} from "@workoutgen/global-typings/kiosk-types"
import clsx from "clsx"
import { bulb, eye, eyeOff } from "ionicons/icons"
import { SyntheticEvent, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import * as yup from "yup"
import { usePostAuthLocalRegisterMutation } from "../../../api/WorkoutGenApi"
import { APP_ROUTES } from "../../../commons/constants"
import { useWKGData } from "../../../commons/hooks/useWKGData"
import { useSaveUserData } from "../../hooks/useSaveUserData"

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const RegisterAccount = () => {
  const [localRegister, { isLoading }] = usePostAuthLocalRegisterMutation()

  const { saveUser } = useWKGData()

  const { saveUserData } = useSaveUserData()

  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, "L'email doit être valide")
      .email("L'email doit être valide")
      .required("L'email est requis"),
    password: yup
      .string()
      .required("Le mot de passe est requis")
      .min(8, "Le mot de passe doit contenir au moins 8 caractères")
      .matches(
        /[A-Z]/,
        "Le mot de passe doit contenir au moins une lettre majuscule"
      )
      .matches(
        /[a-z]/,
        "Le mot de passe doit contenir au moins une lettre minuscule"
      )
      .matches(/[0-9]/, "Le mot de passe doit contenir au moins un chiffre")
      .matches(
        /[@$!%*?&#]/,
        "Le mot de passe doit contenir au moins un caractère spécial (@$!%*?&#)"
      )
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm<LoginForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  })

  const onRegisterSubmit = async ({
    email,
    password
  }: LoginForm): Promise<void> => {
    try {
      const username =
        email.split("@")[0] + "-" + new Date().getTime().toString().slice(-4)

      const payload = {
        body: { email, password, username: username }
      }

      const response = await localRegister(payload)

      if ("error" in response) {
        const apiError = response as ApiResponse

        throw new Error(apiError.error.data.error.message)
      }

      await saveUserData(response.data)

      await saveUser(response.data)

      toast("Votre compte a été créé avec succès", {
        position: "bottom-center"
      })
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    }
  }

  const handelTogglePassword = (e: SyntheticEvent) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  return (
    <section className="w-full flex flex-col gap-3">
      <header>
        <h2 className="text-xl">Sauvgarder mon programme</h2>
      </header>

      <FaSurface>
        <div className="centered-flex-col mb-2">
          <IonIcon className="text-xl" icon={bulb} />
        </div>

        <p>
          La borne WorkoutGen est un service totalement gratuit pour les
          utilisateurs, le compte sert uniquement à sauvegarder vos programmes
          et votre suivi.
        </p>
      </FaSurface>

      <form onSubmit={handleSubmit(onRegisterSubmit)}>
        <FaSurface>
          <IonItem lines="full">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <IonInput
                  label="Entrez votre email"
                  labelPlacement="floating"
                  type="email"
                  inputmode="email"
                  onIonInput={field.onChange}
                  required
                  {...field}
                />
              )}
            />
          </IonItem>

          <div className="h-[30px] flex items-center">
            {errors?.email?.message && (
              <p className="text-danger text-xs ml-5" slot="error">
                {errors?.email?.message}
              </p>
            )}
          </div>

          <IonItem lines="full">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <IonInput
                  label="Choisissez un mot de passe"
                  labelPlacement="floating"
                  type={showPassword ? "text" : "password"}
                  onIonInput={field.onChange}
                  required
                  {...field}
                />
              )}
            />

            <button
              onClick={handelTogglePassword}
              className="h-full centered-flex-col mt-[30px]"
            >
              <IonIcon
                slot="end"
                className="text-black"
                icon={showPassword ? eyeOff : eye}
              />
            </button>
          </IonItem>

          <div className="h-[30px] flex items-center">
            {errors?.password?.message && (
              <p className="text-danger text-xs mt-2 ml-5" slot="error">
                {errors?.password?.message}
              </p>
            )}
          </div>
        </FaSurface>

        <div className="mt-6 relative flex flex-col items-center justify-center text-center">
          <FaButton
            type="submit"
            loading={isLoading}
            disabled={!isDirty || !isValid || isLoading}
            animated={isValid}
            className={clsx(
              isValid && "animate-blue-purple-pink-cycle-animation-fast"
            )}
          >
            Créer mon compte
          </FaButton>
        </div>
      </form>

      <section className="flex flex-col gap-3 mt-3">
        <header>
          <h2 className="text-xl">Paramètres</h2>
        </header>

        <FaSurface href={APP_ROUTES.PROFILE_SETTINGS}>
          <div className="flex-1 h-full flex flex-col justify-between">
            <p>Gerer mon profil</p>
          </div>
        </FaSurface>
      </section>
    </section>
  )
}
