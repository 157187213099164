import { ExercisePlayerCard } from "@workoutgen/global-typings/kiosk-types";
import { capitalizeFirstLetter } from "../../../utils/text";
import FaPlayerUnit from "../FaPlayerUnit/FaPlayerUnit";

export interface FaPlayerExerciseInfoProps {
  exercise: ExercisePlayerCard;
  isCurrentSlide: boolean;
  isPlaying: boolean;
  workoutPart: string;
  handleTogglePlay: () => void;
}

const FaPlayerExerciseInfo = (props: FaPlayerExerciseInfoProps) => {
  const { exercise, isCurrentSlide, isPlaying, workoutPart, handleTogglePlay } =
    props;
  const { name } = exercise;

  const formattedTitle = name[0].toUpperCase() + name.slice(1);

  const contextInfos = [
    ...(workoutPart === "warmup" ? [`intensité moyenne`] : []),
    ...(workoutPart === "exercises" ? [`Intensité ${exercise.rm} RM`] : []),
    ...(exercise?.equipments ?? []),
    ...(exercise?.execution_instructions ?? []),
  ];

  return (
    <div className="text-center w-full">
      {exercise.category === "training" && (
        <FaPlayerUnit
          exercise={exercise}
          isCurrentSlide={isCurrentSlide}
          isPlaying={isPlaying}
          handleTogglePlay={handleTogglePlay}
        />
      )}

      <h1 className="text-black text-base leading-snug mt-2 mb-3 text-white font-text ui-player-text-element">
        {formattedTitle}
      </h1>

      {exercise.category === "training" && contextInfos.length > 0 && (
        <div className="centered-flex-row gap-2 mb-2 flex-wrap">
          {contextInfos.map((info) => (
            <div key={info} className="rounded-full bg-black/60">
              <p className="px-2 py-1.5 lg:px-4 lg:py-2 text-[0.7rem] xl:text-sm text-white tracking-wide">
                {capitalizeFirstLetter(info)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FaPlayerExerciseInfo;
