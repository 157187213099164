import { memo } from "react";
import seedrandom from "seedrandom";
import { thinkList } from "./thinkList";

export const FaThink = memo(() => {
  const getRandomIndex = (length: number) => {
    const rng = seedrandom(`${Date.now()}-${Math.random()}`);
    return Math.floor(rng() * length);
  };

  const randomIndex = getRandomIndex(thinkList.length);

  return <p className="text-center">{thinkList[randomIndex]}</p>;
});
