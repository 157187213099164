import {
  UserTracking,
  WorkoutPart,
} from "@workoutgen/global-typings/engine-types";
import {
  ExercisePlayerCard,
  PlayerTracking,
  UserSettings,
} from "@workoutgen/global-typings/kiosk-types";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSwiper } from "swiper/react";
import FaPlayerExerciseInfo from "../FaPlayerExerciseInfo/FaPlayerExerciseInfo";
import FaPlayerExerciseModal from "../FaPlayerExerciseModal/FaPlayerExerciseModal";
import FaPlayerProgressBar from "../FaPlayerProgressBar/FaPlayerProgressBar";
import FaPlayerToolbar from "../FaPlayerToolbar/FaPlayerToolbar";
import FaPlayerVideo from "../FaPlayerVideo/FaPlayerVideo";
import FaRecupCountdown from "../FaRecupCountdown/FaRecupCountdown";
import { useTrainingNotifications } from "../hooks/useTrainingNotifications";

export interface FaPlayerProps {
  slideIndex: number;
  exercise: ExercisePlayerCard;
  nextExercise: ExercisePlayerCard;
  currentSlide: number;
  tracking: PlayerTracking;
  workoutPart: WorkoutPart;
  userSettings: UserSettings;
  userTracking: UserTracking;
  onCloseSession: () => void;
  onUpdateUserSettings: (userSettings: UserSettings) => void;
}

const FaPlayer = (props: FaPlayerProps) => {
  const {
    slideIndex,
    exercise,
    nextExercise,
    currentSlide,
    tracking,
    userTracking,
    workoutPart,
    userSettings,
    onCloseSession,
    onUpdateUserSettings,
  } = props;

  const swiper = useSwiper();

  const handleSlideNext = () => {
    swiper.slideNext();
  };

  const isCurrentSlide = slideIndex === currentSlide;

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClose = (): void => {
    swiper.setProgress(0);
    swiper.slideTo(0);

    onCloseSession();
    setIsPlaying(false);
  };

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleOpenInfoModal = () => {
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  useTrainingNotifications({
    setsLength: exercise.sets.length,
    exercise,
    nextExercise,
    isCurrentSlide,
  });

  useEffect(() => {
    const { training_followup } = userTracking;

    const currentExerciseIndex = `current_${workoutPart}_index`;

    const currentExerciseIndexValue =
      training_followup[currentExerciseIndex as keyof typeof training_followup];

    if (
      currentExerciseIndexValue &&
      typeof currentExerciseIndexValue === "number" &&
      currentExerciseIndexValue > 0
    ) {
      swiper.slideTo(currentExerciseIndexValue);
    }
  }, []);

  const isPwa = window.matchMedia("(display-mode: standalone)").matches;

  return (
    <>
      {exercise.category === "training" && (
        <FaPlayerVideo exercise={exercise} />
      )}
      {exercise.category !== "training" && isCurrentSlide && (
        <FaRecupCountdown onCounterEnd={handleSlideNext} exercise={exercise} />
      )}
      <div
        className={clsx(
          "absolute inset-0 relative",
          "h-dvh lg:h-[100%] hide-scroll",
          "flex flex-col justify-end",
          "text-black !bg-black/10",
          "px-8",
          isPwa ? "player-to-mobile-native-bottom mb-2" : "pb-10",
          exercise.category === "training" && "player-linear-gradient",
        )}
      >
        <FaPlayerToolbar
          exercisePlayerCard={exercise}
          isCurrentSlide={isCurrentSlide}
          userSettings={userSettings}
          handleClose={handleClose}
          handleOpenInfoModal={handleOpenInfoModal}
          onUpdateUserSettings={onUpdateUserSettings}
        />

        <div className="flex flex-col items-start">
          <FaPlayerExerciseInfo
            exercise={exercise}
            workoutPart={workoutPart}
            isCurrentSlide={isCurrentSlide}
            isPlaying={isPlaying}
            handleTogglePlay={handleTogglePlay}
          />

          {exercise.category === "training" && tracking.length > 1 && (
            <FaPlayerProgressBar
              currentSlide={currentSlide}
              tracking={tracking}
            />
          )}
        </div>
      </div>

      <FaPlayerExerciseModal
        isOpen={isInfoModalOpen}
        onClose={handleOpenInfoModal}
        exercise={exercise}
      />
    </>
  );
};

export default FaPlayer;
