import clsx from "clsx";
import { SelectButtonsProps } from "./FaSelectButtons.types";

const FaSelectButtons = <T,>(props: SelectButtonsProps<T>) => {
  const { items, currentValue, cols = 2, onChange } = props;

  const handleChange = async (newValue: T): Promise<void> => {
    onChange(newValue);
  };

  return (
    <div
      className={clsx(
        "grid gap-4 md:gap-5",
        cols === 1 && "grid-cols-1",
        cols === 2 && "grid-cols-2",
        cols === 3 && "grid-cols-3",
      )}
    >
      {items.map(({ title, icon, value, slot, disabled }) => (
        <div className={clsx("relative", disabled && "mt-2")} key={value}>
          {disabled && (
            <i className="absolute -top-2 right-2 md:-right-2 not-italic text-xs z-50 bg-secondary text-white py-1 px-2 rounded-full">
              Bientôt disponible !
            </i>
          )}

          <button
            className={clsx(
              "relative group transition-all duration-300 ease-in-out w-full",
              "rounded-3xl px-4 text-lg md:text-xl border-2",
              currentValue && currentValue === value
                ? "bg-secondary/20"
                : "bg-secondary/5",
              disabled && "opacity-50 pointer-events-none",
              !slot && !icon ? "min-h-[110px]" : "min-h-[160px]",
            )}
            onClick={async () => {
              await handleChange(value as T);
            }}
            type="button"
            disabled={disabled}
          >
            <span>{title}</span>

            {slot}

            {icon !== undefined && (
              <>
                <div className="h-2" />

                <i
                  aria-label={title}
                  className={clsx(
                    "block transform transition-all duration-200 ease-in-out not-italic text-lg",
                    "group-hover:scale-150 group-hover:rotate-[360deg] group-hover:opacity-100 group-hover:grayscale-0",
                    currentValue === value && "scale-150 rotate-[360deg]",
                    currentValue &&
                      currentValue !== value &&
                      "opacity-50 grayscale",
                    "hover:opacity-100 hover:grayscale-0",
                  )}
                >
                  {icon}
                </i>
              </>
            )}
          </button>
        </div>
      ))}
    </div>
  );
};

export default FaSelectButtons;
