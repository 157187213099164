export const thinkList = [
  "Soyez impeccable avec vos paroles.",
  "Ne prenez rien personnellement.",
  "Ne faites pas de suppositions.",
  "Faites toujours de votre mieux.",
  "Respectez-vous autant que vous respectez les autres.",
  "Accueillez chaque jour comme une nouvelle opportunité.",
  "Soyez conscient de votre pouvoir intérieur.",
  "La vérité vous rend libre, même si elle est difficile.",
  "Ne vous laissez pas définir par vos erreurs.",
  "Chaque émotion a sa raison d'être.",
  "Prenez soin de votre esprit comme vous prenez soin de votre corps.",
  "La paix intérieure est un trésor inestimable.",
  "Votre réalité est façonnée par vos pensées.",
  "Exprimez votre gratitude chaque jour.",
  "L'amour commence par l'amour de soi.",
  "Soyez un modèle de bienveillance.",
  "Le respect des autres commence par le respect de soi-même.",
  "Soyez en paix avec vos imperfections.",
  "Ne vous comparez pas aux autres, vous êtes unique.",
  "Cherchez la vérité en vous-même avant de la chercher ailleurs.",
  "Apprenez à écouter avant de parler.",
  "Chaque épreuve est une opportunité déguisée.",
  "Le jugement des autres ne définit pas qui vous êtes.",
  "Soyez conscient de l'énergie que vous apportez dans une pièce.",
  "N'attendez pas que les autres changent, soyez le changement.",
  "Apprenez de vos erreurs, ne les craignez pas.",
  "Le pardon est un cadeau que vous vous faites à vous-même.",
  "La vie est trop courte pour la passer dans la colère.",
  "Apprenez à laisser partir ce qui ne vous sert plus.",
  "Soyez le gardien de votre propre paix.",
  "Prenez le temps de célébrer vos victoires, même petites.",
  "Les mots peuvent construire ou détruire, choisissez-les avec soin.",
  "Soyez présent dans chaque instant.",
  "Ne laissez pas la peur vous empêcher d'agir.",
  "Votre bonheur dépend de vous, pas des circonstances extérieures.",
  "La patience est une forme de sagesse.",
  "Faites de chaque jour un apprentissage.",
  "Ne vous attardez pas sur le passé, vivez le présent.",
  "La gentillesse est une forme de courage.",
  "Écoutez votre cœur, il connaît le chemin.",
  "Ce que vous donnez, vous le recevez en retour.",
  "Chaque personne que vous rencontrez a quelque chose à vous apprendre.",
  "La vérité ne se trouve pas dans les extrêmes, mais dans l'équilibre.",
  "Soyez le maître de vos émotions, ne les laissez pas vous contrôler.",
  "La simplicité est le chemin vers la clarté.",
  "Ce que vous cherchez à l'extérieur est souvent déjà en vous.",
  "Soyez reconnaissant pour ce que vous avez avant de désirer plus.",
  "Le respect mutuel est la base de toute relation saine.",
  "Ne gaspillez pas votre énergie à changer les autres, changez-vous d'abord.",
  "Chaque matin est une nouvelle chance de recommencer.",
  "La sérénité se trouve dans l'acceptation, pas dans la résistance.",
  "L'amour véritable commence par l'acceptation de soi.",
  "Prenez le temps de respirer et de vous recentrer.",
  "La patience est un signe de force intérieure.",
  "Apprenez à voir la beauté dans l'imperfection.",
  "Vos pensées créent votre réalité.",
  "Ce que vous pensez des autres reflète souvent ce que vous pensez de vous-même.",
  "Prenez le temps de nourrir votre âme.",
  "Soyez attentif aux petites choses, elles font la différence.",
  "Apprenez à donner sans attendre en retour.",
  "Ne laissez pas les opinions des autres façonner votre réalité.",
  "Soyez conscient de l'énergie que vous laissez derrière vous.",
  "Faites preuve de compassion envers vous-même.",
  "La paix se trouve en vous, pas à l'extérieur.",
  "Prenez la responsabilité de vos actions et de vos émotions.",
  "Votre chemin est unique, ne le comparez pas à celui des autres.",
  "Soyez ouvert aux leçons de la vie.",
  "Ce que vous tolérez vous définit.",
  "Le pardon vous libère du poids du passé.",
  "La gratitude transforme ce que vous avez en assez.",
  "Soyez l'architecte de votre propre bonheur.",
  "Votre valeur ne dépend pas de l'approbation des autres.",
  "Chaque échec est une leçon déguisée.",
  "Ne faites pas de promesses que vous ne pouvez pas tenir.",
  "Soyez fier de vos progrès, même s'ils sont lents.",
  "Votre bonheur ne dépend que de vous.",
  "Prenez le temps de comprendre avant de juger.",
  "Ce que vous donnez revient toujours, d'une manière ou d'une autre.",
  "Le changement est la seule constante, acceptez-le.",
  "Faites de la paix intérieure votre priorité.",
  "Apprenez à dire non sans culpabilité.",
  "Chaque jour est une nouvelle chance de faire mieux.",
  "La vie est un miroir, ce que vous y voyez est le reflet de votre âme.",
  "Votre passé ne vous définit pas, mais il vous façonne.",
  "Ne cherchez pas la perfection, cherchez l'authenticité.",
  "Prenez le temps de vous reposer, c'est essentiel.",
  "Soyez patient, les bonnes choses prennent du temps.",
  "Les difficultés vous rendent plus fort, pas plus faible.",
  "Ne sous-estimez jamais le pouvoir de l'intention.",
  "Faites confiance au processus de la vie.",
  "Votre bien-être émotionnel est aussi important que votre bien-être physique.",
  "Apprenez à écouter votre intuition, elle est votre meilleure guide.",
  "Ce que vous attirez dépend de ce que vous êtes prêt à recevoir.",
  "Ne vous définissez pas par vos possessions, mais par votre essence.",
  "Les relations sont un miroir de votre propre état intérieur.",
  "Soyez conscient de l'impact de vos mots et de vos actions.",
  "Faites de la gratitude une habitude quotidienne.",
  "Apprenez à accepter l'inconnu avec sérénité.",
  "La vie est une aventure, embrassez l'inattendu.",
  "N'attendez pas que quelqu'un d'autre vous rende heureux.",
  "Soyez l'exemple que vous aimeriez voir dans le monde.",
  "Prenez soin de votre corps, c'est le temple de votre esprit.",
  "Chaque petite action compte dans la grande toile de la vie.",
  "Soyez fidèle à vous-même, peu importe ce que les autres pensent.",
  "Le véritable bonheur vient de l'intérieur.",
  "Chaque jour est une opportunité de grandir et d'apprendre.",
  "Le respect est un chemin à double sens.",
  "Ne vous laissez pas définir par les erreurs du passé.",
  "Soyez l'artisan de votre propre destin.",
  "Ce que vous permettez persiste.",
  "L'amour est la réponse, quelle que soit la question.",
  "Votre voyage est unique, ne le comparez pas à celui des autres.",
  "Les réponses que vous cherchez sont souvent en vous.",
  "La confiance en soi est la clé de l'épanouissement.",
  "Soyez ouvert à ce que la vie vous apporte.",
  "Chaque jour est un cadeau, traitez-le comme tel.",
  "La peur est souvent un mensonge déguisé.",
  "Soyez responsable de votre bonheur.",
  "N'oubliez pas de rire, même dans les moments difficiles.",
  "Le changement commence toujours de l'intérieur.",
  "Apprenez à être flexible, la rigidité casse.",
  "Vos rêves méritent d'être poursuivis.",
  "Prenez le temps d'apprécier la beauté qui vous entoure.",
  "La liberté vient de l'acceptation de soi.",
  "Soyez prêt à abandonner ce qui ne vous sert plus.",
  "Le silence est parfois la réponse la plus puissante.",
  "Prenez soin de vos pensées, elles façonnent votre réalité.",
  "La patience est la clé pour surmonter les épreuves.",
  "Ce que vous imaginez peut devenir réalité.",
  "Apprenez à aimer le voyage autant que la destination.",
  "Les plus grandes transformations viennent souvent après les plus grandes épreuves.",
  "La compassion est un acte de courage.",
  "Soyez le changement que vous souhaitez voir dans le monde.",
  "Ne laissez pas les attentes des autres définir votre chemin.",
  "La joie se trouve dans les petites choses.",
  "Soyez conscient de votre propre lumière.",
  "Ne laissez pas la peur de l'échec vous empêcher d'essayer.",
  "L'amour propre est le début de toute guérison.",
  "Apprenez à marcher dans la tempête avec confiance.",
  "La sagesse vient avec l'expérience, pas avec l'âge.",
  "Ce que vous tolérez devient votre standard.",
  "La gratitude attire l'abondance.",
  "Prenez le temps de ralentir et de respirer.",
  "Apprenez à vous détacher du résultat.",
  "Ne vous laissez pas définir par les attentes des autres.",
  "L'échec est seulement un pas vers le succès.",
  "Chaque rencontre est une leçon, bonne ou mauvaise.",
  "Soyez la paix que vous souhaitez voir dans le monde.",
  "La sagesse se trouve dans le silence intérieur.",
  "Chaque action que vous entreprenez a une répercussion.",
  "Prenez soin de vos pensées, elles deviennent vos paroles.",
  "Le changement commence avec une décision.",
  "Ne jugez pas avant de comprendre.",
  "L'humilité est la porte de la sagesse.",
  "Soyez le miroir de la paix que vous cherchez.",
  "Le bonheur est un choix quotidien.",
  "Les plus grandes leçons viennent des plus grandes difficultés.",
  "Ne permettez pas aux autres de voler votre paix.",
  "Votre pouvoir réside dans le moment présent.",
  "L'authenticité est la plus belle des vertus.",
  "N'oubliez pas de prendre soin de votre esprit.",
  "Chaque jour est une nouvelle chance de créer.",
  "Apprenez à accepter ce que vous ne pouvez pas changer.",
  "L'auto-compassion est une forme d'amour profond.",
  "Ne cherchez pas la validation externe, trouvez-la en vous-même.",
  "Votre valeur est innée et n'a pas besoin d'être prouvée.",
  "La gratitude est l'aimant de l'abondance.",
  "La simplicité dans les pensées apporte la clarté.",
  "Écoutez plus, parlez moins.",
  "Ne laissez pas les doutes prendre le contrôle.",
  "Apprenez à danser sous la pluie.",
  "La sérénité se cultive dans le calme de l'âme.",
  "Votre potentiel est illimité, croyez-y.",
  "Respectez les cycles naturels de votre vie.",
  "Chaque jour est une opportunité de transformation.",
  "Vous ne pouvez pas contrôler les autres, seulement vous-même.",
  "Le respect commence avec soi-même.",
  "L'amour est la force la plus puissante de l'univers.",
  "Ne laissez pas les circonstances définir votre bonheur.",
  "Soyez reconnaissant pour les défis, ils vous font grandir.",
  "Le chemin vers la liberté est pavé de lâcher-prise.",
  "Apprenez à vous pardonner pour avancer.",
  "Votre vie est une œuvre d'art, peignez-la avec soin.",
  "Ne cherchez pas la perfection, mais la progression.",
  "Ce que vous donnez au monde vous revient multiplié.",
  "Soyez attentif à votre intuition, elle est votre guide le plus fidèle.",
  "Le respect d'autrui commence par l'écoute.",
  "Ne vous attardez pas sur ce qui ne peut être changé.",
  "La paix commence avec un simple souffle.",
  "Soyez le gardien de vos pensées et de vos paroles.",
  "Le bonheur n'est pas un objectif, c'est un état d'esprit.",
  "Prenez le temps de célébrer les petites victoires.",
  "Ce que vous permettez dans votre vie se multiplie.",
  "Apprenez à vous détacher de l'ego.",
  "La sagesse ne se trouve pas dans l'accumulation, mais dans le dépouillement.",
  "Votre chemin est unique, ne vous comparez pas aux autres.",
  "L'écoute profonde est un acte d'amour.",
  "La paix intérieure est la véritable richesse.",
  "Faites confiance à la sagesse du cœur.",
  "Les plus grands trésors sont souvent invisibles.",
  "Ne laissez pas les distractions vous éloigner de l'essentiel.",
  "Apprenez à ralentir et à apprécier chaque moment.",
  "Le pardon est une clé pour libérer votre cœur.",
  "Votre présence est plus puissante que vos mots.",
  "Soyez le calme au milieu de la tempête.",
  "Ne poursuivez pas les ombres, cherchez la lumière.",
  "Chaque instant est une chance de recommencer.",
  "Le courage commence par une simple décision.",
  "Ne sous-estimez jamais le pouvoir d'un sourire.",
  "Votre temps est précieux, ne le gaspillez pas en futilités.",
  "La gentillesse est un choix que vous pouvez faire chaque jour.",
  "Apprenez à laisser aller ce qui ne vous sert plus.",
  "Chaque personne a une leçon à vous enseigner.",
  "Le bonheur est un état d'esprit que vous cultivez.",
  "Les blessures peuvent devenir des forces.",
  "Le respect ne se demande pas, il se gagne.",
  "Apprenez à observer sans juger.",
  "L'acceptation est la première étape vers la guérison.",
  "Votre histoire est en constante évolution.",
  "La vulnérabilité est une force, pas une faiblesse.",
  "Chaque émotion est un message, écoutez-le.",
  "Ne cherchez pas à impressionner, cherchez à inspirer.",
  "La gratitude transforme tout ce qu'elle touche.",
  "Soyez curieux du monde, il a beaucoup à vous offrir.",
  "Votre lumière brille plus fort quand vous êtes authentique.",
  "Ne laissez pas les attentes des autres dicter votre chemin.",
  "L'amour est une énergie infinie, donnez sans compter.",
  "Le passé est derrière vous, regardez vers l'avant.",
  "Chaque difficulté est une opportunité déguisée.",
  "Votre cœur connaît le chemin, écoutez-le.",
  "Soyez un phare de lumière pour les autres.",
  "La résilience est la capacité de renaître après chaque chute.",
  "Faites de la compassion une priorité dans votre vie.",
  "Les mots peuvent guérir ou blesser, choisissez-les avec soin.",
  "Apprenez à dire non sans culpabilité.",
  "Le véritable pouvoir vient de l'intérieur.",
  "Lâchez prise sur ce que vous ne pouvez pas contrôler.",
  "Le moment présent est tout ce que vous avez.",
  "Soyez reconnaissant pour chaque souffle que vous prenez.",
  "La vie est une aventure, explorez-la pleinement.",
  "Ce que vous semez, vous le récolterez.",
  "Ne permettez pas à la peur de vous empêcher de grandir.",
  "Chaque jour est une nouvelle page de votre histoire.",
  "Les erreurs sont des tremplins vers la réussite.",
  "Soyez le maître de votre propre destinée.",
  "Votre lumière intérieure est votre guide le plus précieux.",
  "Le silence est parfois plus éloquent que les mots.",
  "Faites confiance à votre voyage, même si vous ne voyez pas encore le chemin.",
  "La sérénité vient de l'acceptation, pas de la résistance.",
  "La véritable sagesse se trouve dans la simplicité.",
  "Soyez le changement que vous souhaitez voir dans le monde.",
  "Les rêves sont les plans de votre âme, suivez-les.",
  "Le respect mutuel est la base de toute relation saine.",
  "Apprenez à apprécier le chemin, pas seulement la destination.",
  "Ne laissez pas le passé dicter votre avenir.",
  "L'abondance commence avec la gratitude.",
  "La clarté vient avec le calme intérieur.",
  "Ne laissez pas les doutes vous éloigner de vos rêves.",
  "Soyez un artisan de paix dans un monde de chaos.",
  "La confiance en soi est le premier pas vers la liberté.",
  "L'authenticité est la clé de la vraie connexion.",
  "Ne laissez pas les épreuves voler votre joie.",
  "La paix intérieure est le plus grand des trésors.",
  "Soyez conscient de l'impact de vos actions sur le monde.",
  "La persévérance est la clé de tout succès.",
  "Ce que vous croyez devient votre réalité.",
  "Chaque obstacle est une opportunité de croissance.",
  "L'amour de soi est la base de tout autre amour.",
  "Votre temps est limité, ne le gaspillez pas à plaire aux autres.",
  "L'univers est de votre côté, ayez confiance en lui.",
  "Prenez le temps de vous connecter à la nature.",
  "Ne laissez pas les petits problèmes obscurcir votre vision.",
  "Apprenez à écouter avant de répondre.",
  "Chaque jour est une chance de réinventer votre vie.",
  "Votre énergie attire ce que vous êtes prêt à recevoir.",
  "La vie est trop courte pour se soucier de ce que pensent les autres.",
  "Le courage est de suivre votre cœur même quand c'est difficile.",
  "Soyez fidèle à vos rêves, même quand ils semblent impossibles.",
  "Ne cherchez pas à être parfait, cherchez à être authentique.",
  "Les réponses que vous cherchez sont déjà en vous.",
  "Le respect des autres commence par l'auto-respect.",
  "Soyez conscient de la beauté de l'instant présent.",
  "Votre plus grande force est votre capacité à vous adapter.",
  "Le bonheur n'est pas dans l'accumulation, mais dans l'appréciation.",
  "Ce que vous craignez le plus est souvent ce dont vous avez le plus besoin.",
  "L'amour est un choix que vous pouvez faire chaque jour.",
  "Le calme intérieur est la clé de la clarté.",
  "San je t'aime",
  "Votre voyage est unique, ne le comparez pas aux autres.",
  "La gentillesse est une force que rien ne peut briser.",
  "Ne laissez pas les opinions des autres affecter votre paix.",
  "Chaque échec est une leçon sur la voie du succès.",
  "La patience est la clé pour naviguer les eaux agitées.",
  "Soyez reconnaissant pour ce que vous avez déjà.",
  "Lâchez prise sur ce qui ne vous apporte plus de joie.",
  "Les opportunités se présentent à ceux qui sont prêts à les voir.",
  "Chaque jour est une nouvelle chance de faire mieux.",
  "Ne laissez pas la peur de l'échec vous arrêter.",
  "Votre cœur sait ce qui est juste pour vous, suivez-le.",
  "Soyez le maître de vos pensées et de vos émotions.",
  "Apprenez à écouter la sagesse de votre corps.",
  "Les choses les plus simples sont souvent les plus profondes.",
  "Le bonheur se trouve dans l'équilibre, pas dans l'extrême.",
  "La gratitude transforme l'ordinaire en extraordinaire.",
  "Prenez soin de votre esprit, il est le maître de votre réalité.",
  "Soyez la lumière que vous cherchez dans le monde.",
  "Chaque jour est une nouvelle chance de briller.",
];
