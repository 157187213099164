import clsx from "clsx";
import { motion } from "framer-motion";
import FaTitle from "../FaTitle/FaTitle";

const FaVerticalTitle = () => {
  return (
    <motion.aside
      className={clsx(
        "hidden lg:block absolute right-0 top-1/2 transform -translate-y-1/2 -mr-10",
      )}
      initial={{ translateY: -100, rotate: 90, opacity: 0 }}
      animate={{ translateY: 0, rotate: 90, opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <FaTitle
        as="h1"
        size="small"
        text="WorkoutGen ™"
        className="tracking-[-1px]"
      />
    </motion.aside>
  );
};

export default FaVerticalTitle;
