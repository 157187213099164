import { BeforeInstallPromptEvent } from "@workoutgen/global-typings/kiosk-types"
import { useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { setPwaPromptEvent } from "../redux/globalSlice"

export const usePwaInstall = () => {
  const dispatch = useDispatch()

  const handleBeforeInstallPrompt: EventListener = (e: Event) => {
    e.preventDefault()

    dispatch(setPwaPromptEvent(e as BeforeInstallPromptEvent))
  }

  useLayoutEffect(() => {
    // Block arc browser
    if (
      getComputedStyle(document.documentElement).getPropertyValue(
        "--arc-palette-title"
      )
    )
      return

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      )
    }
  }, [])
}
