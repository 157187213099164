import ButtonItems from "@workoutgen/design-system/ButtonItems"
import FaSelectButtons from "@workoutgen/design-system/FaSelectButtons"
import { UserLevel } from "@workoutgen/global-typings/engine-types"
import { RegisterStepProps } from "@workoutgen/global-typings/kiosk-types"
import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setCurrentStep,
  setUserLevel
} from "../../../redux/registerSlice"

export const LevelStep = (props: RegisterStepProps<ButtonItems<UserLevel>>) => {
  const { texts } = props

  const dispatch = useAppDispatch()

  const { userLevel } = useAppSelector(selectRegisterForm)

  const handleLevel = (level: UserLevel): void => {
    if (level === undefined) return

    dispatch(setUserLevel(level))

    dispatch(setCurrentStep(8))
  }

  return (
    <FaSelectButtons<UserLevel>
      items={texts}
      currentValue={userLevel}
      onChange={handleLevel}
    />
  )
}
