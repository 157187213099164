import clsx from "clsx"
import { ReactNode, useEffect } from "react"
import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import { useScrollTo } from "../../../../commons/hooks/useScrollTo"
import {
  selectRegisterForm,
  setCurrentStep,
  setLocale,
  setPractice
} from "../../../redux/registerSlice"

interface RegisterContainerProps {
  children: ReactNode
}

export const RegisterContainer = ({ children }: RegisterContainerProps) => {
  const dispatch = useAppDispatch()

  const { currentStep, stepValidation, focusedStep, formEnded } =
    useAppSelector(selectRegisterForm)

  useScrollTo({ currentStep, stepValidation, focusedStep, formEnded })

  useEffect(() => {
    // Add real local when app will be available in other languages
    dispatch(setLocale("fr"))
    // remove this when other practices are available
    dispatch(setPractice("indoor"))

    const currentStepIndex = Object.values(stepValidation).findIndex(
      (step) => step === false
    )

    dispatch(setCurrentStep(currentStep === -1 ? 0 : currentStepIndex))
  }, [])

  return (
    <article
      className={clsx(
        "md:max-w-[500px] md:mx-auto",
        "flex flex-col scroll-container"
      )}
    >
      {children}
    </article>
  )
}
