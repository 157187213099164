import { ExercisePlayerCard } from "@workoutgen/global-typings/kiosk-types";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../utils/text";

interface TrainingNotificationsProps {
  setsLength: number;
  exercise: ExercisePlayerCard;
  nextExercise: ExercisePlayerCard;
  isCurrentSlide: boolean;
}

export const useTrainingNotifications = ({
  setsLength,
  exercise,
  nextExercise,
  isCurrentSlide,
}: TrainingNotificationsProps) => {
  useEffect(() => {
    if (
      setsLength > 1 &&
      nextExercise &&
      isCurrentSlide &&
      exercise.name !== nextExercise.name &&
      exercise.category === "training" &&
      nextExercise.category === "training"
    ) {
      toast(
        <div>
          <p className="text-black text-center font-text text-sm">
            Prochain exercice :
          </p>

          <p className="text-black font-title text-center font-text text-base">
            {capitalizeFirstLetter(nextExercise.name)} !
          </p>
        </div>,
        {
          autoClose: 5000,
          draggable: true,
          pauseOnHover: false,
        },
      );
    }
  }, [isCurrentSlide]);
};
