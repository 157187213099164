import Spline from "@splinetool/react-spline";
import clsx from "clsx";
import { useState } from "react";

interface FaHomeAnimationProps {
  onLoad?: () => void;
}

const FaHomeAnimation = (props: FaHomeAnimationProps) => {
  const { onLoad } = props;

  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setTimeout(() => {
      setIsLoaded(true);
      onLoad?.();
    }, 180);
  };

  return (
    <div
      className={clsx(
        "relative",
        "small-phone:w-[150px] small-phone:h-[150px]",
        "w-[180px] h-[180px]",
        "md:w-[200px] md:h-[200px]",
      )}
    >
      <Spline
        onLoad={handleLoad}
        scene="https://prod.spline.design/C0T5J88widMFkT7E/scene.splinecode"
        className={clsx(!isLoaded && "opacity-0")}
      />
    </div>
  );
};

export default FaHomeAnimation;
