import { toast } from "react-toastify"
import {
  ProfileRequest,
  usePostProfilesMutation,
  usePostUserTrackingsMutation,
  UsersPermissionsUserRegistration,
  UserTrackingRequest
} from "../../../features/api/WorkoutGenApi"
import { useAppSelector } from "../../../features/commons/hooks/useAppSelector"
import { useWKGData } from "../../../features/commons/hooks/useWKGData"
import { selectGlobal } from "../../../features/commons/redux/globalSlice"
import { selectAuth } from "../redux/authentication"

export const useSaveUserData = () => {
  const { saveUser, saveUserSettings } = useWKGData()

  const { profile } = useAppSelector(selectAuth)
  const { userTracking, userSettings } = useAppSelector(selectGlobal)

  const [postProfiles] = usePostProfilesMutation()
  const [postUserTrackings] = usePostUserTrackingsMutation()

  const errorMessage =
    "Une erreur est survenue lors de l'enregistrement de vos données"

  const saveUserData = async (data: UsersPermissionsUserRegistration) => {
    try {
      const { user, jwt } = data

      if (!user || !jwt) {
        toast.error(errorMessage)
        console.error("No user or jwt")
        return
      }

      await saveUser(data)

      if (profile) {
        try {
          const non_available_equipments = profile?.non_available_equipments
            ?.length
            ? profile.non_available_equipments.flatMap((equipment) =>
                typeof equipment.documentId === "string"
                  ? equipment.documentId
                  : []
              )
            : undefined

          const profileRequest: ProfileRequest = {
            data: {
              ...profile,
              non_available_equipments,
              localizations: profile?.localizations?.map((loc) =>
                typeof loc === "object" ? loc.documentId || loc.id : loc
              ) as (string | number)[] | undefined,
              user: user.id
            }
          }

          await postProfiles({ profileRequest })
        } catch (error) {
          console.error(error)
          toast.error(errorMessage)
        }
      }

      if (userTracking) {
        try {
          const userTrackingRequest: UserTrackingRequest = {
            data: {
              ...userTracking[0],
              user: user.id
            }
          }
          postUserTrackings({
            userTrackingRequest
          })
        } catch (error) {
          console.error(error)
          toast.error(errorMessage)
        }
      }

      saveUserSettings({
        ...userSettings,
        speedSpeech: userSettings?.speedSpeech ?? 1,
        playerTracking: userSettings?.playerTracking ?? {
          join_unlocking: 0,
          warmup: 0,
          exercises: 0,
          abds: 0,
          stretching: 0
        }
      })
    } catch (error) {
      console.error(error)
      toast.error(errorMessage)
    }
  }

  return { saveUserData }
}
