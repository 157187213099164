export const DAY_DICT = {
  0: "Lundi",
  1: "Mardi",
  2: "Mercredi",
  3: "Jeudi",
  4: "Vendredi",
  5: "Samedi",
  6: "Dimanche",
};

export const LEVEL_DICT = {
  beginner: "débutant",
  intermediate: "Moyen",
  advanced: "avancé",
};

export const TRAINING_TYPE_DICT = {
  alternate_circuit_training: "circuit alterné",
  grouped_circuit_training: "circuit groupé",
};

export const TRAINING_FORMAT_DICT = {
  full_body: "full body",
  split_body: "split body",
  half_body: "half body",
};

export const CYCLES_DICT = {
  toning: "tonification",
  hypertrophy: "hypertrophie",
  mass_gain: "prise de masse",
  reinforcement: "renforcement",
  cardio1: "cardio 1",
  cardio2: "cardio 2",
  cardio3: "cardio 3",
};

export const TRAINING_POINT_DICT = {
  join_unlocking: 15,
  warmup: 15,
  exercises: 40,
  stretching: 15,
  abds: 15,
};

export const WORKOUT_PARTS_DICT = {
  exercises: "Session pricipale",
  stretching: "Etirements",
  join_unlocking: "Déverrouillage articulaire",
  warmup: "Echauffement",
  abds: "Abdos",
};

export const WORKOUT_PARTS_DICT_FULL = {
  exercises: "la Session principale",
  stretching: "les étirements",
  join_unlocking: "les déverrouillages articulaires",
  warmup: "l'échauffement",
  abds: "les abdos",
};

export const TROPHY_INTENSITY_DICT = {
  20: "bg-secondary/35",
  40: "bg-secondary/50",
  60: "bg-secondary/70",
  80: "bg-secondary/90",
  100: "bg-secondary",
};

export enum APP_ROUTES {
  HOME = "/",
  LOGIN = "/login",
  GENERATE_WKG_WORKOUT = "/generate-wkg-workout",
  TRAINING_PREVIEW = "/training-preview",
  PLAYER = "/player",
  TIMELINE = "/timeline",
  JOURNEY = "/journey",
  PROFILE_SETTINGS = "/profile-settings",
  TRAINING_SETTINGS = "/training-settings",
  RESET_PASSWORD_REQUEST = "/reset-password-request",
  RESET_PASSWORD = "/reset-password",
}

export const SECRETS_FOLDER = "secrets";

export const FILE_SYSTEM_KEYS = {
  SECRETS: {
    USER_WORKOUT: `${SECRETS_FOLDER}/userWorkout.json`,
    USER_PATH: `${SECRETS_FOLDER}/userPath.json`,
    USER: `${SECRETS_FOLDER}/user.json`,
    USER_TRACKING: `${SECRETS_FOLDER}/userTracking.json`,
    CURRENT_WORKOUT_PROGRESS: `${SECRETS_FOLDER}/currentWorkoutProgress.json`,
    PROFILE: `${SECRETS_FOLDER}/profile.json`,
    USER_SETTINGS: `${SECRETS_FOLDER}/userSettings.json`,
  },
};
