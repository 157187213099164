/**
 * Calcule le niveau de l'utilisateur en fonction du total des points accumulés.
 * La difficulté augmente avec chaque niveau (progression avec exposant 1.5).
 * @param points - Total des points accumulés par l'utilisateur.
 * @returns Le niveau de l'utilisateur.
 */
export function calculateUserLevel(points: number): number {
  let level = 0;
  let totalPointsRequired = 0;

  const basePoints = 1.5; // Ajusté pour atteindre le niveau 100 avec 60 000 points
  const difficultyFactor = 1.5; // Exposant pour augmenter la difficulté

  while (points >= totalPointsRequired) {
    level += 1;
    totalPointsRequired += basePoints * Math.pow(level, difficultyFactor);
  }

  return level - 1;
}

export function maxLevelsFromSessions(totalSessions: number): number {
  const points = totalSessions * 100;

  return calculateUserLevel(points);
}

/**
 * Calcule le pourcentage de niveaux gagnés par rapport au total des niveaux possibles en fonction du nombre de sessions.
 * La difficulté augmente avec chaque niveau (progression avec exposant 1.5).
 * @param userLevel - Le niveau actuel de l'utilisateur.
 * @param sessionCount - Le nombre total de sessions disponibles.
 * @param maxPointsPerSession - Le nombre maximum de points qu'un utilisateur peut gagner par session (par défaut 100).
 * @returns Le pourcentage de niveaux gagnés (de 0% à 100%).
 */
export function calculateLevelProgressPercentage(
  userLevel: number,
  sessionCount: number,
  maxPointsPerSession: number = 100,
): number {
  const totalPossiblePoints = sessionCount * maxPointsPerSession;

  // Calcul du nombre total de niveaux possibles avec les points totaux
  let level = 0;
  let totalPointsRequired = 0;
  const basePoints = 1.5; // Doit être le même que dans calculateUserLevel
  const difficultyFactor = 1.5; // Doit être le même que dans calculateUserLevel

  while (totalPointsRequired <= totalPossiblePoints) {
    level += 1;
    totalPointsRequired += basePoints * Math.pow(level, difficultyFactor);
  }

  const totalPossibleLevels = level - 1;

  const percentage = (userLevel / totalPossibleLevels) * 100;

  return percentage > 100 ? 100 : Math.min(Math.ceil(percentage), 100);
}
