import { type Action, configureStore, type ThunkAction } from "@reduxjs/toolkit"
import { baseApi } from "../../api/baseApi"
import authReducer from "../../authentification/redux/authentication"
import playerReducer from "../../player/redux/playerSlice"
import registerReducer from "../../register/redux/registerSlice"
import globalReducer from "./globalSlice"

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,

    global: globalReducer,
    auth: authReducer,
    player: playerReducer,
    register: registerReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(baseApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
