import ButtonItems from "@workoutgen/design-system/ButtonItems"
import FaSelectButtons from "@workoutgen/design-system/FaSelectButtons"
import { WorkoutTarget } from "@workoutgen/global-typings/engine-types"
import { RegisterStepProps } from "@workoutgen/global-typings/kiosk-types"
import { useCallback } from "react"
import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setCurrentStep,
  setTarget
} from "../../../redux/registerSlice"

export const TargetStep = (
  props: RegisterStepProps<ButtonItems<WorkoutTarget>>
) => {
  const { texts } = props

  const dispatch = useAppDispatch()

  const { workoutTarget } = useAppSelector(selectRegisterForm)

  const handleTargetChange = useCallback(
    (target: WorkoutTarget): void => {
      if (target === undefined) return

      dispatch(setTarget(target))

      dispatch(setCurrentStep(1))
    },
    [dispatch]
  )

  return (
    <FaSelectButtons<WorkoutTarget>
      items={texts}
      currentValue={workoutTarget}
      onChange={handleTargetChange}
    />
  )
}
