import { useCallback } from "react"
import { toast } from "react-toastify"
import {
  useDeleteDeleteWkgWorkoutByUserIdMutation,
  useDeleteUsersByIdMutation,
  UsersPermissionsUserRegistration
} from "../../api/WorkoutGenApi"
import { useAppDispatch } from "../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../commons/hooks/useAppSelector"
import { useWKGData } from "../../commons/hooks/useWKGData"
import { setAppLoading, setUserTracking } from "../../commons/redux/globalSlice"
import { selectAuth } from "../redux/authentication"

const useAuth = () => {
  const dispatch = useAppDispatch()

  const {
    removeAllUserData,
    deleteUser,
    getUserTracking,
    getUserWorkout,
    saveUser,
    getProfile,
    getUserSettings
  } = useWKGData()

  const [deleteUsers] = useDeleteUsersByIdMutation()
  const [deleteDeleteWkgWorkoutByUserId] =
    useDeleteDeleteWkgWorkoutByUserIdMutation()

  const { user } = useAppSelector(selectAuth)

  const logout = async () => {
    try {
      dispatch(setAppLoading(true))

      await deleteUser()
    } catch (error) {
      console.error(error)

      toast.error(
        "Une erreur est survenue lors de la déconnexion. Veuillez réessayer."
      )
    } finally {
      setTimeout(() => {
        dispatch(setAppLoading(false))
      }, 1500)
    }
  }

  const login = useCallback(
    async (data: UsersPermissionsUserRegistration) => {
      try {
        dispatch(setAppLoading(true))

        await Promise.all([
          getUserTracking(),
          getUserWorkout(),
          getProfile(),
          getUserSettings()
        ])

        await saveUser(data)
      } catch (error) {
        console.error(error)

        toast.error(
          "Une erreur est survenue lors de la connexion. Veuillez réessayer."
        )
      } finally {
        dispatch(setAppLoading(false))
      }
    },
    [getUserTracking, getUserWorkout, getProfile, getUserSettings, saveUser]
  )

  const deletePreviewWorkout = async () => {
    try {
      dispatch(setAppLoading(true))

      await removeAllUserData()
    } catch (error) {
      console.error(error)

      toast.error(
        "Une erreur est survenue lors de la suppression de l'entraînement de prévisualisation. Veuillez réessayer."
      )
    } finally {
      setTimeout(() => {
        dispatch(setAppLoading(false))
      }, 1500)
    }
  }

  const deleteAllAccountData = useCallback(
    async (data: UsersPermissionsUserRegistration) => {
      try {
        if (!data.user?.id)
          throw new Error("Les données de l'utilisateur sont manquantes")

        dispatch(setAppLoading(true))

        const deleteWorkoutResponse = await deleteDeleteWkgWorkoutByUserId({
          userId: String(data.user.id)
        })

        if ("error" in deleteWorkoutResponse) {
          throw new Error(
            "Une erreur est survenue lors de la suppression de vos données"
          )
        }

        const deleteUserResponse = await deleteUsers({
          id: String(data.user.id)
        })

        if ("error" in deleteUserResponse) {
          throw new Error(
            "Une erreur est survenue lors de la suppression de vos données"
          )
        }

        await removeAllUserData()
      } catch (error) {
        console.error(error)
        const e = error as Error

        toast.error(e.message)
      } finally {
        setTimeout(() => {
          dispatch(setAppLoading(false))
        }, 1500)
      }
    },
    [deleteUsers, removeAllUserData]
  )

  return {
    user,
    setUserTracking,
    logout,
    login,
    deleteAllAccountData,
    deletePreviewWorkout
  }
}

export default useAuth
