import { IonButton, IonIcon } from "@ionic/react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { close } from "ionicons/icons";
import { useEffect, type SyntheticEvent } from "react";
import { createPortal } from "react-dom";

export interface FaModalProps {
  children: JSX.Element;
  open: boolean;
  className?: string;
  hideCloseButton?: boolean;
  noAnimations?: boolean;
  onClose?: () => void;
}

const FaModal = (props: FaModalProps) => {
  const { children, open, className, onClose, hideCloseButton, noAnimations } =
    props;

  const handleStopPropagation = (event: SyntheticEvent): void => {
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  return (
    open &&
    createPortal(
      <>
        {noAnimations ? (
          <section
            className={clsx(
              "fixed inset-0 z-[9999] bg-white transform",
              "min-h-dvh overflow-auto",
              className,
            )}
          >
            {children}
          </section>
        ) : (
          <motion.section
            key={JSON.stringify(open)}
            initial={{ translateY: "100dvh" }}
            animate={{ translateY: 0 }}
            exit={{ translateY: "100dvh" }}
            transition={{ duration: 0.2 }}
            className={clsx(
              "fixed inset-0 z-[9999] bg-white transform",
              "min-h-dvh overflow-auto",
              className,
            )}
          >
            {!hideCloseButton && (
              <IonButton
                shape="round"
                onClick={onClose}
                className={clsx(
                  "fixed left-1 top-1 md:left-2 md:top-2 backdrop-blur-3x",
                  "transition-all duration-200 ease-in-out hover:rotate-[360deg]",
                )}
              >
                <IonIcon icon={close}></IonIcon>
              </IonButton>
            )}

            <div onClick={handleStopPropagation}>{children}</div>
          </motion.section>
        )}
      </>,
      document.body,
    )
  );
};

export default FaModal;
