import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import FaAppLoader from "@workoutgen/design-system/FaAppLoader"
import { Redirect, Route } from "react-router"
import { Slide, ToastContainer } from "react-toastify"
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute"
import { PublicRoute } from "./components/PublicRoute/PublicRoute"
import { APP_ROUTES } from "./constants"
import { useAppSelector } from "./hooks/useAppSelector"
import { useLoadApp } from "./hooks/useLoadApp"
import { usePwaInstall } from "./hooks/usePwaInstall"
import { useUpdateApp } from "./hooks/useUpdateApp"
import { selectGlobal } from "./redux/globalSlice"
import { privateRoutes, publicRoutes } from "./routes"

import "@fontsource/inter/900.css"
import "@fontsource/poppins/600.css"

import "@ionic/react/css/core.css"
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

import "./assets/css/tailwind.css"

import "./assets/css/global.css"
import "./assets/css/ionic-overrides.css"

import "@ionic/react/css/padding.css"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/virtual"

import "react-toastify/dist/ReactToastify.css"

import "./assets/css/theme.css"

setupIonicReact({
  mode: "ios"
})

const App = () => {
  const { appLoading } = useAppSelector(selectGlobal)

  useUpdateApp()

  usePwaInstall()

  useLoadApp()

  return (
    <>
      <FaAppLoader open={appLoading}>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              {publicRoutes.map(({ path, component }) => (
                <Route key={path} path={path} exact>
                  <PublicRoute
                    noCheck={[APP_ROUTES.RESET_PASSWORD_REQUEST].includes(path)}
                  >
                    {component}
                  </PublicRoute>
                </Route>
              ))}

              {privateRoutes.map(({ path, component }) => (
                <Route key={path} path={path} exact>
                  <PrivateRoute>{component}</PrivateRoute>
                </Route>
              ))}

              <Redirect to={APP_ROUTES.HOME} />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </FaAppLoader>

      <ToastContainer position="bottom-center" transition={Slide} />
    </>
  )
}

export default App
