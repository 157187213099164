import FaTimelineSlide from "@workoutgen/design-system/FaTimelineSlide"
import {
  ApiWKGWorkout,
  UserTracking
} from "@workoutgen/global-typings/engine-types"
import clsx from "clsx"
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react"
import { RegisterAccount } from "../../../../../authentification/pages/RegisterAccount/RegisterAccount"
import { getCurrentStringDate } from "../../../../../commons/utils/getCurrentStringDate"

interface TimelineProps {
  userWorkout: ApiWKGWorkout
  userTracking: UserTracking[]
  lastUserTracking: UserTracking
  shownOnboarding?: boolean
  currentSlideIndex: number
  isCurrentSession: boolean
  handleSetSwiper: (swiper: SwiperClass) => void
  handleSetSession: (index: number) => void
}

export const Timeline = ({
  userWorkout,
  userTracking,
  lastUserTracking,
  currentSlideIndex,
  shownOnboarding,
  isCurrentSession,
  handleSetSwiper,
  handleSetSession
}: TimelineProps) => {
  const { workout } = userWorkout

  const { sessions } = workout

  return (
    <Swiper
      allowTouchMove
      grabCursor
      spaceBetween={50}
      slidesPerView={1}
      autoHeight
      onSwiper={handleSetSwiper}
      onSlideChange={({ realIndex }) => handleSetSession(realIndex)}
    >
      {sessions.map((session, index) => (
        <SwiperSlide
          key={session.sessionIndex + index}
          className={clsx(
            "!min-h-[calc(100dvh-65px)] centered-flex-col ion d-padding-horizontal mt-1.5 px-3",
            lastUserTracking.session_index === 2 &&
              shownOnboarding &&
              "swiper-no-swiping"
          )}
        >
          {shownOnboarding ? (
            <RegisterAccount />
          ) : (
            <FaTimelineSlide
              userTracking={userTracking}
              lastUserTracking={lastUserTracking}
              session={session}
              userWorkout={userWorkout}
              currentSlideIndex={currentSlideIndex}
              isCurrentSession={isCurrentSession}
              currentDate={getCurrentStringDate(index)}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
