import { IonIcon } from "@ionic/react";
import {
  ApiWKGWorkout,
  UserTracking,
} from "@workoutgen/global-typings/engine-types";
import clsx from "clsx";
import { addDays, format } from "date-fns";
import { fr } from "date-fns/locale";
import { bed, flash } from "ionicons/icons";
import { useMemo } from "react";

interface FaTimelineScheduleProps {
  userWorkout: ApiWKGWorkout;
  userTracking: UserTracking;
  currentSlideIndex: number;
  shownOnboarding?: boolean;
  onSlideTo: (index: number) => void;
}

const FaTimelineSchedule = ({
  userWorkout,
  userTracking,
  shownOnboarding,
  currentSlideIndex,
  onSlideTo,
}: FaTimelineScheduleProps) => {
  const { workout } = userWorkout;

  const { session_index } = userTracking;

  const currentSessionIndex = (session_index - 1) % 7;

  const { sessions, schedule } = workout;

  const generateSchedule = useMemo(() => {
    const currentDate = new Date();

    return Array.from({ length: 7 }, (_, index) => {
      const newDate = addDays(currentDate, index);

      const day = format(newDate, "EEEE", { locale: fr })
        .charAt(0)
        .toLocaleUpperCase();

      return {
        day,
        isRest: schedule[index],
        sessionIndex: (sessions[index].sessionIndex - 1) % 7,
      };
    });
  }, [schedule]);

  const handleSlideToSession = (index: number) => {
    onSlideTo(index);
  };

  return (
    <header
      className={clsx(
        "grid grid-cols-7 gap-5 py-1",
        shownOnboarding && "pointer-events-none",
      )}
    >
      {generateSchedule.map(({ day, isRest, sessionIndex }, index) => (
        <div
          key={day + index}
          className={clsx("centered-flex-col")}
          onClick={() => handleSlideToSession(index)}
        >
          <div className="centered-flex-col gap-1">
            <span
              className={clsx(
                "font-title text-sm",
                sessionIndex < currentSessionIndex && "text-secondary",
                currentSessionIndex === sessionIndex &&
                  "animate-text-full-color-cycle-fast",
              )}
            >
              {day}
            </span>

            <span>
              <IonIcon
                className={clsx(
                  "text-lg",
                  sessionIndex < currentSessionIndex && "text-secondary",
                  currentSessionIndex === sessionIndex &&
                    "animate-text-full-color-cycle-fast",
                )}
                icon={isRest ? flash : bed}
              />
            </span>

            <div
              className={clsx(
                currentSlideIndex === index ? "opacity-100" : "opacity-0",
                "h-[5px] w-[5px] rounded-full -mt-0.5 mb-0.5",
                sessionIndex < currentSessionIndex && "bg-secondary",
                currentSessionIndex === sessionIndex &&
                  "animate-blue-purple-pink-cycle-animation-fast",
                sessionIndex > currentSessionIndex && "bg-black",
              )}
            />
          </div>
        </div>
      ))}
    </header>
  );
};

export default FaTimelineSchedule;
