import {
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { ExercisePlayerCard } from "@workoutgen/global-typings/kiosk-types";
import { chevronBack } from "ionicons/icons";
import Markdown from "react-markdown";
import { capitalizeFirstLetter } from "../../../utils/text";

export interface FaPlayerExerciseModalProps {
  isOpen: boolean;
  exercise: ExercisePlayerCard;
  onClose: () => void;
}

const FaPlayerExerciseModal = (props: FaPlayerExerciseModalProps) => {
  const { isOpen, exercise, onClose } = props;

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader translucent className="ion-no-border" collapse="fade">
        <IonToolbar className="centered-flex-col px-3 min-h-[50px]">
          <button onClick={onClose} className="flex items-center gap-1">
            <IonIcon className="text-black" icon={chevronBack} />{" "}
            {capitalizeFirstLetter(exercise.name)}
          </button>
        </IonToolbar>
      </IonHeader>

      <IonContent className="player-to-mobile-native-top player-to-mobile-native-bottom">
        <section className="mt-6 px-3 xl:px-5 flex gap-3 xl:gap-5 pb-5 flex justify-center">
          <div className="flex justify-center flex-col items-center gap-2">
            <IonImg
              className="rounded-xl w-full object-cover"
              src={exercise.move_start.url}
              alt="move start"
            />

            <i className="mt-1 text-sm xl:text-base not-italic">
              Position initale
            </i>
          </div>

          <div className="flex justify-center flex-col items-center gap-2">
            <IonImg
              className="rounded-xl w-full object-cover"
              src={exercise.move_end.url}
              alt="move start"
            />

            <i className="mt-1 text-sm xl:text-base not-italic">
              Position finale
            </i>
          </div>
        </section>

        <div className="relative wkg-cms mt-5 px-3 mb-5">
          <Markdown>{exercise.execution}</Markdown>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default FaPlayerExerciseModal;
