import {
  IonAlert,
  IonAvatar,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";
import {
  CurrentTrainingState,
  FinalSessionExerciseParamsWithExercise,
  UserTracking,
  WorkoutPart,
} from "@workoutgen/global-typings/engine-types";
import { UserSettings } from "@workoutgen/global-typings/kiosk-types";
import clsx from "clsx";
import { checkmark, close, reload } from "ionicons/icons";
import { calculateTrainingDuration } from "../../../utils/calculateTrainingDuration";
import { getThumbnailUrl } from "../../../utils/getThumbnailUrl";
import FaBodypartCard from "../../timeline/FaBodypartCard/FaBodypartCard";
import FaButton from "../FaButton/FaButton";
import FaChip from "../FaChip/FaChip";
import FaSurface from "../FaSurface/FaSurface";

interface FaSessionPadProps {
  devMode: boolean;
  state: CurrentTrainingState;
  title: string;
  nbExercises: number;
  content: FinalSessionExerciseParamsWithExercise[];
  workoutGenPoints: number;
  workoutPart: WorkoutPart;
  userSettings: UserSettings;
  passTitle: string;
  endedBlocks: number;
  userTracking: UserTracking;
  index: number;
  description: string;
  onReactivateBloc: (workoutPart: WorkoutPart) => void;
  onValidateBloc: (workoutPart: WorkoutPart) => void;
}

const FaSessionPad = ({
  devMode,
  state,
  title,
  nbExercises,
  content,
  description,
  userSettings,
  passTitle,
  endedBlocks,
  userTracking,
  workoutGenPoints,
  workoutPart,
  index,
  onReactivateBloc,
  onValidateBloc,
}: FaSessionPadProps) => {
  const isCancelled = state === "cancelled";
  const isTodo = state === "todo";
  const isDone = state === "done";

  const alertIdReactivate = `reactivate-${workoutPart}`;
  const alertIdRedo = `redo-${workoutPart}`;

  const isHidden = isCancelled || isDone || (index > endedBlocks && isTodo);

  const position = userSettings?.playerTracking[workoutPart] ?? 0;

  return (
    <FaSurface>
      <div
        id={"bloc-" + workoutPart}
        className={clsx(
          "relative transition-all duration-200",
          "hover:border-secondary",
          "flex flex-col ",
          "mb-3",
          "gap-6",
        )}
      >
        <h4
          className={clsx(
            "text-xs leading-none absolute top-0 left-0",
            isCancelled && "opacity-30",
          )}
        >
          Bloc {index + 1}
        </h4>

        <div
          className={clsx(
            "absolute top-0 right-0 w-2 h-2 rounded-full",
            isTodo && "animate-blue-purple-pink-cycle-animation-fast",
            isDone && "bg-success",
            isCancelled && "bg-danger",
          )}
        />

        <div>
          <div
            className={clsx(
              "relative flex items-center justify-center flex-col",
            )}
          >
            <div className={clsx("w-[250px] pt-5", isHidden && "opacity-30")}>
              <FaBodypartCard
                bodyparts={content.map((exercise) => exercise.bodypart)}
                trainingFormat="split_body"
                realMuscles
              />
            </div>
          </div>
        </div>

        <div
          className={clsx("centered-flex-col gap-2", isHidden && "opacity-30")}
        >
          <div className="centered-flex-col gap-1">
            <div className={clsx("flex flex-col items-center gap-2")}>
              {isDone && (
                <p className="text-sm text-center animate-text-full-color-cycle-fast">
                  +{workoutGenPoints} WKG
                </p>
              )}

              {isTodo && (
                <p className="text-sm text-center text-subtitle">
                  +{workoutGenPoints} WKG
                </p>
              )}

              {isCancelled && (
                <p className="text-sm text-center text-subtitle">0 WKG</p>
              )}

              <h3 className="text-xl py-1 leading-none">
                {isCancelled ? passTitle : title}
              </h3>
            </div>

            {isTodo && (
              <p className="text-sm mt-2 text-center text-subtitle">
                {description}
              </p>
            )}
          </div>

          <div className="flex gap-2 mt-2">
            <FaChip
              item={`${nbExercises} exercice${nbExercises > 1 ? "s" : ""}`}
            />

            <FaChip item={calculateTrainingDuration(content) as string} />
          </div>
        </div>
      </div>

      <IonList
        className={clsx("!m-0", "transition-all ease-in-out duration-300")}
        inset
        lines="full"
      >
        {content.map(({ name, move_start, sets }, index) => (
          <IonItem key={index + name} disabled={isHidden}>
            <div slot="end">
              <span className="font-title">
                {sets[0].unit === "reps" ? (
                  <>
                    {sets.length} x {sets[0].reps}
                  </>
                ) : (
                  <>{sets[0].reps}s</>
                )}
              </span>
            </div>

            <div slot="start" className="flex gap-1">
              {isCancelled ? (
                <IonAvatar className="w-[35px] h-[35px] centered-flex-col object-cover my-2">
                  <IonIcon color="danger" icon={close} />
                </IonAvatar>
              ) : position > index ? (
                <IonAvatar className="w-[35px] h-[35px] centered-flex-col object-cover my-2">
                  <IonIcon icon={checkmark} />
                </IonAvatar>
              ) : (
                <IonImg
                  className="w-[35px] h-[35px] object-cover my-2"
                  src={move_start.url}
                  alt={name}
                />
              )}
            </div>

            <IonLabel>{name}</IonLabel>
          </IonItem>
        ))}
      </IonList>

      <footer className={clsx("centered-flex-col gap-3", "mt-4 pb-4")}>
        {isTodo && (
          <>
            {devMode && (
              <FaButton
                onClick={() => onValidateBloc(workoutPart)}
                className="centered-flex-col !py-3 !bg-black text-white"
                size="small"
              >
                <div className="centered-flex-row gap-2">
                  <IonIcon icon={checkmark} />

                  <span>Valider</span>
                </div>

                <div className="absolute -top-1 -right-2 text-xs bg-black rounded-full p-1">
                  <span className="text-white font-text">{"</>"}</span>
                </div>
              </FaButton>
            )}
          </>
        )}

        {isCancelled ? (
          <>
            <div id={alertIdReactivate}>
              <FaButton className="centered-flex-col" size="small">
                <div className="centered-flex-row gap-2">
                  <IonIcon icon={reload} />

                  <span>Réactiver</span>
                </div>
              </FaButton>
            </div>

            <IonAlert
              trigger={alertIdReactivate}
              message={`Êtes-vous sûr de vouloir réactiver le bloc ${title.toLowerCase()} ?`}
              buttons={[
                {
                  text: "Réactiver",
                  handler: () => onReactivateBloc(workoutPart),
                },
                {
                  text: "Non",
                },
              ]}
            />
          </>
        ) : isDone ? (
          <>
            <div id={alertIdRedo}>
              <FaButton className="centered-flex-col" size="small">
                <div className="centered-flex-row gap-2">
                  <IonIcon icon={reload} />

                  <span>Refaire</span>
                </div>
              </FaButton>
            </div>

            <IonAlert
              trigger={alertIdRedo}
              message={`Êtes-vous sûr de vouloir refaire le bloc ${title.toLowerCase()} ? Vous perdrez votre progression actuelle.`}
              buttons={[
                {
                  text: "Oui",
                  handler: () => onReactivateBloc(workoutPart),
                },
                {
                  text: "Non",
                },
              ]}
            />
          </>
        ) : null}
      </footer>
    </FaSurface>
  );
};

export default FaSessionPad;
