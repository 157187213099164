import clsx from "clsx";
import { memo } from "react";

const FaAnimatedBackground = memo(() => (
  <div
    className={clsx(
      "fixed inset-0 z-[-1] w-screen h-dvh",
      "pointer-events-none overflow-hidden",
    )}
  >
    <div className="absolute bg-white/70 z-50 inset-0 backdrop-blur-3xl" />

    <div
      className={clsx(
        "top-[120px] -right-[100px] lg:top-[120px] lg:-right-[100px]",
        "absolute w-[200px] h-[100px] lg:h-[150px] bg-[#fd4adf]",
        "bg-[#ff5be4]",
        "rounded-full",
      )}
    />

    <div
      className={clsx(
        "bottom-[120px] -left-[20px] lg:bottom-[100px] lg:-left-[100px]",
        "absolute w-[100px] h-[100px] lg:w-[200px] lg:h-[200px]",
        "bg-[#fd4adf]",
        "rounded-full",
      )}
    />
  </div>
));

export default FaAnimatedBackground;
