import { useLayoutEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useDebounce } from "react-use"
import {
  setAge,
  setAgeIsValid,
  setCurrentStep,
  setSize,
  setSizeIsValid,
  setWeight,
  setWeightIsValid
} from "../../register/redux/registerSlice"

interface UseValidateScrollStepParams {
  isValid: boolean
  isDirty: boolean
  method: "age" | "weight" | "size"
  formValue: number | undefined
  step: number
}

/**
 * @description This hook is used to validate the form and scroll to the next step and prevent scrolling to the next
 * step if the form is not valid id the form is not valid
 *
 * @param params UseValidateScrollStepParams
 */
export const useValidateScrollStep = (params: UseValidateScrollStepParams) => {
  const { isValid, isDirty, formValue, step, method } = params

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    if (!isValid || !isDirty) {
      setLoading(false)

      return
    }

    if (formValue) setLoading(true)
  }, [isValid, formValue])

  useDebounce(
    () => {
      if (formValue === undefined) {
        setLoading(false)

        return
      }

      switch (method) {
        case "age":
          if (isNaN(formValue)) {
            setLoading(false)

            return
          }

          dispatch(setAgeIsValid(isValid))
          dispatch(setAge(formValue))
          break
        case "weight":
          if (isNaN(formValue)) {
            setLoading(false)

            return
          }

          dispatch(setWeightIsValid(isValid))
          dispatch(setWeight(formValue))
          break
        case "size":
          if (isNaN(formValue)) {
            setLoading(false)

            return
          }

          dispatch(setSizeIsValid(isValid))
          dispatch(setSize(formValue))
          break
        default:
          setLoading(false)

          break
      }

      if (!isValid || !isDirty) {
        setLoading(false)

        return
      }

      dispatch(setCurrentStep(step))

      setLoading(false)
    },
    300,
    [isValid]
  )

  return loading
}
