import { calculateUserLevel } from "@workoutgen/design-system/calc-level"
import {
  ApiWKGWorkout,
  AvailableDays,
  UserTracking
} from "@workoutgen/global-typings/engine-types"
import { addDays, endOfDay } from "date-fns"
import { toast } from "react-toastify"
import {
  Profile,
  usePostGenerateWkgWorkoutMutation,
  usePostUserTrackingsMutation,
  WkgArgs,
  WorkoutTrainingFollowupComponent
} from "../../api/WorkoutGenApi"
import { selectAuth } from "../../authentification/redux/authentication"
import { useAppSelector } from "../../commons/hooks/useAppSelector"
import { useWKGData } from "../../commons/hooks/useWKGData"

export const useTimelineNavigation = () => {
  const [genWorkout] = usePostGenerateWkgWorkoutMutation()
  const [postUserTrackings] = usePostUserTrackingsMutation()

  const { userTracking, lastUserTracking, userWorkout, userSettings } =
    useAppSelector((state) => state.global)
  const { user, profile } = useAppSelector(selectAuth)

  const { saveUserTracking, saveUserWorkout, saveUserSettings } = useWKGData()

  const handleSetNextSession = async (isRest = false) => {
    try {
      if (
        !userTracking ||
        !lastUserTracking ||
        !userWorkout ||
        !user ||
        !profile
      ) {
        throw new Error("Données utilisateur manquantes")
      }

      if (userSettings)
        await saveUserSettings({
          ...userSettings,
          playerTracking: {
            join_unlocking: 0,
            warmup: 0,
            exercises: 0,
            abds: 0,
            stretching: 0
          }
        })

      const updatedLastUserTracking = updateUserTrackingPoints(
        lastUserTracking,
        isRest
      )

      const nextIndices = getNextSessionIndices(
        updatedLastUserTracking,
        userWorkout.metadata.cyclesSummary
      )

      const newUserTrackingEntry = createNewUserTrackingEntry(
        updatedLastUserTracking,
        userWorkout,
        nextIndices
      )

      const updatedUserTracking = [
        ...userTracking.slice(0, -1),
        updatedLastUserTracking,
        newUserTrackingEntry
      ]

      await saveUserTracking(updatedUserTracking)

      if (lastUserTracking.session_index === 2 && user.user?.id) {
        await postUserTrackings({
          userTrackingRequest: {
            data: {
              ...updatedUserTracking[0],
              user: user.user.id
            }
          }
        })

        await postUserTrackings({
          userTrackingRequest: {
            data: {
              ...updatedUserTracking[1],
              user: user.user.id
            }
          }
        })
      }

      if (lastUserTracking.session_index === 3 && user.user?.id) {
        await postUserTrackings({
          userTrackingRequest: {
            data: {
              ...updatedUserTracking[2],
              user: user.user.id
            }
          }
        })
      }

      if (lastUserTracking.session_index > 3 && user.user?.id)
        await postUserTrackings({
          userTrackingRequest: {
            data: {
              ...updatedUserTracking[updatedUserTracking.length - 1],
              user: user.user.id
            }
          }
        })

      if (nextIndices.nextWeekIndex > updatedLastUserTracking.week_index) {
        await handleSetNextWeek(updatedUserTracking)
      }
    } catch (error) {
      console.error(error)
      toast.error("Erreur lors du paramétrage de la prochaine session")
    }
  }

  const handleSetNextWeek = async (updatedUserTracking: UserTracking[]) => {
    try {
      if (!user || !profile) {
        throw new Error("Données utilisateur manquantes")
      }

      const wkgArgs: WkgArgs = createWkgArgs(profile)

      const localLastUserTracking =
        updatedUserTracking[updatedUserTracking.length - 1]

      const workoutResponse = await genWorkout({
        createWkgWorkoutRequestBody: {
          wkgArgs,
          lastUserTracking: localLastUserTracking
        }
      })

      if ("error" in workoutResponse) {
        throw new Error(
          "Une erreur est survenue lors de la génération du programme"
        )
      }

      const { data: workout } = workoutResponse as { data: ApiWKGWorkout }

      await saveUserWorkout(workout)
    } catch (error) {
      console.error(error)
      toast.error("Erreur lors de la génération de la semaine suivante")
    }
  }

  return {
    handleSetNextSession,
    handleSetNextWeek
  }
}

const updateUserTrackingPoints = (
  lastUserTracking: UserTracking,
  isRest: boolean
): UserTracking => {
  const updatedWkgPoints = lastUserTracking.wkg_points

  const updatedLastUserTracking = isRest
    ? {
        ...lastUserTracking,
        wkg_points: updatedWkgPoints + 100,
        achievement_rate: 100
      }
    : lastUserTracking

  return updatedLastUserTracking
}

const createNewUserTrackingEntry = (
  lastUserTracking: UserTracking,
  workoutData: ApiWKGWorkout,
  nextIndices: NextSessionIndices
): UserTracking => {
  const updatedWkgPoints = lastUserTracking.wkg_points

  const nextUserLevel = calculateUserLevel(updatedWkgPoints)

  const nextSessionType =
    workoutData.workout.sessions[nextIndices.nextSessionIndex - 1]
      ?.sessionType || "session"

  const countdown = endOfDay(
    addDays(new Date(), nextSessionType !== "session" ? 1 : 0)
  ).toISOString()

  return {
    countdown,
    achievement_rate: 0,
    level: nextUserLevel,
    session_index: nextIndices.nextSessionIndex,
    week_index: nextIndices.nextWeekIndex,
    cycle_index: nextIndices.nextCycleIndex,
    wkg_points: updatedWkgPoints,
    training_followup: getDefaultTrainingFollowup(),
    session_type: nextSessionType
  }
}

const createWkgArgs = (profile: Profile): WkgArgs => ({
  size: profile.size,
  weight: profile.weight,
  age: profile.age,
  gender: profile.gender,
  morphotype: profile.morphotype,
  level: profile.level,
  workoutTarget: profile.workoutTarget,
  practice: profile.practice,
  availableDays: profile.availableDays as AvailableDays,
  activityLevel: profile.activityLevel,
  non_available_equipments: [],
  country: "fr",
  acceptTerms: !!profile.acceptTerms
})

type NextSessionIndices = {
  nextSessionIndex: number
  nextWeekIndex: number
  nextCycleIndex: number
}

const getNextSessionIndices = (
  lastTracking: UserTracking,
  cyclesSummary: Array<{
    cycleIndex: number
    cycleName: string
    weekCount: number
    sessionCount: number
  }>
): NextSessionIndices => {
  const nextSessionIndex = lastTracking.session_index + 1

  const nextWeekIndex = Math.floor((nextSessionIndex - 1) / 7) + 1

  let cumulativeSessions = 0
  let nextCycleIndex = lastTracking.cycle_index

  for (const cycle of cyclesSummary) {
    cumulativeSessions += cycle.sessionCount

    if (nextSessionIndex <= cumulativeSessions) {
      nextCycleIndex = cycle.cycleIndex
      break
    }
  }

  if (nextSessionIndex > cumulativeSessions) {
    nextCycleIndex += 1
  }

  return {
    nextSessionIndex,
    nextWeekIndex,
    nextCycleIndex
  }
}

const getDefaultTrainingFollowup = (): WorkoutTrainingFollowupComponent => ({
  exercises: "todo",
  stretching: "todo",
  join_unlocking: "todo",
  warmup: "todo",
  abds: "todo",
  current_exercises_index: 0,
  current_abds_index: 0,
  current_join_unlocking_index: 0,
  current_stretching_index: 0,
  current_warmup_index: 0
})
