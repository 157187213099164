import { ReactNode } from "react"
import { Redirect } from "react-router"
import { APP_ROUTES } from "../../constants"
import { useAppIsReady } from "../../hooks/useAppIsReady"

interface PublicRouteProps {
  children: ReactNode
}

export const PrivateRoute = ({ children }: PublicRouteProps) => {
  const isAuthenticated = useAppIsReady()

  return isAuthenticated ? children : <Redirect to={APP_ROUTES.HOME} />
}
