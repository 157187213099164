import clsx from "clsx";

export interface FaSpacerProps {
  size?: "small" | "medium" | "large";
}

const FaSpacer = (props: FaSpacerProps) => {
  const { size = "medium" } = props;

  return (
    <div
      className={clsx(
        size === "small" ? "p-2" : size === "medium" ? "p-5" : "p-14",
      )}
    />
  );
};

export default FaSpacer;
