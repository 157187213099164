import FaSelectImage from "@workoutgen/design-system/FaSelectImage"
import { SelectImage } from "@workoutgen/design-system/FaSelectImageTypes"
import { Morphotypes } from "@workoutgen/global-typings/engine-types"
import { useCallback } from "react"
import menEctomorph from "../../../../commons/assets/images/morphotypes/men-ectomorph.png"
import womenEctomorph from "../../../../commons/assets/images/morphotypes/women-ectomorph.png"

import menEndomorph from "../../../../commons/assets/images/morphotypes/men-endomorph.png"
import womenEndomorph from "../../../../commons/assets/images/morphotypes/women-endomorph.png"

import menMesomorph from "../../../../commons/assets/images/morphotypes/men-mesomorph.png"
import womenMesomorph from "../../../../commons/assets/images/morphotypes/women-mesomorph.png"

import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setCurrentStep,
  setMorphotypes
} from "../../../redux/registerSlice"

interface MorphoStepProps {
  images: Array<SelectImage<Morphotypes>>
}

export const MorphoStep = (props: MorphoStepProps) => {
  const { images } = props

  const dispatch = useAppDispatch()

  const { morphotype, gender } = useAppSelector(selectRegisterForm)

  const handleMorphotypesChange = useCallback(
    (morphotypes?: Morphotypes): void => {
      if (morphotypes === undefined) return

      dispatch(setMorphotypes(morphotypes))

      dispatch(setCurrentStep(3))
    },
    [dispatch]
  )

  const items = [
    {
      description: (
        <p>
          L&apos;<strong className="font-title">ectomorphe</strong> possède une
          silhouette fine. Il a un métabolisme rapide et a du mal à prendre du
          poids ou à gagner de la masse musculaire.
        </p>
      ),
      img: gender !== "women" ? menEctomorph : womenEctomorph
    },
    {
      description: (
        <p>
          L&apos;<strong className="font-title">endomorphe</strong> a une
          silhouette ronde et un métabolisme lent. Il prend facilement du poids,
          surtout sous forme de graisse, et a des difficultés à le perdre.
        </p>
      ),
      img:
        gender === "all"
          ? womenMesomorph
          : gender === "women"
            ? womenMesomorph
            : menMesomorph
    },
    {
      description: (
        <p>
          Le <strong className="font-title">mésomorphe</strong> a une stature
          athlétique avec une musculature bien développée. Il gagne facilement
          de la masse musculaire tout en conservant un taux de graisse
          corporelle bas.
        </p>
      ),
      img: gender !== "women" ? menEndomorph : womenEndomorph
    }
  ].map(({ img, description }, index) => ({
    description,
    img,
    title: images?.[index]?.title,
    value: images?.[index]?.value
  })) as Array<SelectImage<Morphotypes>>

  return (
    <FaSelectImage<Morphotypes>
      items={items}
      currentValue={morphotype}
      onChange={handleMorphotypesChange}
    />
  )
}
