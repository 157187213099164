import { IonIcon } from "@ionic/react";
import {
  ExercisePlayerCard,
  UserSettings,
} from "@workoutgen/global-typings/kiosk-types";
import clsx from "clsx";
import { close, list } from "ionicons/icons";
import { Audio } from "./components/Audio";

interface FaPlayerToolbarProps {
  handleClose: () => void;
  handleOpenInfoModal: () => void;
  userSettings: UserSettings;
  isCurrentSlide: boolean;
  exercisePlayerCard: ExercisePlayerCard;
  onUpdateUserSettings: (userSettings: UserSettings) => void;
}

const FaPlayerToolbar = ({
  handleClose,
  handleOpenInfoModal,
  userSettings,
  isCurrentSlide,
  exercisePlayerCard,
  onUpdateUserSettings,
}: FaPlayerToolbarProps) => {
  const isPwa = window.matchMedia("(display-mode: standalone)").matches;

  return (
    <div
      className={clsx(
        "absolute right-2 top-0 rounded-full",
        "flex flex-col items-center justify-center gap-4 xl:gap-6",
        isPwa ? "player-to-mobile-native-top mt-2" : "pt-3",
      )}
    >
      <button
        onClick={handleClose}
        className="w-14  flex flex-col items-center justify-center"
      >
        <IonIcon
          className="text-3xl xl:text-4xl text-white ui-player-text-element"
          slot="icon-only"
          icon={close}
        />
      </button>

      <div className="w-14 h-14 flex flex-col items-center justify-center lg:hidden">
        <button onClick={handleOpenInfoModal}>
          <IonIcon
            className="text-3xl xl:text-4xl text-white ui-player-text-element"
            slot="icon-only"
            icon={list}
          />
        </button>

        <i className="text-[0.7rem] ui-player-text-element xl:text-xs text-white not-italic">
          Infos
        </i>
      </div>

      <Audio
        userSettings={userSettings}
        exercisePlayerCard={exercisePlayerCard}
        isCurrentSlide={isCurrentSlide}
        onUpdateUserSettings={onUpdateUserSettings}
      />
    </div>
  );
};

export default FaPlayerToolbar;
