import { APP_ROUTES } from "../../commons/constants"
import HomePage from "../pages/HomePage/HomePage"
import LoginPage from "../pages/LoginPage/LoginPage"
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage"
import ResetPasswordRequest from "../pages/ResetPasswordRequest/ResetPasswordRequest"

const authenticationRoutes = [
  {
    path: APP_ROUTES.HOME,
    component: <HomePage />
  },
  {
    path: APP_ROUTES.LOGIN,
    component: <LoginPage />
  },
  {
    path: APP_ROUTES.RESET_PASSWORD_REQUEST,
    component: <ResetPasswordRequest />
  },
  {
    path: APP_ROUTES.RESET_PASSWORD,
    component: <ResetPasswordPage />
  }
]

export default authenticationRoutes
