import { IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react"
import FaAnimatedBackground from "@workoutgen/design-system/FaAnimatedBackground"
import clsx from "clsx"
import { type ReactNode } from "react"

interface FaPageProps {
  content?: ReactNode
  padding?: boolean
  paddingHorizontal?: boolean
  paddingVertical?: boolean
  header?: ReactNode
  fullscreen?: boolean
  translucent?: boolean
}

const FaPageLayout = (props: FaPageProps) => {
  const {
    content,
    padding = true,
    paddingHorizontal = false,
    paddingVertical = false,
    header,
    translucent = true,
    fullscreen = true
  } = props

  return (
    <IonPage>
      {header && (
        <IonHeader
          className="ion-no-border"
          collapse="fade"
          translucent={translucent}
        >
          <IonToolbar className="centered-flex-col px-3 min-h-[50px]">
            {header}
          </IonToolbar>
        </IonHeader>
      )}

      <IonContent
        className={clsx(
          padding && "ion-padding",
          paddingHorizontal && "ion-padding-horizontal",
          paddingVertical && "ion-padding-vertical"
        )}
        fullscreen={fullscreen}
      >
        {content}

        <FaAnimatedBackground />
      </IonContent>
    </IonPage>
  )
}

export default FaPageLayout
