import { useInterval } from "ahooks";
import { useState } from "react";

export interface FaEmojiAnimationProps {
  interval?: number;
}

const FaEmojiAnimation = (props: FaEmojiAnimationProps) => {
  const { interval } = props;

  const emojilIST = ["💤", "🛏️", "🐨", "🍲", "⏰", "🥘", "😴"];

  const [emoji, setEmoji] = useState(emojilIST[0]);
  const [count, setCount] = useState(0);

  useInterval(() => {
    if (count < emojilIST.length - 1) setCount(count + 1);
    else setCount(0);

    setEmoji(emojilIST[count]);
  }, interval);

  return <span className="lg:text-lg">{emoji}</span>;
};

export default FaEmojiAnimation;
