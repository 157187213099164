import { Directory, Encoding, Filesystem } from "@capacitor/filesystem"
import {
  ApiWKGWorkout,
  UserTracking
} from "@workoutgen/global-typings/engine-types"
import { UserSettings } from "@workoutgen/global-typings/kiosk-types"
import {
  Profile,
  UsersPermissionsUserRegistration
} from "../../api/WorkoutGenApi"
import {
  setProfile,
  setUser
} from "../../authentification/redux/authentication"
import { FILE_SYSTEM_KEYS } from "../constants"
import {
  setUserSettings,
  setUserTracking,
  setUserWorkout
} from "../redux/globalSlice"
import { useAppDispatch } from "./useAppDispatch"

export const useWKGData = () => {
  const dispatch = useAppDispatch()

  const getUserWorkout = async () => {
    const userWorkoutData = await Filesystem.readFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_WORKOUT,
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    const userWorkout: ApiWKGWorkout = JSON.parse(
      userWorkoutData.data as string
    )

    dispatch(setUserWorkout(userWorkout))

    return userWorkout
  }

  const saveUserWorkout = async (userWorkout: ApiWKGWorkout) => {
    await Filesystem.writeFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_WORKOUT,
      data: JSON.stringify(userWorkout),
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    dispatch(setUserWorkout(userWorkout))

    return userWorkout
  }

  const deleteUserWorkout = async () => {
    await Filesystem.deleteFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_WORKOUT,
      directory: Directory.Data
    })

    dispatch(setUserWorkout(undefined))
  }

  const getUserTracking = async () => {
    const userTrackingData = await Filesystem.readFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_TRACKING,
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    const userTracking = JSON.parse(
      userTrackingData.data as string
    ) as UserTracking[]

    dispatch(setUserTracking(userTracking))

    return userTracking
  }

  const saveUserTracking = async (userTracking: UserTracking[]) => {
    await Filesystem.writeFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_TRACKING,
      data: JSON.stringify(userTracking),
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    const sortedUserTracking = userTracking.sort(
      (a, b) => a.session_index - b.session_index
    )

    dispatch(setUserTracking(sortedUserTracking))

    return sortedUserTracking
  }

  const deleteUserTracking = async () => {
    await Filesystem.deleteFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_TRACKING,
      directory: Directory.Data
    })

    dispatch(setUserTracking(undefined))
  }

  const getUser = async () => {
    const WKGUserData = await Filesystem.readFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER,
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    const WKGUser: UsersPermissionsUserRegistration = JSON.parse(
      WKGUserData.data as string
    )

    dispatch(setUser(WKGUser))

    return WKGUser
  }

  const saveUser = async (WKGUser: UsersPermissionsUserRegistration) => {
    await Filesystem.writeFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER,
      data: JSON.stringify(WKGUser),
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    dispatch(setUser(WKGUser))

    return WKGUser
  }

  const deleteUser = async () => {
    await Filesystem.deleteFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER,
      directory: Directory.Data
    })

    dispatch(setUser(undefined))
  }

  const getProfile = async () => {
    const profileData = await Filesystem.readFile({
      path: FILE_SYSTEM_KEYS.SECRETS.PROFILE,
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    const WKGUser = JSON.parse(profileData.data as string)

    dispatch(setProfile(WKGUser))
  }

  const saveProfile = async (profile: Profile) => {
    await Filesystem.writeFile({
      path: FILE_SYSTEM_KEYS.SECRETS.PROFILE,
      data: JSON.stringify(profile),
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    dispatch(setProfile(profile))

    return profile
  }

  const deleteProfile = async () => {
    await Filesystem.deleteFile({
      path: FILE_SYSTEM_KEYS.SECRETS.PROFILE,
      directory: Directory.Data
    })

    dispatch(setProfile(undefined))
  }

  const saveUserSettings = async (userSettings: UserSettings) => {
    try {
      await Filesystem.writeFile({
        path: FILE_SYSTEM_KEYS.SECRETS.USER_SETTINGS,
        data: JSON.stringify(userSettings),
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })

      dispatch(setUserSettings(userSettings))

      return userSettings
    } catch (error) {
      console.error(error)
    }
  }

  const deleteUserSettings = async () => {
    await Filesystem.deleteFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_SETTINGS,
      directory: Directory.Data
    })

    dispatch(setUserSettings(undefined))
  }

  const getUserSettings = async () => {
    const userSettingsData = await Filesystem.readFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_SETTINGS,
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    const userSettings = JSON.parse(userSettingsData.data as string)

    dispatch(setUserSettings(userSettings))

    return userSettings
  }

  const removeAllUserData = async () => {
    await Promise.all([
      deleteUserTracking(),
      deleteUserWorkout(),
      deleteProfile(),
      deleteUser(),
      deleteUserSettings()
    ])
  }

  return {
    getUserWorkout,
    saveUserWorkout,
    deleteUserWorkout,

    getUser,
    saveUser,
    deleteUser,

    getUserTracking,
    saveUserTracking,
    deleteUserTracking,

    getProfile,
    saveProfile,
    deleteProfile,

    getUserSettings,
    saveUserSettings,
    deleteUserSettings,

    removeAllUserData
  }
}
