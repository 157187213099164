import { addDays, format } from "date-fns"
import { fr } from "date-fns/locale"

export const getCurrentStringDate = (index: number) => {
  const currentDate = new Date()
  const newDate = addDays(currentDate, index)

  if (index === 0) {
    return `aujourd'hui, ${format(newDate, "dd MMMM yyyy", { locale: fr })}`
  }

  return format(newDate, "EEEE dd MMMM yyyy", { locale: fr })
}
