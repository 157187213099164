import FaSubtitle from "@workoutgen/design-system/FaSubtitle"
import FaTitle from "@workoutgen/design-system/FaTitle"
import clsx from "clsx"
import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setFocusedStep
} from "../../../redux/registerSlice"

interface RegisterStepProps {
  children: JSX.Element
  title?: string
  centerTitle?: boolean
  subtitle?: string
  stepNumber: number
  additionalInfos?: string
  className?: string
}

export const RegisterStep = (props: RegisterStepProps) => {
  const {
    children,
    title,
    centerTitle,
    subtitle,
    stepNumber,
    additionalInfos,
    className
  } = props

  const dispatch = useAppDispatch()

  const { currentStep } = useAppSelector(selectRegisterForm)

  const handleFocusedStep = () => {
    dispatch(
      setFocusedStep({
        timestamp: new Date(),
        value: stepNumber
      })
    )
  }

  return (
    <section
      id={`step-${stepNumber}`}
      className={clsx(
        "w-full min-h-[80vh] relative scroll-slide flex flex-col justify-center",
        "py-16 md::py-0",
        stepNumber > currentStep && "opacity-20 pointer-events-none",
        className
      )}
      onClick={handleFocusedStep}
    >
      {title !== undefined && (
        <header
          className={clsx(
            "mb-8",
            centerTitle === true && "flex flex-col items-center justify-center"
          )}
        >
          <h2>
            <FaTitle
              text={title}
              size="medium"
              align={centerTitle === true ? "center" : "left"}
              mobileAlign={centerTitle === true ? "center" : "left"}
            />
          </h2>

          {subtitle !== undefined && (
            <h3 className="mt-3">
              <FaSubtitle
                text={subtitle}
                align={centerTitle === true ? "center" : "left"}
              />
            </h3>
          )}
        </header>
      )}

      {children}

      {additionalInfos !== undefined && (
        <p className="mt-10">{additionalInfos}</p>
      )}
    </section>
  )
}
