import { capitalizeFirstLetter } from "../../../utils/text";

interface FaChipProps {
  item: string;
}

const FaChip = ({ item }: FaChipProps) => {
  const title = capitalizeFirstLetter(item);

  return (
    <div className="bg-secondary/5 px-3 py-1 rounded-full">
      <span className="text-sm">{title}</span>
    </div>
  );
};

export default FaChip;
