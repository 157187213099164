import { PlayerTracking } from "@workoutgen/global-typings/kiosk-types";
import clsx from "clsx";

export interface FaPlayerProgressBarProps {
  currentSlide: number;
  tracking: PlayerTracking;
}

const FaPlayerProgressBar = ({
  currentSlide,
  tracking,
}: FaPlayerProgressBarProps) => {
  return (
    <div className="flex justify-center w-full mt-2.5">
      {tracking.map((exercise, index) => (
        <div
          key={exercise.name + exercise.index}
          className={clsx(
            "h-[10px] max-w-[10px] bg-surface w-full ui-player-box-element",
            exercise.index < currentSlide ? "bg-secondary" : "bg-white",
            exercise.category === "exercisesRecup"
              ? "!bg-transparent"
              : index === 0
                ? "mr-[2px]"
                : index === tracking.length - 1
                  ? "ml-[2px]"
                  : "mx-[2px]",
            exercise.index === currentSlide &&
              exercise.category !== "exercisesRecup" &&
              "animate-blue-purple-pink-cycle-animation-fast",
          )}
        />
      ))}
    </div>
  );
};

export default FaPlayerProgressBar;
