import { UserTracking } from "@workoutgen/global-typings/engine-types"

export const calcCompletedBlocks = (currentUserTracking: UserTracking) => {
  const totalBlocks = 5

  const completedBlocks = Object.values(
    currentUserTracking.training_followup
  ).filter((value) => value === "done").length

  return (completedBlocks / totalBlocks) * 100
}
