import { IonSpinner } from "@ionic/react";
import Spline from "@splinetool/react-spline";
import { useInterval } from "ahooks";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Reducer, useCallback, useReducer, useState } from "react";
import { createPortal } from "react-dom";

interface FaWorkoutGeneratingProps {
  onFinished: () => void;
}

// Define the states as an enum for clarity
enum WorkoutState {
  ANALYZING = "Analysant l'utilisateur",
  GENERATING_CYCLES = "Générant des cycles",
  APPLYING_SETS = "Appliquant les séries",
  GENERATING_WORKOUTS = "Générant les entraînements",
  CUSTOMIZING_EXERCISES = "Personnalisant les exercices",
}

// A reducer function to manage state transitions
const workoutReducer = (state: WorkoutState) => {
  switch (state) {
    case WorkoutState.ANALYZING:
      return WorkoutState.GENERATING_CYCLES;
    case WorkoutState.GENERATING_CYCLES:
      return WorkoutState.APPLYING_SETS;
    case WorkoutState.APPLYING_SETS:
      return WorkoutState.GENERATING_WORKOUTS;
    case WorkoutState.GENERATING_WORKOUTS:
      return WorkoutState.CUSTOMIZING_EXERCISES;
    case WorkoutState.CUSTOMIZING_EXERCISES:
      return WorkoutState.CUSTOMIZING_EXERCISES;
    default:
      return state;
  }
};

const FaWorkoutGenerating = ({ onFinished }: FaWorkoutGeneratingProps) => {
  const [loaded, setLoaded] = useState(false);
  const [currentState, dispatch] = useReducer<Reducer<WorkoutState, string>>(
    workoutReducer,
    WorkoutState.ANALYZING,
  );

  useInterval(() => {
    if (!loaded) return;

    if (currentState === WorkoutState.CUSTOMIZING_EXERCISES) {
      onFinished();
    } else {
      dispatch(currentState);
    }
  }, 3000);

  const handleSetLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  return createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-white flex items-center justify-center"
      aria-live="polite"
    >
      <div
        className={clsx(
          "transition-opacity duration-500",
          loaded ? "opacity-100" : "opacity-0",
          "px-4 text-center -mt-[100px] min-h-[426px]",
        )}
      >
        <Spline
          scene="https://prod.spline.design/tql29tCUc2srOR-l/scene.splinecode"
          onLoad={handleSetLoaded}
        />

        <div className="h-[30px] centered-flex-col">
          <motion.p
            initial={{ opacity: 0, translateY: 30 }}
            animate={{ opacity: 1, translateY: 0 }}
            className="text-black mb-10 px-10 font-bold"
            aria-label={currentState}
            key={currentState}
          >
            {currentState}
          </motion.p>
        </div>

        <IonSpinner />
      </div>
    </motion.div>,
    document.body,
  );
};

export default FaWorkoutGenerating;
