import { IonIcon, IonSpinner } from "@ionic/react";
import clsx from "clsx";
import { backspace, refresh } from "ionicons/icons";
import { useState } from "react";
import { useDebounce } from "react-use";
import FaFormError from "../FaFormError/FaFormError";

export interface NumberPadFieldChangeEvent {
  value: number | undefined;
  isValid: boolean;
  isDirty: boolean;
}
export interface PadValidationRule {
  message: string;
  rule: (value: number) => boolean;
}

export interface FaNumberPadFieldProps {
  onChange?: (value: NumberPadFieldChangeEvent) => void;
  contextInfo: string;
  rules?: PadValidationRule[];
  isValid?: boolean;
  maxLength?: number;
  defaultValue: string;
  loading?: boolean;
}

const FaNumberPadField = (props: FaNumberPadFieldProps) => {
  const {
    onChange,
    contextInfo,
    rules,
    maxLength = 3,
    defaultValue,
    loading = false,
  } = props;

  const [currentValue, setCurrentValue] = useState<string>(defaultValue ?? "");
  const [error, setError] = useState<string | undefined>(undefined);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const handleSetValue = (value: string) => {
    if (value === "") {
      setCurrentValue("");

      return;
    }

    const newValue = currentValue + value;

    if (newValue.length > maxLength) return;

    setCurrentValue(newValue);
  };

  const handleRemoveLastValue = () => {
    setCurrentValue(currentValue.slice(0, -1));
  };

  useDebounce(
    () => {
      if (!rules) return;

      if (isDirty) {
        const error = rules.find(
          (rule) => !rule.rule(Number(currentValue)),
        )?.message;
        const isValid = !error;

        setError(error);

        onChange?.({ value: Number(currentValue), isValid, isDirty });
      }

      setIsDirty(true);
    },
    1000,
    [currentValue],
  );

  return (
    <>
      <p className="border-b border-medium flex items-center justify-between p-2">
        <span className="text-xl">{currentValue}</span>

        {loading ? (
          <IonSpinner />
        ) : (
          <span className="font-title italic text-lg">{contextInfo}</span>
        )}
      </p>

      <div className="w-full grid grid-cols-3 gap-4 md:gap-5 mt-3">
        {new Array(9).fill(0).map((_, index) => (
          <button
            onClick={() => handleSetValue(String(index + 1))}
            key={index}
            className={clsx(
              "h-20 md:h-32 bg-secondary/5 rounded-3xl font-title text-2xl hover:scale-105",
              "transition-all ease-in-out duration-200",
            )}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <div className="w-full grid grid-cols-3 gap-4 md:gap-5 mt-5 mb-5 transition-all ease-in-out duration-200">
        <button
          onClick={() => handleSetValue("")}
          className={clsx(
            "transition-all ease-in-out duration-200",
            "h-20 md:h-32 bg-black text-white rounded-3xl centered-flex-col font-title text-2xl",
            "hover:scale-105 hover:bg-black/90",
          )}
        >
          <IonIcon icon={refresh} />
        </button>

        <button
          onClick={() => handleSetValue("0")}
          className="h-20 md:h-32 bg-secondary/5 rounded-3xl font-title text-2xl hover:scale-105 transition-all ease-in-out duration-200"
        >
          0
        </button>

        <button
          onClick={handleRemoveLastValue}
          className={clsx(
            "transition-all ease-in-out duration-200",
            "h-20 md:h-32 bg-black text-white rounded-3xl centered-flex-col font-title text-2xl",
            "hover:scale-105 hover:bg-black/90",
          )}
        >
          <IonIcon icon={backspace} />
        </button>
      </div>

      <FaFormError error={error} />
    </>
  );
};

export default FaNumberPadField;
