import {
  IonBackButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonActionSheet
} from "@ionic/react"
import FaInfos from "@workoutgen/design-system/FaInfos"
import FaSocialNetworks from "@workoutgen/design-system/FaSocialNetworks"
import FaSurface from "@workoutgen/design-system/FaSurface"
import {
  enter,
  list,
  logoAndroid,
  logoApple,
  logoTux,
  logOut,
  logoWindows,
  mail,
  people,
  person,
  trash
} from "ionicons/icons"
import useAuth from "../../../authentification/hooks/useAuth"
import { selectAuth } from "../../../authentification/redux/authentication"
import { APP_ROUTES } from "../../../commons/constants"
import { useAppDispatch } from "../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../commons/hooks/useAppSelector"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import {
  selectGlobal,
  setPwaPromptEvent
} from "../../../commons/redux/globalSlice"

const settings = [
  {
    title: "À propos de nous",
    href: `${import.meta.env.VITE_WORKOUTGEN_LANDING_URL}/l-equipe`,
    icon: person
  },
  {
    title: "Contactez nous",
    href: `mailto:${import.meta.env.VITE_WORKOUTGEN_EMAIL}`,
    icon: mail
  },
  {
    title: "Conditions d’utilisation",
    href: `${import.meta.env.VITE_WORKOUTGEN_LANDING_URL}/cgu`,
    icon: list
  },
  {
    title: "Mention légales",
    href: `${import.meta.env.VITE_WORKOUTGEN_LANDING_URL}/mentions-legales/`,
    icon: people
  }
]

const getOSIcon = () => {
  const { userAgent } = navigator
  if (userAgent.includes("Win")) return logoWindows
  if (userAgent.includes("Mac")) return logoApple
  if (userAgent.includes("Linux")) return logoTux
  if (userAgent.includes("Android")) return logoAndroid
  if (userAgent.includes("like Mac")) return logoApple
  return enter
}

const SettingsHome = () => {
  const dispatch = useAppDispatch()

  const [present] = useIonActionSheet()

  const { deletePreviewWorkout, logout, deleteAllAccountData } = useAuth()

  const { user } = useAppSelector(selectAuth)

  const { pwaPromptEvent } = useAppSelector(selectGlobal)

  const handleInstallClick = () => {
    if (pwaPromptEvent) {
      pwaPromptEvent.prompt()
      pwaPromptEvent.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt")
        } else {
          console.log("User dismissed the A2HS prompt")
        }

        dispatch(setPwaPromptEvent(undefined))
      })
    }
  }

  const removePreviewWorkout = () => {
    present({
      header: "Voulez vous vraiment supprimer votre programme ?",
      subHeader:
        "Cette action est irréversible, toutes vos données seront supprimées",
      buttons: [
        {
          text: "Supprimer",
          handler: async () => {
            handleDeleteAccount()
          }
        },
        {
          text: "Annuler",
          role: "selected",
          data: {
            action: "cancel"
          }
        }
      ]
    })
  }

  const handleLogout = () => {
    present({
      header: "Voulez vous vraiment vous deconnecter ?",
      buttons: [
        {
          text: "Se deconnecter",
          handler: async () => {
            await logout()
          }
        },
        {
          text: "Annuler",
          role: "selected",
          data: {
            action: "cancel"
          }
        }
      ]
    })
  }

  const handleDeleteAccount = async () => {
    if (user?.user) {
      await deleteAllAccountData(user)
    } else {
      await deletePreviewWorkout()
    }

    window.location.reload()
  }

  return (
    <FaPageLayout
      header={
        <IonButtons slot="start">
          <IonBackButton defaultHref={APP_ROUTES.TIMELINE} text="Profil" />
        </IonButtons>
      }
      content={
        <article className="max-w-[500px] mx-auto flex flex-col gap-6">
          <section>
            <header className="mb-3">
              <h2 className="text-xl">Général</h2>
            </header>

            <FaSurface>
              <IonList inset className="!m-0">
                {settings.map(({ title, href, icon }) => (
                  <IonItem
                    key={href}
                    href={href}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <IonIcon slot="start" icon={icon} className="h-5 w-5" />

                    <IonLabel>{title}</IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </FaSurface>
          </section>

          {pwaPromptEvent && (
            <section>
              <header className="mb-3">
                <h2 className="text-xl">Installation</h2>
              </header>

              <FaSurface>
                <IonList className="!m-0" inset>
                  <IonItem onClick={handleInstallClick}>
                    <IonIcon
                      slot="start"
                      icon={getOSIcon()}
                      className="h-5 w-5"
                    />
                    <IonLabel>Installer WorkoutGen</IonLabel>
                  </IonItem>

                  <IonItem />
                </IonList>
              </FaSurface>
            </section>
          )}

          <section>
            <header className="mb-3">
              <h2 className="text-xl">Mon compte</h2>
            </header>

            <FaSurface>
              <IonList className="!m-0" inset>
                {user?.user && (
                  <IonItem onClick={handleLogout}>
                    <IonIcon slot="start" icon={logOut} className="h-5 w-5" />

                    <IonLabel>Se deconnecter</IonLabel>
                  </IonItem>
                )}

                <IonItem onClick={removePreviewWorkout}>
                  <IonIcon
                    slot="start"
                    icon={trash}
                    className="h-5 w-5 text-danger"
                  />

                  <IonLabel className="!text-danger">
                    Supprimer mon programme
                  </IonLabel>
                </IonItem>
              </IonList>
            </FaSurface>
          </section>

          <div className="mt-10 centered-flex-col gap-5">
            <div className="centered-flex-col">
              <p className="text-subtitle text-xs mb-1">WorkoutGen</p>

              <FaInfos version={import.meta.env.VITE_WORKOUTGEN_VERSION} />
            </div>

            <FaSocialNetworks
              socialNetworksLinks={{
                instagram: import.meta.env.VITE_INSTAGRAM_URL,
                tiktok: import.meta.env.VITE_TIKTOK_URL,
                linkedin: import.meta.env.VITE_LINKEDIN_URL
              }}
            />
          </div>
        </article>
      }
    />
  )
}

export default SettingsHome
