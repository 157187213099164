import { APP_ROUTES } from "../../commons/constants"
import SessionPreviewPage from "../pages/SessionPreviewPage/SessionPreviewPage"

const trainingPreviewRoutes = [
  {
    path: `${APP_ROUTES.TRAINING_PREVIEW}/:sessionIndex`,
    component: <SessionPreviewPage />
  }
]

export default trainingPreviewRoutes
