import { yupResolver } from "@hookform/resolvers/yup"
import { IonCheckbox } from "@ionic/react"
import { Turnstile } from "@marsidev/react-turnstile"
import FaFormError from "@workoutgen/design-system/FaFormError"
import { useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useDebounce } from "react-use"
import { bool, object } from "yup"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setAcceptTerms,
  setCurrentStep,
  setFormEnded,
  setInfosFormIsValid,
  setTurnstile
} from "../../../redux/registerSlice"

export const AccountInfosStep = () => {
  const dispatch = useDispatch()

  const {
    focusedStep,
    currentStep,
    email,
    acceptTerms,
    stepValidation,
    turnstile
  } = useAppSelector(selectRegisterForm)

  const validationSchema = object().shape({
    acceptTerms: bool().oneOf(
      [true],
      "Vous devez accepter les Conditions Générales d'Utilisation"
    )
  })

  const turnstileRef = useRef()

  const {
    control,
    trigger,
    watch,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      acceptTerms
    }
  })

  const watchFields = watch(["acceptTerms"])

  useDebounce(
    () => {
      if (!turnstile) return

      dispatch(setAcceptTerms(!!watchFields[0]))
      dispatch(setInfosFormIsValid(isValid))
    },
    300,
    [watchFields, turnstile]
  )

  useDebounce(
    async () => {
      if (currentStep < 9 || email === undefined || !turnstile) return

      const formHasErrors = Object.values(stepValidation).some(
        (step) => step === false
      )

      if (!formHasErrors) {
        dispatch(setCurrentStep(10))
        dispatch(setFormEnded(true))
      }
    },
    1000,
    [stepValidation, focusedStep, currentStep, turnstile]
  )

  const handleTurnstileSuccess = (token: string) => {
    dispatch(setTurnstile(token))
  }

  const handleRetry = () => {
    setTurnstileError(null)

    // @ts-expect-error Turnstile component does not have onExpire prop
    turnstileRef?.current?.reset()
  }

  const [turnstileError, setTurnstileError] = useState<string | null>(null)

  return (
    <form className="w-full relative" autoComplete="off">
      <Controller
        control={control}
        name="acceptTerms"
        render={({ field: { value, onChange } }) => (
          <div className="flex flex-col gap-3 items-center">
            <div className="flex gap-3 items-center">
              <IonCheckbox
                color="secondary"
                id="acceptTerms"
                checked={value}
                onIonChange={(value) => {
                  onChange(value.detail.checked)
                  trigger("acceptTerms")
                }}
              />

              <a
                className="underline"
                href={import.meta.env.VITE_WORKOUTGEN_LANDING_URL + "/cgu"}
                target="_blank"
                rel="noreferrer noopener"
              >
                Veuillez accepter les Conditions Générales d&apos;Utilisation
              </a>
            </div>

            <FaFormError error={errors?.acceptTerms?.message as string} />

            {!!watchFields[0] && (
              <>
                <Turnstile
                  scriptOptions={{
                    async: true,
                    defer: true
                  }}
                  ref={turnstileRef}
                  className="mt-5"
                  siteKey={import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITE_KEY}
                  options={{
                    theme: "light",
                    language: "fr"
                  }}
                  onSuccess={handleTurnstileSuccess}
                  onError={(error) => setTurnstileError(error)}
                  // @ts-expect-error Turnstile component does not have onExpire prop
                  onExpire={() => turnstileRef?.current?.reset()}
                  onUnsupported={() =>
                    handleTurnstileSuccess("oapzdj88JJJsqs&")
                  }
                />

                {turnstileError && (
                  <>
                    <FaFormError error={turnstileError as string} />

                    <button onClick={handleRetry}>Réessayer</button>
                  </>
                )}
              </>
            )}
          </div>
        )}
      />
    </form>
  )
}
