import { useIonRouter } from "@ionic/react"
import FaEndWorkoutPart from "@workoutgen/design-system/FaEndWorkoutPart"
import FaPlayer from "@workoutgen/design-system/FaPlayer"
import FaPlayerDesktopCard from "@workoutgen/design-system/FaPlayerDesktopCard"
import {
  UserSettings,
  WorkoutPart
} from "@workoutgen/global-typings/kiosk-types"
import clsx from "clsx"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { Virtual } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { APP_ROUTES, WKG_POINTS_DICT } from "../../../commons/constants"
import { useAppDispatch } from "../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../commons/hooks/useAppSelector"
import { useWKGData } from "../../../commons/hooks/useWKGData"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import { selectGlobal } from "../../../commons/redux/globalSlice"
import {
  selectPlayer,
  setCurrentExerciseIndex,
  setCurrentSlide
} from "../../redux/playerSlice"
import { generatePlayerCards } from "../../utils/generatePlayerCards"
import { generateTracking } from "../../utils/generateTracking"

const PlayerPage = () => {
  const router = useIonRouter()

  const { saveUserTracking, saveUserSettings } = useWKGData()

  const { workoutPart } = useParams<{ workoutPart: WorkoutPart }>()

  const dispatch = useAppDispatch()

  const { lastUserTracking, userTracking, userWorkout, userSettings } =
    useAppSelector(selectGlobal)

  const { currentSlide, currentExerciseIndex } = useAppSelector(selectPlayer)

  if (
    !lastUserTracking ||
    !userWorkout ||
    !workoutPart ||
    !userSettings ||
    !userTracking
  ) {
    console.error(
      "No user tracking or user workout or workout part or userSettings"
    )

    return null
  }

  const { session_index } = lastUserTracking

  const session = userWorkout?.workout?.sessions[(session_index - 1) % 7]

  const baseCurrentTraining = session?.training[workoutPart]

  const currentSessionExercises = generatePlayerCards(baseCurrentTraining)

  const isTrainingEnd = currentSessionExercises.length === currentSlide

  const tracking = generateTracking(currentSessionExercises)

  const [reRenderPlayer, setReRenderPlayer] = useState(0)

  const handleCloseSession = (): void => {
    router.push(
      `${APP_ROUTES.TRAINING_PREVIEW}/${lastUserTracking?.session_index}`,
      "back"
    )

    setReRenderPlayer(reRenderPlayer + 1)

    dispatch(setCurrentSlide(0))

    dispatch(setCurrentExerciseIndex(0))
  }

  const [blocValidated, setBlocValidated] = useState(false)

  const handleSlideChange = async (activeIndex: number, isEnd: boolean) => {
    setBlocValidated(isEnd)

    if (blocValidated) return

    dispatch(setCurrentSlide(activeIndex))

    const wkgPoint = lastUserTracking.wkg_points + WKG_POINTS_DICT[workoutPart]

    const currentExercise = currentSessionExercises[activeIndex]

    const currentExerciseIndex = `current_${workoutPart}_index`

    const restUserTracking = userTracking.slice(0, userTracking.length - 1)

    const updatedTracking = {
      ...lastUserTracking,
      wkg_points: isEnd ? wkgPoint : lastUserTracking.wkg_points,
      training_followup: {
        ...lastUserTracking.training_followup,
        [currentExerciseIndex]: activeIndex,
        [workoutPart]: isEnd ? "done" : "todo"
      }
    }

    saveUserTracking([
      ...restUserTracking,
      {
        ...updatedTracking
      }
    ])

    if (currentSessionExercises[activeIndex]?.category !== "training") return

    const exerciseIndex = baseCurrentTraining.findIndex(
      (exercise) => exercise.name === currentExercise.name
    )

    dispatch(setCurrentExerciseIndex(exerciseIndex))

    await saveUserSettings({
      ...userSettings,
      playerTracking: {
        ...userSettings.playerTracking,
        [workoutPart]: baseCurrentTraining.findIndex(
          (exercise) => exercise.name === currentExercise.name
        )
      }
    })
  }

  const handleEndSession = async () => {
    dispatch(setCurrentSlide(0))

    dispatch(setCurrentExerciseIndex(0))

    handleCloseSession()

    await saveUserSettings({
      ...userSettings,
      playerTracking: {
        join_unlocking: 0,
        warmup: 0,
        exercises: 0,
        abds: 0,
        stretching: 0
      }
    })
  }

  const handleUpdateUserSettings = async (userSettings: UserSettings) => {
    await saveUserSettings(userSettings)
  }

  return (
    <FaPageLayout
      padding={false}
      content={
        <div className="flex justify-center w-screen">
          <FaPlayerDesktopCard
            currentExerciseIndex={currentExerciseIndex}
            isTrainingEnd={isTrainingEnd}
            currentExercises={baseCurrentTraining[currentExerciseIndex]}
            baseCurrentTraining={baseCurrentTraining}
          />

          <div className="sticky right-0 top-0 w-full">
            <Swiper
              key={reRenderPlayer}
              initialSlide={currentSlide}
              modules={[Virtual]}
              direction="vertical"
              className="player-swiper w-screen h-dvh"
              virtual
              grabCursor
              allowSlidePrev={false}
              onSlideChange={(swiper) =>
                handleSlideChange(swiper.realIndex, swiper.isEnd)
              }
            >
              {currentSessionExercises.map((exercise, index) => (
                <SwiperSlide
                  key={exercise.name + index}
                  virtualIndex={index}
                  className={clsx(
                    "player-swiper-slide",
                    (exercise.category !== "training" ||
                      exercise.unit === "seconds") &&
                      "swiper-no-swiping"
                  )}
                >
                  <FaPlayer
                    userSettings={userSettings}
                    slideIndex={index}
                    exercise={exercise}
                    workoutPart={workoutPart}
                    userTracking={lastUserTracking}
                    currentSlide={currentSlide}
                    tracking={tracking}
                    nextExercise={
                      currentSessionExercises[
                        workoutPart === "join_unlocking" ? index + 1 : index + 2
                      ]
                    }
                    onUpdateUserSettings={handleUpdateUserSettings}
                    onCloseSession={handleCloseSession}
                  />
                </SwiperSlide>
              ))}

              <SwiperSlide virtualIndex={currentSessionExercises.length}>
                <FaEndWorkoutPart
                  workoutPart={workoutPart}
                  currentSlide={currentSlide}
                  slideIndex={currentSessionExercises.length}
                  onEndSession={handleEndSession}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      }
    />
  )
}

export default PlayerPage
