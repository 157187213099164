import { baseApi as api } from "./baseApi"
export const addTagTypes = [
  "Bodypart",
  "Category",
  "Equipment",
  "Execution-instruction",
  "Exercise",
  "Gender",
  "Level",
  "Practice",
  "Profile",
  "Training-type",
  "User-tracking",
  "Users-Permissions - Auth",
  "Users-Permissions - Users & Roles",
  "G\u00E9n\u00E9ration d'Entra\u00EEnement",
  "Gestion d'Entra\u00EEnement"
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getBodyparts: build.query<GetBodypartsApiResponse, GetBodypartsApiArg>({
        query: (queryArg) => ({
          url: `/bodyparts`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Bodypart"]
      }),
      postBodyparts: build.mutation<
        PostBodypartsApiResponse,
        PostBodypartsApiArg
      >({
        query: (queryArg) => ({
          url: `/bodyparts`,
          method: "POST",
          body: queryArg.bodypartRequest
        }),
        invalidatesTags: ["Bodypart"]
      }),
      getBodypartsById: build.query<
        GetBodypartsByIdApiResponse,
        GetBodypartsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/bodyparts/${queryArg.id}` }),
        providesTags: ["Bodypart"]
      }),
      putBodypartsById: build.mutation<
        PutBodypartsByIdApiResponse,
        PutBodypartsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/bodyparts/${queryArg.id}`,
          method: "PUT",
          body: queryArg.bodypartRequest
        }),
        invalidatesTags: ["Bodypart"]
      }),
      deleteBodypartsById: build.mutation<
        DeleteBodypartsByIdApiResponse,
        DeleteBodypartsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/bodyparts/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Bodypart"]
      }),
      getCategories: build.query<GetCategoriesApiResponse, GetCategoriesApiArg>(
        {
          query: (queryArg) => ({
            url: `/categories`,
            params: {
              sort: queryArg.sort,
              pagination: queryArg.pagination,
              fields: queryArg.fields,
              populate: queryArg.populate,
              filters: queryArg.filters,
              locale: queryArg.locale
            }
          }),
          providesTags: ["Category"]
        }
      ),
      postCategories: build.mutation<
        PostCategoriesApiResponse,
        PostCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/categories`,
          method: "POST",
          body: queryArg.categoryRequest
        }),
        invalidatesTags: ["Category"]
      }),
      getCategoriesById: build.query<
        GetCategoriesByIdApiResponse,
        GetCategoriesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/categories/${queryArg.id}` }),
        providesTags: ["Category"]
      }),
      putCategoriesById: build.mutation<
        PutCategoriesByIdApiResponse,
        PutCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/categories/${queryArg.id}`,
          method: "PUT",
          body: queryArg.categoryRequest
        }),
        invalidatesTags: ["Category"]
      }),
      deleteCategoriesById: build.mutation<
        DeleteCategoriesByIdApiResponse,
        DeleteCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/categories/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Category"]
      }),
      getEquipments: build.query<GetEquipmentsApiResponse, GetEquipmentsApiArg>(
        {
          query: (queryArg) => ({
            url: `/equipments`,
            params: {
              sort: queryArg.sort,
              pagination: queryArg.pagination,
              fields: queryArg.fields,
              populate: queryArg.populate,
              filters: queryArg.filters,
              locale: queryArg.locale
            }
          }),
          providesTags: ["Equipment"]
        }
      ),
      postEquipments: build.mutation<
        PostEquipmentsApiResponse,
        PostEquipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/equipments`,
          method: "POST",
          body: queryArg.equipmentRequest
        }),
        invalidatesTags: ["Equipment"]
      }),
      getEquipmentsById: build.query<
        GetEquipmentsByIdApiResponse,
        GetEquipmentsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/equipments/${queryArg.id}` }),
        providesTags: ["Equipment"]
      }),
      putEquipmentsById: build.mutation<
        PutEquipmentsByIdApiResponse,
        PutEquipmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/equipments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.equipmentRequest
        }),
        invalidatesTags: ["Equipment"]
      }),
      deleteEquipmentsById: build.mutation<
        DeleteEquipmentsByIdApiResponse,
        DeleteEquipmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/equipments/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Equipment"]
      }),
      getExecutionInstructions: build.query<
        GetExecutionInstructionsApiResponse,
        GetExecutionInstructionsApiArg
      >({
        query: (queryArg) => ({
          url: `/execution-instructions`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Execution-instruction"]
      }),
      postExecutionInstructions: build.mutation<
        PostExecutionInstructionsApiResponse,
        PostExecutionInstructionsApiArg
      >({
        query: (queryArg) => ({
          url: `/execution-instructions`,
          method: "POST",
          body: queryArg.executionInstructionRequest
        }),
        invalidatesTags: ["Execution-instruction"]
      }),
      getExecutionInstructionsById: build.query<
        GetExecutionInstructionsByIdApiResponse,
        GetExecutionInstructionsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/execution-instructions/${queryArg.id}`
        }),
        providesTags: ["Execution-instruction"]
      }),
      putExecutionInstructionsById: build.mutation<
        PutExecutionInstructionsByIdApiResponse,
        PutExecutionInstructionsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/execution-instructions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.executionInstructionRequest
        }),
        invalidatesTags: ["Execution-instruction"]
      }),
      deleteExecutionInstructionsById: build.mutation<
        DeleteExecutionInstructionsByIdApiResponse,
        DeleteExecutionInstructionsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/execution-instructions/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Execution-instruction"]
      }),
      getExercises: build.query<GetExercisesApiResponse, GetExercisesApiArg>({
        query: (queryArg) => ({
          url: `/exercises`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Exercise"]
      }),
      postExercises: build.mutation<
        PostExercisesApiResponse,
        PostExercisesApiArg
      >({
        query: (queryArg) => ({
          url: `/exercises`,
          method: "POST",
          body: queryArg.exerciseRequest
        }),
        invalidatesTags: ["Exercise"]
      }),
      getExercisesById: build.query<
        GetExercisesByIdApiResponse,
        GetExercisesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/exercises/${queryArg.id}` }),
        providesTags: ["Exercise"]
      }),
      putExercisesById: build.mutation<
        PutExercisesByIdApiResponse,
        PutExercisesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/exercises/${queryArg.id}`,
          method: "PUT",
          body: queryArg.exerciseRequest
        }),
        invalidatesTags: ["Exercise"]
      }),
      deleteExercisesById: build.mutation<
        DeleteExercisesByIdApiResponse,
        DeleteExercisesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/exercises/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Exercise"]
      }),
      getGenders: build.query<GetGendersApiResponse, GetGendersApiArg>({
        query: (queryArg) => ({
          url: `/genders`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Gender"]
      }),
      postGenders: build.mutation<PostGendersApiResponse, PostGendersApiArg>({
        query: (queryArg) => ({
          url: `/genders`,
          method: "POST",
          body: queryArg.genderRequest
        }),
        invalidatesTags: ["Gender"]
      }),
      getGendersById: build.query<
        GetGendersByIdApiResponse,
        GetGendersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/genders/${queryArg.id}` }),
        providesTags: ["Gender"]
      }),
      putGendersById: build.mutation<
        PutGendersByIdApiResponse,
        PutGendersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/genders/${queryArg.id}`,
          method: "PUT",
          body: queryArg.genderRequest
        }),
        invalidatesTags: ["Gender"]
      }),
      deleteGendersById: build.mutation<
        DeleteGendersByIdApiResponse,
        DeleteGendersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/genders/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Gender"]
      }),
      getLevels: build.query<GetLevelsApiResponse, GetLevelsApiArg>({
        query: (queryArg) => ({
          url: `/levels`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Level"]
      }),
      postLevels: build.mutation<PostLevelsApiResponse, PostLevelsApiArg>({
        query: (queryArg) => ({
          url: `/levels`,
          method: "POST",
          body: queryArg.levelRequest
        }),
        invalidatesTags: ["Level"]
      }),
      getLevelsById: build.query<GetLevelsByIdApiResponse, GetLevelsByIdApiArg>(
        {
          query: (queryArg) => ({ url: `/levels/${queryArg.id}` }),
          providesTags: ["Level"]
        }
      ),
      putLevelsById: build.mutation<
        PutLevelsByIdApiResponse,
        PutLevelsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/levels/${queryArg.id}`,
          method: "PUT",
          body: queryArg.levelRequest
        }),
        invalidatesTags: ["Level"]
      }),
      deleteLevelsById: build.mutation<
        DeleteLevelsByIdApiResponse,
        DeleteLevelsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/levels/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Level"]
      }),
      getPractices: build.query<GetPracticesApiResponse, GetPracticesApiArg>({
        query: (queryArg) => ({
          url: `/practices`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Practice"]
      }),
      postPractices: build.mutation<
        PostPracticesApiResponse,
        PostPracticesApiArg
      >({
        query: (queryArg) => ({
          url: `/practices`,
          method: "POST",
          body: queryArg.practiceRequest
        }),
        invalidatesTags: ["Practice"]
      }),
      getPracticesById: build.query<
        GetPracticesByIdApiResponse,
        GetPracticesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/practices/${queryArg.id}` }),
        providesTags: ["Practice"]
      }),
      putPracticesById: build.mutation<
        PutPracticesByIdApiResponse,
        PutPracticesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/practices/${queryArg.id}`,
          method: "PUT",
          body: queryArg.practiceRequest
        }),
        invalidatesTags: ["Practice"]
      }),
      deletePracticesById: build.mutation<
        DeletePracticesByIdApiResponse,
        DeletePracticesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/practices/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Practice"]
      }),
      getProfiles: build.query<GetProfilesApiResponse, GetProfilesApiArg>({
        query: (queryArg) => ({
          url: `/profiles`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Profile"]
      }),
      postProfiles: build.mutation<PostProfilesApiResponse, PostProfilesApiArg>(
        {
          query: (queryArg) => ({
            url: `/profiles`,
            method: "POST",
            body: queryArg.profileRequest
          }),
          invalidatesTags: ["Profile"]
        }
      ),
      getProfilesById: build.query<
        GetProfilesByIdApiResponse,
        GetProfilesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/profiles/${queryArg.id}` }),
        providesTags: ["Profile"]
      }),
      putProfilesById: build.mutation<
        PutProfilesByIdApiResponse,
        PutProfilesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/profiles/${queryArg.id}`,
          method: "PUT",
          body: queryArg.profileRequest
        }),
        invalidatesTags: ["Profile"]
      }),
      deleteProfilesById: build.mutation<
        DeleteProfilesByIdApiResponse,
        DeleteProfilesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/profiles/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Profile"]
      }),
      getTrainingTypes: build.query<
        GetTrainingTypesApiResponse,
        GetTrainingTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/training-types`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["Training-type"]
      }),
      postTrainingTypes: build.mutation<
        PostTrainingTypesApiResponse,
        PostTrainingTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/training-types`,
          method: "POST",
          body: queryArg.trainingTypeRequest
        }),
        invalidatesTags: ["Training-type"]
      }),
      getTrainingTypesById: build.query<
        GetTrainingTypesByIdApiResponse,
        GetTrainingTypesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/training-types/${queryArg.id}` }),
        providesTags: ["Training-type"]
      }),
      putTrainingTypesById: build.mutation<
        PutTrainingTypesByIdApiResponse,
        PutTrainingTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/training-types/${queryArg.id}`,
          method: "PUT",
          body: queryArg.trainingTypeRequest
        }),
        invalidatesTags: ["Training-type"]
      }),
      deleteTrainingTypesById: build.mutation<
        DeleteTrainingTypesByIdApiResponse,
        DeleteTrainingTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/training-types/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Training-type"]
      }),
      getUserTrackings: build.query<
        GetUserTrackingsApiResponse,
        GetUserTrackingsApiArg
      >({
        query: (queryArg) => ({
          url: `/user-trackings`,
          params: {
            sort: queryArg.sort,
            pagination: queryArg.pagination,
            fields: queryArg.fields,
            populate: queryArg.populate,
            filters: queryArg.filters,
            locale: queryArg.locale
          }
        }),
        providesTags: ["User-tracking"]
      }),
      postUserTrackings: build.mutation<
        PostUserTrackingsApiResponse,
        PostUserTrackingsApiArg
      >({
        query: (queryArg) => ({
          url: `/user-trackings`,
          method: "POST",
          body: queryArg.userTrackingRequest
        }),
        invalidatesTags: ["User-tracking"]
      }),
      getUserTrackingsById: build.query<
        GetUserTrackingsByIdApiResponse,
        GetUserTrackingsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/user-trackings/${queryArg.id}` }),
        providesTags: ["User-tracking"]
      }),
      putUserTrackingsById: build.mutation<
        PutUserTrackingsByIdApiResponse,
        PutUserTrackingsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/user-trackings/${queryArg.id}`,
          method: "PUT",
          body: queryArg.userTrackingRequest
        }),
        invalidatesTags: ["User-tracking"]
      }),
      deleteUserTrackingsById: build.mutation<
        DeleteUserTrackingsByIdApiResponse,
        DeleteUserTrackingsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/user-trackings/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["User-tracking"]
      }),
      getConnectByProvider: build.query<
        GetConnectByProviderApiResponse,
        GetConnectByProviderApiArg
      >({
        query: (queryArg) => ({ url: `/connect/${queryArg.provider}` }),
        providesTags: ["Users-Permissions - Auth"]
      }),
      postAuthLocal: build.mutation<
        PostAuthLocalApiResponse,
        PostAuthLocalApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/local`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Auth"]
      }),
      postAuthLocalRegister: build.mutation<
        PostAuthLocalRegisterApiResponse,
        PostAuthLocalRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/local/register`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Auth"]
      }),
      getAuthByProviderCallback: build.query<
        GetAuthByProviderCallbackApiResponse,
        GetAuthByProviderCallbackApiArg
      >({
        query: (queryArg) => ({ url: `/auth/${queryArg.provider}/callback` }),
        providesTags: ["Users-Permissions - Auth"]
      }),
      postAuthForgotPassword: build.mutation<
        PostAuthForgotPasswordApiResponse,
        PostAuthForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/forgot-password`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Auth"]
      }),
      postAuthResetPassword: build.mutation<
        PostAuthResetPasswordApiResponse,
        PostAuthResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/reset-password`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Auth"]
      }),
      postAuthChangePassword: build.mutation<
        PostAuthChangePasswordApiResponse,
        PostAuthChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/change-password`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Auth"]
      }),
      getAuthEmailConfirmation: build.query<
        GetAuthEmailConfirmationApiResponse,
        GetAuthEmailConfirmationApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/email-confirmation`,
          params: { confirmation: queryArg.confirmation }
        }),
        providesTags: ["Users-Permissions - Auth"]
      }),
      postAuthSendEmailConfirmation: build.mutation<
        PostAuthSendEmailConfirmationApiResponse,
        PostAuthSendEmailConfirmationApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/send-email-confirmation`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Auth"]
      }),
      getUsersPermissionsPermissions: build.query<
        GetUsersPermissionsPermissionsApiResponse,
        GetUsersPermissionsPermissionsApiArg
      >({
        query: () => ({ url: `/users-permissions/permissions` }),
        providesTags: ["Users-Permissions - Users & Roles"]
      }),
      getUsersPermissionsRoles: build.query<
        GetUsersPermissionsRolesApiResponse,
        GetUsersPermissionsRolesApiArg
      >({
        query: () => ({ url: `/users-permissions/roles` }),
        providesTags: ["Users-Permissions - Users & Roles"]
      }),
      postUsersPermissionsRoles: build.mutation<
        PostUsersPermissionsRolesApiResponse,
        PostUsersPermissionsRolesApiArg
      >({
        query: (queryArg) => ({
          url: `/users-permissions/roles`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Users & Roles"]
      }),
      getUsersPermissionsRolesById: build.query<
        GetUsersPermissionsRolesByIdApiResponse,
        GetUsersPermissionsRolesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users-permissions/roles/${queryArg.id}`
        }),
        providesTags: ["Users-Permissions - Users & Roles"]
      }),
      putUsersPermissionsRolesByRole: build.mutation<
        PutUsersPermissionsRolesByRoleApiResponse,
        PutUsersPermissionsRolesByRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/users-permissions/roles/${queryArg.role}`,
          method: "PUT",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Users & Roles"]
      }),
      deleteUsersPermissionsRolesByRole: build.mutation<
        DeleteUsersPermissionsRolesByRoleApiResponse,
        DeleteUsersPermissionsRolesByRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/users-permissions/roles/${queryArg.role}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Users-Permissions - Users & Roles"]
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: () => ({ url: `/users` }),
        providesTags: ["Users-Permissions - Users & Roles"]
      }),
      postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
        query: (queryArg) => ({
          url: `/users`,
          method: "POST",
          body: queryArg.body
        }),
        invalidatesTags: ["Users-Permissions - Users & Roles"]
      }),
      getUsersById: build.query<GetUsersByIdApiResponse, GetUsersByIdApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg.id}` }),
        providesTags: ["Users-Permissions - Users & Roles"]
      }),
      putUsersById: build.mutation<PutUsersByIdApiResponse, PutUsersByIdApiArg>(
        {
          query: (queryArg) => ({
            url: `/users/${queryArg.id}`,
            method: "PUT",
            body: queryArg.body
          }),
          invalidatesTags: ["Users-Permissions - Users & Roles"]
        }
      ),
      deleteUsersById: build.mutation<
        DeleteUsersByIdApiResponse,
        DeleteUsersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Users-Permissions - Users & Roles"]
      }),
      getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
        query: () => ({ url: `/users/me` }),
        providesTags: ["Users-Permissions - Users & Roles"]
      }),
      getUsersCount: build.query<GetUsersCountApiResponse, GetUsersCountApiArg>(
        {
          query: () => ({ url: `/users/count` }),
          providesTags: ["Users-Permissions - Users & Roles"]
        }
      ),
      postGenerateTestWkgWorkout: build.mutation<
        PostGenerateTestWkgWorkoutApiResponse,
        PostGenerateTestWkgWorkoutApiArg
      >({
        query: (queryArg) => ({
          url: `/generate-test-wkg-workout`,
          method: "POST",
          body: queryArg.createTestWkgWorkoutRequestBody
        }),
        invalidatesTags: ["G\u00E9n\u00E9ration d'Entra\u00EEnement"]
      }),
      postGenerateWkgWorkout: build.mutation<
        PostGenerateWkgWorkoutApiResponse,
        PostGenerateWkgWorkoutApiArg
      >({
        query: (queryArg) => ({
          url: `/generate-wkg-workout`,
          method: "POST",
          body: queryArg.createWkgWorkoutRequestBody
        }),
        invalidatesTags: ["G\u00E9n\u00E9ration d'Entra\u00EEnement"]
      }),
      deleteDeleteWkgWorkoutByUserId: build.mutation<
        DeleteDeleteWkgWorkoutByUserIdApiResponse,
        DeleteDeleteWkgWorkoutByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/delete-wkg-workout/${queryArg.userId}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Gestion d'Entra\u00EEnement"]
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as WorkoutGenApi }
export type GetBodypartsApiResponse = /** status 200 OK */ BodypartListResponse
export type GetBodypartsApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostBodypartsApiResponse = /** status 200 OK */ BodypartResponse
export type PostBodypartsApiArg = {
  bodypartRequest: BodypartRequest
}
export type GetBodypartsByIdApiResponse = /** status 200 OK */ BodypartResponse
export type GetBodypartsByIdApiArg = {
  id: number
}
export type PutBodypartsByIdApiResponse = /** status 200 OK */ BodypartResponse
export type PutBodypartsByIdApiArg = {
  id: number
  bodypartRequest: BodypartRequest
}
export type DeleteBodypartsByIdApiResponse = /** status 200 OK */ number
export type DeleteBodypartsByIdApiArg = {
  id: number
}
export type GetCategoriesApiResponse = /** status 200 OK */ CategoryListResponse
export type GetCategoriesApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostCategoriesApiResponse = /** status 200 OK */ CategoryResponse
export type PostCategoriesApiArg = {
  categoryRequest: CategoryRequest
}
export type GetCategoriesByIdApiResponse = /** status 200 OK */ CategoryResponse
export type GetCategoriesByIdApiArg = {
  id: number
}
export type PutCategoriesByIdApiResponse = /** status 200 OK */ CategoryResponse
export type PutCategoriesByIdApiArg = {
  id: number
  categoryRequest: CategoryRequest
}
export type DeleteCategoriesByIdApiResponse = /** status 200 OK */ number
export type DeleteCategoriesByIdApiArg = {
  id: number
}
export type GetEquipmentsApiResponse =
  /** status 200 OK */ EquipmentListResponse
export type GetEquipmentsApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostEquipmentsApiResponse = /** status 200 OK */ EquipmentResponse
export type PostEquipmentsApiArg = {
  equipmentRequest: EquipmentRequest
}
export type GetEquipmentsByIdApiResponse =
  /** status 200 OK */ EquipmentResponse
export type GetEquipmentsByIdApiArg = {
  id: number
}
export type PutEquipmentsByIdApiResponse =
  /** status 200 OK */ EquipmentResponse
export type PutEquipmentsByIdApiArg = {
  id: number
  equipmentRequest: EquipmentRequest
}
export type DeleteEquipmentsByIdApiResponse = /** status 200 OK */ number
export type DeleteEquipmentsByIdApiArg = {
  id: number
}
export type GetExecutionInstructionsApiResponse =
  /** status 200 OK */ ExecutionInstructionListResponse
export type GetExecutionInstructionsApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostExecutionInstructionsApiResponse =
  /** status 200 OK */ ExecutionInstructionResponse
export type PostExecutionInstructionsApiArg = {
  executionInstructionRequest: ExecutionInstructionRequest
}
export type GetExecutionInstructionsByIdApiResponse =
  /** status 200 OK */ ExecutionInstructionResponse
export type GetExecutionInstructionsByIdApiArg = {
  id: number
}
export type PutExecutionInstructionsByIdApiResponse =
  /** status 200 OK */ ExecutionInstructionResponse
export type PutExecutionInstructionsByIdApiArg = {
  id: number
  executionInstructionRequest: ExecutionInstructionRequest
}
export type DeleteExecutionInstructionsByIdApiResponse =
  /** status 200 OK */ number
export type DeleteExecutionInstructionsByIdApiArg = {
  id: number
}
export type GetExercisesApiResponse = /** status 200 OK */ ExerciseListResponse
export type GetExercisesApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostExercisesApiResponse = /** status 200 OK */ ExerciseResponse
export type PostExercisesApiArg = {
  exerciseRequest: ExerciseRequest
}
export type GetExercisesByIdApiResponse = /** status 200 OK */ ExerciseResponse
export type GetExercisesByIdApiArg = {
  id: number
}
export type PutExercisesByIdApiResponse = /** status 200 OK */ ExerciseResponse
export type PutExercisesByIdApiArg = {
  id: number
  exerciseRequest: ExerciseRequest
}
export type DeleteExercisesByIdApiResponse = /** status 200 OK */ number
export type DeleteExercisesByIdApiArg = {
  id: number
}
export type GetGendersApiResponse = /** status 200 OK */ GenderListResponse
export type GetGendersApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostGendersApiResponse = /** status 200 OK */ GenderResponse
export type PostGendersApiArg = {
  genderRequest: GenderRequest
}
export type GetGendersByIdApiResponse = /** status 200 OK */ GenderResponse
export type GetGendersByIdApiArg = {
  id: number
}
export type PutGendersByIdApiResponse = /** status 200 OK */ GenderResponse
export type PutGendersByIdApiArg = {
  id: number
  genderRequest: GenderRequest
}
export type DeleteGendersByIdApiResponse = /** status 200 OK */ number
export type DeleteGendersByIdApiArg = {
  id: number
}
export type GetLevelsApiResponse = /** status 200 OK */ LevelListResponse
export type GetLevelsApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostLevelsApiResponse = /** status 200 OK */ LevelResponse
export type PostLevelsApiArg = {
  levelRequest: LevelRequest
}
export type GetLevelsByIdApiResponse = /** status 200 OK */ LevelResponse
export type GetLevelsByIdApiArg = {
  id: number
}
export type PutLevelsByIdApiResponse = /** status 200 OK */ LevelResponse
export type PutLevelsByIdApiArg = {
  id: number
  levelRequest: LevelRequest
}
export type DeleteLevelsByIdApiResponse = /** status 200 OK */ number
export type DeleteLevelsByIdApiArg = {
  id: number
}
export type GetPracticesApiResponse = /** status 200 OK */ PracticeListResponse
export type GetPracticesApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostPracticesApiResponse = /** status 200 OK */ PracticeResponse
export type PostPracticesApiArg = {
  practiceRequest: PracticeRequest
}
export type GetPracticesByIdApiResponse = /** status 200 OK */ PracticeResponse
export type GetPracticesByIdApiArg = {
  id: number
}
export type PutPracticesByIdApiResponse = /** status 200 OK */ PracticeResponse
export type PutPracticesByIdApiArg = {
  id: number
  practiceRequest: PracticeRequest
}
export type DeletePracticesByIdApiResponse = /** status 200 OK */ number
export type DeletePracticesByIdApiArg = {
  id: number
}
export type GetProfilesApiResponse = /** status 200 OK */ ProfileListResponse
export type GetProfilesApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostProfilesApiResponse = /** status 200 OK */ ProfileResponse
export type PostProfilesApiArg = {
  profileRequest: ProfileRequest
}
export type GetProfilesByIdApiResponse = /** status 200 OK */ ProfileResponse
export type GetProfilesByIdApiArg = {
  id: number
}
export type PutProfilesByIdApiResponse = /** status 200 OK */ ProfileResponse
export type PutProfilesByIdApiArg = {
  id: number
  profileRequest: ProfileRequest
}
export type DeleteProfilesByIdApiResponse = /** status 200 OK */ number
export type DeleteProfilesByIdApiArg = {
  id: number
}
export type GetTrainingTypesApiResponse =
  /** status 200 OK */ TrainingTypeListResponse
export type GetTrainingTypesApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostTrainingTypesApiResponse =
  /** status 200 OK */ TrainingTypeResponse
export type PostTrainingTypesApiArg = {
  trainingTypeRequest: TrainingTypeRequest
}
export type GetTrainingTypesByIdApiResponse =
  /** status 200 OK */ TrainingTypeResponse
export type GetTrainingTypesByIdApiArg = {
  id: number
}
export type PutTrainingTypesByIdApiResponse =
  /** status 200 OK */ TrainingTypeResponse
export type PutTrainingTypesByIdApiArg = {
  id: number
  trainingTypeRequest: TrainingTypeRequest
}
export type DeleteTrainingTypesByIdApiResponse = /** status 200 OK */ number
export type DeleteTrainingTypesByIdApiArg = {
  id: number
}
export type GetUserTrackingsApiResponse =
  /** status 200 OK */ UserTrackingListResponse
export type GetUserTrackingsApiArg = {
  /** Sort by attributes ascending (asc) or descending (desc) */
  sort?: string
  pagination?: {
    withCount?: boolean
    page?: number
    pageSize?: number
    start?: number
    limit?: number
  }
  /** Fields to return (ex: title,author) */
  fields?: string
  /** Relations to return */
  populate?: string
  /** Filters to apply */
  filters?: {
    [key: string]: any
  }
  /** Locale to apply */
  locale?: string
}
export type PostUserTrackingsApiResponse =
  /** status 200 OK */ UserTrackingResponse
export type PostUserTrackingsApiArg = {
  userTrackingRequest: UserTrackingRequest
}
export type GetUserTrackingsByIdApiResponse =
  /** status 200 OK */ UserTrackingResponse
export type GetUserTrackingsByIdApiArg = {
  id: number
}
export type PutUserTrackingsByIdApiResponse =
  /** status 200 OK */ UserTrackingResponse
export type PutUserTrackingsByIdApiArg = {
  id: number
  userTrackingRequest: UserTrackingRequest
}
export type DeleteUserTrackingsByIdApiResponse = /** status 200 OK */ number
export type DeleteUserTrackingsByIdApiArg = {
  id: number
}
export type GetConnectByProviderApiResponse = unknown
export type GetConnectByProviderApiArg = {
  /** Provider name */
  provider: string
}
export type PostAuthLocalApiResponse =
  /** status 200 Connection */ UsersPermissionsUserRegistration
export type PostAuthLocalApiArg = {
  body: {
    identifier?: string
    password?: string
  }
}
export type PostAuthLocalRegisterApiResponse =
  /** status 200 Successful registration */ UsersPermissionsUserRegistration
export type PostAuthLocalRegisterApiArg = {
  body: {
    username?: string
    email?: string
    password?: string
  }
}
export type GetAuthByProviderCallbackApiResponse =
  /** status 200 Returns a jwt token and user info */ UsersPermissionsUserRegistration
export type GetAuthByProviderCallbackApiArg = {
  /** Provider name */
  provider: string
}
export type PostAuthForgotPasswordApiResponse = /** status 200 Returns ok */ {
  ok?: true
}
export type PostAuthForgotPasswordApiArg = {
  body: {
    email?: string
  }
}
export type PostAuthResetPasswordApiResponse =
  /** status 200 Returns a jwt token and user info */ UsersPermissionsUserRegistration
export type PostAuthResetPasswordApiArg = {
  body: {
    password?: string
    passwordConfirmation?: string
    code?: string
  }
}
export type PostAuthChangePasswordApiResponse =
  /** status 200 Returns a jwt token and user info */ UsersPermissionsUserRegistration
export type PostAuthChangePasswordApiArg = {
  body: {
    password: string
    currentPassword: string
    passwordConfirmation: string
  }
}
export type GetAuthEmailConfirmationApiResponse = unknown
export type GetAuthEmailConfirmationApiArg = {
  /** confirmation token received by email */
  confirmation?: string
}
export type PostAuthSendEmailConfirmationApiResponse =
  /** status 200 Returns email and boolean to confirm email was sent */ {
    email?: string
    sent?: true
  }
export type PostAuthSendEmailConfirmationApiArg = {
  body: {
    email?: string
  }
}
export type GetUsersPermissionsPermissionsApiResponse =
  /** status 200 Returns the permissions tree */ {
    permissions?: UsersPermissionsPermissionsTree
  }
export type GetUsersPermissionsPermissionsApiArg = void
export type GetUsersPermissionsRolesApiResponse =
  /** status 200 Returns list of roles */ {
    roles?: (UsersPermissionsRole & {
      nb_users?: number
    })[]
  }
export type GetUsersPermissionsRolesApiArg = void
export type PostUsersPermissionsRolesApiResponse =
  /** status 200 Returns ok if the role was create */ {
    ok?: true
  }
export type PostUsersPermissionsRolesApiArg = {
  body: {
    name?: string
    description?: string
    type?: string
    permissions?: UsersPermissionsPermissionsTree
  }
}
export type GetUsersPermissionsRolesByIdApiResponse =
  /** status 200 Returns the role */ {
    role?: UsersPermissionsRole
  }
export type GetUsersPermissionsRolesByIdApiArg = {
  /** role Id */
  id: string
}
export type PutUsersPermissionsRolesByRoleApiResponse =
  /** status 200 Returns ok if the role was udpated */ {
    ok?: true
  }
export type PutUsersPermissionsRolesByRoleApiArg = {
  /** role Id */
  role: string
  body: {
    name?: string
    description?: string
    type?: string
    permissions?: UsersPermissionsPermissionsTree
  }
}
export type DeleteUsersPermissionsRolesByRoleApiResponse =
  /** status 200 Returns ok if the role was delete */ {
    ok?: true
  }
export type DeleteUsersPermissionsRolesByRoleApiArg = {
  /** role Id */
  role: string
}
export type GetUsersApiResponse =
  /** status 200 Returns an array of users */ UsersPermissionsUser[]
export type GetUsersApiArg = void
export type PostUsersApiResponse =
  /** status 201 Returns created user info */ UsersPermissionsUser & {
    role?: UsersPermissionsRole
  }
export type PostUsersApiArg = {
  body: {
    email: string
    username: string
    password: string
  }
}
export type GetUsersByIdApiResponse =
  /** status 200 Returns a user */ UsersPermissionsUser
export type GetUsersByIdApiArg = {
  /** user Id */
  id: string
}
export type PutUsersByIdApiResponse =
  /** status 200 Returns updated user info */ UsersPermissionsUser & {
    role?: UsersPermissionsRole
  }
export type PutUsersByIdApiArg = {
  /** user Id */
  id: string
  body: {
    email: string
    username: string
    password: string
  }
}
export type DeleteUsersByIdApiResponse =
  /** status 200 Returns deleted user info */ UsersPermissionsUser
export type DeleteUsersByIdApiArg = {
  /** user Id */
  id: string
}
export type GetUsersMeApiResponse =
  /** status 200 Returns user info */ UsersPermissionsUser
export type GetUsersMeApiArg = void
export type GetUsersCountApiResponse = /** status 200 Returns a number */ number
export type GetUsersCountApiArg = void
export type PostGenerateTestWkgWorkoutApiResponse =
  /** status 200 Réponse réussie */ ApiWkgWorkout
export type PostGenerateTestWkgWorkoutApiArg = {
  createTestWkgWorkoutRequestBody: CreateTestWkgWorkoutRequestBody
}
export type PostGenerateWkgWorkoutApiResponse =
  /** status 200 Réponse réussie */ ApiWkgWorkout
export type PostGenerateWkgWorkoutApiArg = {
  createWkgWorkoutRequestBody: CreateWkgWorkoutRequestBody
}
export type DeleteDeleteWkgWorkoutByUserIdApiResponse =
  /** status 200 Données de l'utilisateur supprimées avec succès */ DeleteWkgWorkoutResponse
export type DeleteDeleteWkgWorkoutByUserIdApiArg = {
  /** Identifiant de l'utilisateur */
  userId: string
}
export type Bodypart = {
  id?: number
  documentId?: string
  name: string
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
    firstname?: string
    lastname?: string
    username?: string
    email?: string
    resetPasswordToken?: string
    registrationToken?: string
    isActive?: boolean
    roles?: {
      id?: number
      documentId?: string
      name?: string
      code?: string
      description?: string
      users?: {
        id?: number
        documentId?: string
      }[]
      permissions?: {
        id?: number
        documentId?: string
        action?: string
        actionParameters?: any
        subject?: string
        properties?: any
        conditions?: any
        role?: {
          id?: number
          documentId?: string
        }
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }[]
    blocked?: boolean
    preferedLanguage?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type BodypartListResponse = {
  data?: Bodypart[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type Error = {
  data?: ((object | null) | (object[] | null)) | null
  error: {
    status?: number
    name?: string
    message?: string
    details?: object
  }
}
export type BodypartResponse = {
  data?: Bodypart
  meta?: object
}
export type BodypartRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type Category = {
  id?: number
  documentId?: string
  name: string
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
    firstname?: string
    lastname?: string
    username?: string
    email?: string
    resetPasswordToken?: string
    registrationToken?: string
    isActive?: boolean
    roles?: {
      id?: number
      documentId?: string
      name?: string
      code?: string
      description?: string
      users?: {
        id?: number
        documentId?: string
      }[]
      permissions?: {
        id?: number
        documentId?: string
        action?: string
        actionParameters?: any
        subject?: string
        properties?: any
        conditions?: any
        role?: {
          id?: number
          documentId?: string
        }
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }[]
    blocked?: boolean
    preferedLanguage?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type CategoryListResponse = {
  data?: Category[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type CategoryResponse = {
  data?: Category
  meta?: object
}
export type CategoryRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type Equipment = {
  id?: number
  documentId?: string
  name: string
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
    firstname?: string
    lastname?: string
    username?: string
    email?: string
    resetPasswordToken?: string
    registrationToken?: string
    isActive?: boolean
    roles?: {
      id?: number
      documentId?: string
      name?: string
      code?: string
      description?: string
      users?: {
        id?: number
        documentId?: string
      }[]
      permissions?: {
        id?: number
        documentId?: string
        action?: string
        actionParameters?: any
        subject?: string
        properties?: any
        conditions?: any
        role?: {
          id?: number
          documentId?: string
        }
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }[]
    blocked?: boolean
    preferedLanguage?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type EquipmentListResponse = {
  data?: Equipment[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type EquipmentResponse = {
  data?: Equipment
  meta?: object
}
export type EquipmentRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type ExecutionInstruction = {
  id?: number
  documentId?: string
  name: string
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
    firstname?: string
    lastname?: string
    username?: string
    email?: string
    resetPasswordToken?: string
    registrationToken?: string
    isActive?: boolean
    roles?: {
      id?: number
      documentId?: string
      name?: string
      code?: string
      description?: string
      users?: {
        id?: number
        documentId?: string
      }[]
      permissions?: {
        id?: number
        documentId?: string
        action?: string
        actionParameters?: any
        subject?: string
        properties?: any
        conditions?: any
        role?: {
          id?: number
          documentId?: string
        }
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }[]
    blocked?: boolean
    preferedLanguage?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type ExecutionInstructionListResponse = {
  data?: ExecutionInstruction[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type ExecutionInstructionResponse = {
  data?: ExecutionInstruction
  meta?: object
}
export type ExecutionInstructionRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type Exercise = {
  id?: number
  documentId?: string
  move_start?: {
    id?: number
    documentId?: string
    name?: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash?: string
    ext?: string
    mime?: string
    size?: number
    url?: string
    previewUrl?: string
    provider?: string
    provider_metadata?: any
    related?: {
      id?: number
      documentId?: string
    }[]
    folder?: {
      id?: number
      documentId?: string
      name?: string
      pathId?: number
      parent?: {
        id?: number
        documentId?: string
      }
      children?: {
        id?: number
        documentId?: string
      }[]
      files?: {
        id?: number
        documentId?: string
        name?: string
        alternativeText?: string
        caption?: string
        width?: number
        height?: number
        formats?: any
        hash?: string
        ext?: string
        mime?: string
        size?: number
        url?: string
        previewUrl?: string
        provider?: string
        provider_metadata?: any
        related?: {
          id?: number
          documentId?: string
        }[]
        folder?: {
          id?: number
          documentId?: string
        }
        folderPath?: string
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
          firstname?: string
          lastname?: string
          username?: string
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            id?: number
            documentId?: string
            name?: string
            code?: string
            description?: string
            users?: {
              id?: number
              documentId?: string
            }[]
            permissions?: {
              id?: number
              documentId?: string
              action?: string
              actionParameters?: any
              subject?: string
              properties?: any
              conditions?: any
              role?: {
                id?: number
                documentId?: string
              }
              createdAt?: string
              updatedAt?: string
              publishedAt?: string
              createdBy?: {
                id?: number
                documentId?: string
              }
              updatedBy?: {
                id?: number
                documentId?: string
              }
              locale?: string
              localizations?: {
                id?: number
                documentId?: string
              }[]
            }[]
            createdAt?: string
            updatedAt?: string
            publishedAt?: string
            createdBy?: {
              id?: number
              documentId?: string
            }
            updatedBy?: {
              id?: number
              documentId?: string
            }
            locale?: string
            localizations?: {
              id?: number
              documentId?: string
            }[]
          }[]
          blocked?: boolean
          preferedLanguage?: string
          createdAt?: string
          updatedAt?: string
          publishedAt?: string
          createdBy?: {
            id?: number
            documentId?: string
          }
          updatedBy?: {
            id?: number
            documentId?: string
          }
          locale?: string
          localizations?: {
            id?: number
            documentId?: string
          }[]
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      path?: string
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }
    folderPath?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  move_end?: {
    id?: number
    documentId?: string
    name?: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash?: string
    ext?: string
    mime?: string
    size?: number
    url?: string
    previewUrl?: string
    provider?: string
    provider_metadata?: any
    related?: {
      id?: number
      documentId?: string
    }[]
    folder?: {
      id?: number
      documentId?: string
    }
    folderPath?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  move_video?: {
    id?: number
    documentId?: string
    name?: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash?: string
    ext?: string
    mime?: string
    size?: number
    url?: string
    previewUrl?: string
    provider?: string
    provider_metadata?: any
    related?: {
      id?: number
      documentId?: string
    }[]
    folder?: {
      id?: number
      documentId?: string
    }
    folderPath?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  is_poly_articular: boolean
  bodyparts?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  categories?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  equipments?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  execution?: string
  practices?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  genders?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  levels?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  execution_instructions?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  is_main: boolean
  name: string
  training_types?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    move_start?: {
      id?: number
      documentId?: string
      name?: string
      alternativeText?: string
      caption?: string
      width?: number
      height?: number
      formats?: any
      hash?: string
      ext?: string
      mime?: string
      size?: number
      url?: string
      previewUrl?: string
      provider?: string
      provider_metadata?: any
      related?: {
        id?: number
        documentId?: string
      }[]
      folder?: {
        id?: number
        documentId?: string
      }
      folderPath?: string
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }
    move_end?: {
      id?: number
      documentId?: string
      name?: string
      alternativeText?: string
      caption?: string
      width?: number
      height?: number
      formats?: any
      hash?: string
      ext?: string
      mime?: string
      size?: number
      url?: string
      previewUrl?: string
      provider?: string
      provider_metadata?: any
      related?: {
        id?: number
        documentId?: string
      }[]
      folder?: {
        id?: number
        documentId?: string
      }
      folderPath?: string
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }
    move_video?: {
      id?: number
      documentId?: string
      name?: string
      alternativeText?: string
      caption?: string
      width?: number
      height?: number
      formats?: any
      hash?: string
      ext?: string
      mime?: string
      size?: number
      url?: string
      previewUrl?: string
      provider?: string
      provider_metadata?: any
      related?: {
        id?: number
        documentId?: string
      }[]
      folder?: {
        id?: number
        documentId?: string
      }
      folderPath?: string
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }
    is_poly_articular?: boolean
    bodyparts?: {
      id?: number
      documentId?: string
    }[]
    categories?: {
      id?: number
      documentId?: string
    }[]
    equipments?: {
      id?: number
      documentId?: string
    }[]
    execution?: string
    practices?: {
      id?: number
      documentId?: string
    }[]
    genders?: {
      id?: number
      documentId?: string
    }[]
    levels?: {
      id?: number
      documentId?: string
    }[]
    execution_instructions?: {
      id?: number
      documentId?: string
    }[]
    is_main?: boolean
    name?: string
    training_types?: {
      id?: number
      documentId?: string
    }[]
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type ExerciseListResponse = {
  data?: Exercise[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type ExerciseResponse = {
  data?: Exercise
  meta?: object
}
export type ExerciseRequest = {
  data: {
    move_start?: number | string
    move_end?: number | string
    move_video?: number | string
    is_poly_articular: boolean
    bodyparts?: (number | string)[]
    categories?: (number | string)[]
    equipments?: (number | string)[]
    execution?: string
    practices?: (number | string)[]
    genders?: (number | string)[]
    levels?: (number | string)[]
    execution_instructions?: (number | string)[]
    is_main: boolean
    name: string
    training_types?: (number | string)[]
    locale?: string
    localizations?: (number | string)[]
  }
}
export type Gender = {
  id?: number
  documentId?: string
  name: string
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
    firstname?: string
    lastname?: string
    username?: string
    email?: string
    resetPasswordToken?: string
    registrationToken?: string
    isActive?: boolean
    roles?: {
      id?: number
      documentId?: string
      name?: string
      code?: string
      description?: string
      users?: {
        id?: number
        documentId?: string
      }[]
      permissions?: {
        id?: number
        documentId?: string
        action?: string
        actionParameters?: any
        subject?: string
        properties?: any
        conditions?: any
        role?: {
          id?: number
          documentId?: string
        }
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }[]
    blocked?: boolean
    preferedLanguage?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type GenderListResponse = {
  data?: Gender[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type GenderResponse = {
  data?: Gender
  meta?: object
}
export type GenderRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type Level = {
  id?: number
  documentId?: string
  name: string
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
    firstname?: string
    lastname?: string
    username?: string
    email?: string
    resetPasswordToken?: string
    registrationToken?: string
    isActive?: boolean
    roles?: {
      id?: number
      documentId?: string
      name?: string
      code?: string
      description?: string
      users?: {
        id?: number
        documentId?: string
      }[]
      permissions?: {
        id?: number
        documentId?: string
        action?: string
        actionParameters?: any
        subject?: string
        properties?: any
        conditions?: any
        role?: {
          id?: number
          documentId?: string
        }
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }[]
    blocked?: boolean
    preferedLanguage?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type LevelListResponse = {
  data?: Level[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type LevelResponse = {
  data?: Level
  meta?: object
}
export type LevelRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type Practice = "indoor" | "outdoor" | "all"
export type PracticeListResponse = {
  data?: Practice[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type PracticeResponse = {
  data?: Practice
  meta?: object
}
export type PracticeRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type Profile = {
  id?: number
  documentId?: string
  size: number
  weight: number
  age: number
  gender: "all" | "men" | "women"
  morphotype: "ectomorph" | "mesomorph" | "endomorph"
  level: "beginner" | "intermediate" | "advanced"
  workoutTarget: "lose_weight" | "gain_muscle" | "be_fit" | "strength"
  practice: "all" | "indoor" | "outdoor"
  availableDays: number
  non_available_equipments?: {
    id?: number
    documentId?: string
    name?: string
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
      firstname?: string
      lastname?: string
      username?: string
      email?: string
      resetPasswordToken?: string
      registrationToken?: string
      isActive?: boolean
      roles?: {
        id?: number
        documentId?: string
        name?: string
        code?: string
        description?: string
        users?: {
          id?: number
          documentId?: string
        }[]
        permissions?: {
          id?: number
          documentId?: string
          action?: string
          actionParameters?: any
          subject?: string
          properties?: any
          conditions?: any
          role?: {
            id?: number
            documentId?: string
          }
          createdAt?: string
          updatedAt?: string
          publishedAt?: string
          createdBy?: {
            id?: number
            documentId?: string
          }
          updatedBy?: {
            id?: number
            documentId?: string
          }
          locale?: string
          localizations?: {
            id?: number
            documentId?: string
          }[]
        }[]
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      blocked?: boolean
      preferedLanguage?: string
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
  activityLevel:
    | "sedentary"
    | "slightly_active"
    | "active"
    | "very_active"
    | "extremly_active"
  country: string
  acceptTerms?: boolean
  user?: {
    id?: number
    documentId?: string
    username?: string
    email?: string
    provider?: string
    resetPasswordToken?: string
    confirmationToken?: string
    confirmed?: boolean
    blocked?: boolean
    role?: {
      id?: number
      documentId?: string
      name?: string
      description?: string
      type?: string
      permissions?: {
        id?: number
        documentId?: string
        action?: string
        role?: {
          id?: number
          documentId?: string
        }
        createdAt?: string
        updatedAt?: string
        publishedAt?: string
        createdBy?: {
          id?: number
          documentId?: string
        }
        updatedBy?: {
          id?: number
          documentId?: string
        }
        locale?: string
        localizations?: {
          id?: number
          documentId?: string
        }[]
      }[]
      users?: {
        id?: number
        documentId?: string
      }[]
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      createdBy?: {
        id?: number
        documentId?: string
      }
      updatedBy?: {
        id?: number
        documentId?: string
      }
      locale?: string
      localizations?: {
        id?: number
        documentId?: string
      }[]
    }
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }
  createdAt?: string
  updatedAt?: string
  publishedAt?: string
  createdBy?: {
    id?: number
    documentId?: string
  }
  updatedBy?: {
    id?: number
    documentId?: string
  }
  locale?: string
  localizations?: {
    id?: number
    documentId?: string
    size?: number
    weight?: number
    age?: number
    gender?: "all" | "men" | "women"
    morphotype?: "ectomorph" | "mesomorph" | "endomorph"
    level?: "beginner" | "intermediate" | "advanced"
    workoutTarget?: "lose_weight" | "gain_muscle" | "be_fit" | "strength"
    practice?: "all" | "indoor" | "outdoor"
    availableDays?: number
    non_available_equipments?: {
      id?: number
      documentId?: string
    }[]
    activityLevel?:
      | "sedentary"
      | "slightly_active"
      | "active"
      | "very_active"
      | "extremly_active"
    country?: string
    acceptTerms?: boolean
    user?: {
      id?: number
      documentId?: string
    }
    createdAt?: string
    updatedAt?: string
    publishedAt?: string
    createdBy?: {
      id?: number
      documentId?: string
    }
    updatedBy?: {
      id?: number
      documentId?: string
    }
    locale?: string
    localizations?: {
      id?: number
      documentId?: string
    }[]
  }[]
}
export type ProfileListResponse = {
  data?: Profile[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type ProfileResponse = {
  data?: Profile
  meta?: object
}
export type ProfileRequest = {
  data: {
    size: number
    weight: number
    age: number
    gender: "all" | "men" | "women"
    morphotype: "ectomorph" | "mesomorph" | "endomorph"
    level: "beginner" | "intermediate" | "advanced"
    workoutTarget: "lose_weight" | "gain_muscle" | "be_fit" | "strength"
    practice: "all" | "indoor" | "outdoor"
    availableDays: number
    non_available_equipments?: (number | string)[]
    activityLevel:
      | "sedentary"
      | "slightly_active"
      | "active"
      | "very_active"
      | "extremly_active"
    country: string
    acceptTerms?: boolean
    user?: number | string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type TrainingType =
  | "alternate_circuit_training"
  | "grouped_circuit_training"
export type TrainingTypeListResponse = {
  data?: TrainingType[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type TrainingTypeResponse = {
  data?: TrainingType
  meta?: object
}
export type TrainingTypeRequest = {
  data: {
    name: string
    locale?: string
    localizations?: (number | string)[]
  }
}
export type WorkoutTrainingFollowupComponent = object
export type UserTracking = {
  wkg_points?: number
  week_index?: number
  cycle_index?: number
  session_index?: number
  user?: number | string
  training_followup?: WorkoutTrainingFollowupComponent
  achievement_rate?: number
  level?: number
  countdown?: string
  locale?: string
  localizations?: (number | string)[]
}
export type UserTrackingListResponse = {
  data?: UserTracking[]
  meta?: {
    pagination?: {
      page?: number
      pageSize?: number
      pageCount?: number
      total?: number
    }
  }
}
export type UserTrackingResponse = {
  data?: UserTracking
  meta?: object
}
export type UserTrackingRequest = {
  data: {
    wkg_points: number
    week_index: number
    cycle_index: number
    session_index: number
    user?: number | string
    training_followup: WorkoutTrainingFollowupComponent
    achievement_rate: number
    level: number
    countdown: string
    session_type: "rest" | "session"
    locale?: string
    localizations?: (number | string)[]
  }
}
export type UsersPermissionsUser = {
  id?: number
  username?: string
  email?: string
  provider?: string
  confirmed?: boolean
  blocked?: boolean
  createdAt?: string
  updatedAt?: string
}
export type UsersPermissionsUserRegistration = {
  jwt?: string
  user?: UsersPermissionsUser
}
export type UsersPermissionsPermissionsTree = {
  [key: string]: {
    /** every controller of the api */
    controllers?: {
      [key: string]: {
        [key: string]: {
          enabled?: boolean
          policy?: string
        }
      }
    }
  }
}
export type UsersPermissionsRole = {
  id?: number
  name?: string
  description?: string
  type?: string
  createdAt?: string
  updatedAt?: string
}
export type WorkoutTarget =
  | "lose_weight"
  | "gain_muscle"
  | "be_fit"
  | "strength"
export type RealTargetName =
  | "toning"
  | "hypertrophy"
  | "mass_gain"
  | "reinforcement"
  | "cardio1"
  | "cardio2"
  | "cardio3"
export type CycleSummary = {
  cycleIndex?: number
  cycleName?: RealTargetName
  weekCount?: number
  sessionCount?: number
}
export type WkgWorkoutMeta = {
  cyclesCount?: number
  weeksCount?: number
  sessionsCount?: number
  workoutTarget?: WorkoutTarget
  cyclesSummary?: CycleSummary[]
}
export type UserLevel = "beginner" | "intermediate" | "advanced"
export type TrainingFormat = "full_body" | "half_body" | "split_body"
export type SetValuesUnits = "seconds" | "reps"
export type SetValues = {
  reps?: number
  setsRecups?: number
  unit?: SetValuesUnits
}
export type MoveMediaSimple = {
  url?: string
}
export type FinalSessionExerciseParamsWithExercise = {
  bodypart?: string
  exerciseRecup?: number
  rm?: number
  sets?: SetValues[]
  id?: number
  documentId?: string
  name?: string
  is_main?: boolean
  is_poly_articular?: boolean
  execution?: string
  move_start?: MoveMediaSimple
  move_end?: MoveMediaSimple
  move_video?: MoveMediaSimple
  equipments?: string[]
  bodyparts?: string[]
  categories?: string[]
  execution_instructions?: string[]
}
export type FinalSessionLapWithExercise = {
  lapCount?: number
  lapRecup?: number
  exercises?: FinalSessionExerciseParamsWithExercise[]
  stretching?: FinalSessionExerciseParamsWithExercise[]
  join_unlocking?: FinalSessionExerciseParamsWithExercise[]
  warmup?: FinalSessionExerciseParamsWithExercise[]
  abds?: FinalSessionExerciseParamsWithExercise[]
}
export type SessionType = "rest" | "session"
export type FinalWorkoutSession = {
  sessionIndex?: number
  bodyparts?: string[]
  realTargetName?: RealTargetName
  trainingLevel?: UserLevel
  trainingFormat?: TrainingFormat
  trainingType?: TrainingType
  training?: FinalSessionLapWithExercise
  sessionType?: SessionType
}
export type FinalWorkoutTraining = {
  weekIndex?: number
  schedule?: boolean[]
  sessions?: FinalWorkoutSession[]
}
export type ApiWkgWorkout = {
  metadata?: WkgWorkoutMeta
  workout?: FinalWorkoutTraining
}
export type ErrorResponse = {
  message?: string
  code?: string
}
export type Genders = "men" | "women" | "all"
export type Morphotypes = "ectomorph" | "mesomorph" | "endomorph"
export type AvailableDays = 1 | 2 | 3 | 4 | 5 | 6
export type ActivityLevel =
  | "sedentary"
  | "slightly_active"
  | "active"
  | "very_active"
  | "extremly_active"
export type Locales = "fr" | "en" | "es"
export type WkgArgs = {
  size: number
  weight: number
  age: number
  gender: Genders
  morphotype: Morphotypes
  level: UserLevel
  workoutTarget: WorkoutTarget
  availableDays: AvailableDays
  practice: Practice
  non_available_equipments: number[]
  activityLevel: ActivityLevel
  country: Locales
  acceptTerms: boolean
}
export type CreateTestWkgWorkoutRequestBody = {
  wkgArgs: WkgArgs
  turnstileToken: string
}
export type CreateWkgWorkoutRequestBody = {
  wkgArgs: WkgArgs
  lastUserTracking: UserTracking
}
export type DeleteWkgWorkoutResponse = {
  message?: string
}
export const {
  useGetBodypartsQuery,
  usePostBodypartsMutation,
  useGetBodypartsByIdQuery,
  usePutBodypartsByIdMutation,
  useDeleteBodypartsByIdMutation,
  useGetCategoriesQuery,
  usePostCategoriesMutation,
  useGetCategoriesByIdQuery,
  usePutCategoriesByIdMutation,
  useDeleteCategoriesByIdMutation,
  useGetEquipmentsQuery,
  usePostEquipmentsMutation,
  useGetEquipmentsByIdQuery,
  usePutEquipmentsByIdMutation,
  useDeleteEquipmentsByIdMutation,
  useGetExecutionInstructionsQuery,
  usePostExecutionInstructionsMutation,
  useGetExecutionInstructionsByIdQuery,
  usePutExecutionInstructionsByIdMutation,
  useDeleteExecutionInstructionsByIdMutation,
  useGetExercisesQuery,
  usePostExercisesMutation,
  useGetExercisesByIdQuery,
  usePutExercisesByIdMutation,
  useDeleteExercisesByIdMutation,
  useGetGendersQuery,
  usePostGendersMutation,
  useGetGendersByIdQuery,
  usePutGendersByIdMutation,
  useDeleteGendersByIdMutation,
  useGetLevelsQuery,
  usePostLevelsMutation,
  useGetLevelsByIdQuery,
  usePutLevelsByIdMutation,
  useDeleteLevelsByIdMutation,
  useGetPracticesQuery,
  usePostPracticesMutation,
  useGetPracticesByIdQuery,
  usePutPracticesByIdMutation,
  useDeletePracticesByIdMutation,
  useGetProfilesQuery,
  usePostProfilesMutation,
  useGetProfilesByIdQuery,
  usePutProfilesByIdMutation,
  useDeleteProfilesByIdMutation,
  useGetTrainingTypesQuery,
  usePostTrainingTypesMutation,
  useGetTrainingTypesByIdQuery,
  usePutTrainingTypesByIdMutation,
  useDeleteTrainingTypesByIdMutation,
  useGetUserTrackingsQuery,
  usePostUserTrackingsMutation,
  useGetUserTrackingsByIdQuery,
  usePutUserTrackingsByIdMutation,
  useDeleteUserTrackingsByIdMutation,
  useGetConnectByProviderQuery,
  usePostAuthLocalMutation,
  usePostAuthLocalRegisterMutation,
  useGetAuthByProviderCallbackQuery,
  usePostAuthForgotPasswordMutation,
  usePostAuthResetPasswordMutation,
  usePostAuthChangePasswordMutation,
  useGetAuthEmailConfirmationQuery,
  usePostAuthSendEmailConfirmationMutation,
  useGetUsersPermissionsPermissionsQuery,
  useGetUsersPermissionsRolesQuery,
  usePostUsersPermissionsRolesMutation,
  useGetUsersPermissionsRolesByIdQuery,
  usePutUsersPermissionsRolesByRoleMutation,
  useDeleteUsersPermissionsRolesByRoleMutation,
  useGetUsersQuery,
  usePostUsersMutation,
  useGetUsersByIdQuery,
  usePutUsersByIdMutation,
  useDeleteUsersByIdMutation,
  useGetUsersMeQuery,
  useGetUsersCountQuery,
  usePostGenerateTestWkgWorkoutMutation,
  usePostGenerateWkgWorkoutMutation,
  useDeleteDeleteWkgWorkoutByUserIdMutation
} = injectedRtkApi
