import { useAsyncEffect } from "ahooks"
import { useEffect, useRef } from "react"
import { toast } from "react-toastify"
import { setAppLoading, setDevMode } from "../redux/globalSlice"
import { useAppDispatch } from "./useAppDispatch"
import { useWKGData } from "./useWKGData"

export const useLoadApp = () => {
  const dispatch = useAppDispatch()

  const {
    getUserTracking,
    getUserWorkout,
    getProfile,
    getUser,
    getUserSettings
  } = useWKGData()

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const loadApp = async () => {
    try {
      await Promise.all([
        getUserTracking(),
        getUserWorkout(),
        getProfile(),
        getUserSettings()
      ])

      timeoutRef.current = setTimeout(() => {
        dispatch(setAppLoading(false))
      }, 1500)
    } catch (error) {
      const e = error as Error

      if (e.message === "File does not exist.") return

      console.error(e.message)

      toast.error(
        "Une erreur est survenue lors du chargement de l'utilisateur actuel."
      )

      timeoutRef.current = setTimeout(() => {
        dispatch(setAppLoading(false))
      }, 1500)
    }
  }

  useAsyncEffect(async () => {
    try {
      await getUser()

      await loadApp()
    } catch (error) {
      const e = error as Error

      if (e.message === "File does not exist.") {
        timeoutRef.current = setTimeout(() => {
          dispatch(setAppLoading(false))
        }, 1500)
      } else {
        console.error(e.message)

        toast.error(
          "Une erreur est survenue lors du chargement de l'utilisateur actuel."
        )

        timeoutRef.current = setTimeout(() => {
          dispatch(setAppLoading(false))
        }, 1500)
      }
    } finally {
      const isDevelopment =
        window.location.href.includes("localhost") ||
        window.location.href.includes("develop.workoutgen.pages")

      dispatch(setDevMode(isDevelopment))
    }
  }, [])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])
}
