import clsx from "clsx";
import { memo } from "react";
import { TROPHY_INTENSITY_DICT } from "../../../constants";
import {
  FaProgressState,
  FaTrainingProgressProps,
} from "./FaTrainingProgress.types";

const FaTrainingProgress = ({
  progress,
  intensity = 100,
  sessionIndex,
}: FaTrainingProgressProps) => {
  const id = `training-progress-${sessionIndex}`;

  const currentBackground =
    progress === "NOT_STARTED"
      ? "bg-black"
      : progress === FaProgressState.IN_PROGRESS
        ? "animate-blue-purple-pink-cycle-animation-fast"
        : TROPHY_INTENSITY_DICT[
            intensity as keyof typeof TROPHY_INTENSITY_DICT
          ];

  return (
    <div
      id={id}
      className={clsx(
        "aspect-square w-full rounded-lg overflow-hidden",
        "centered-flex-col my-0.5",
        currentBackground,
      )}
    />
  );
};

export default memo(FaTrainingProgress);
