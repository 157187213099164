import { IonBackButton, IonButtons } from "@ionic/react"
import { Genders } from "@workoutgen/global-typings/engine-types"
import { useState } from "react"
import { APP_ROUTES } from "../../../commons/constants"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import { AccountInfosStep } from "../partials/AccountInfosStep/AccountInfosStep"
import { ActivityLevelStep } from "../partials/ActivityLevelStep/ActivityLevelStep"
import { AgeStep } from "../partials/AgeStep/AgeStep"
import { AvailabilitysStep } from "../partials/AvailabilitysStep/AvailabilitysStep"
import { FinalStep } from "../partials/FinalStep/FinalStep"
import { GenderStep } from "../partials/GendersStep/GenderSteps"
import { LevelStep } from "../partials/LevelStep/LevelStep"
import { MorphoStep } from "../partials/MorphoStep/MorphoStep"
import { RegisterContainer } from "../partials/RegisterContainer/RegisterContainer"
import { RegisterErrors } from "../partials/RegisterErrors/RegisterErrors"
import { RegisterStep } from "../partials/RegisterStep/RegisterStep"
import { SizeStep } from "../partials/SizeStep/SizeStep"
import { TargetStep } from "../partials/TargetStep/TargetStep"
import { WeightStep } from "../partials/WeightStep/WeightStep"
import {
  ACTIVITY_LEVELS,
  AVAILABILITYS,
  GENDERS,
  LEVELS,
  MORPHOTYPES,
  TARGETS
} from "../partials/register-constants"

const RegisterPage = () => {
  const [generating, setGenerating] = useState(false)

  return (
    <FaPageLayout
      header={
        <IonButtons slot="start">
          <IonBackButton defaultHref={APP_ROUTES.TIMELINE} text="" />
        </IonButtons>
      }
      content={
        <>
          <RegisterContainer>
            <RegisterStep
              title="Objectif"
              subtitle="Votre objectif en musculation?"
              stepNumber={0}
            >
              <TargetStep texts={TARGETS} />
            </RegisterStep>

            <RegisterStep
              title="Genre"
              subtitle="Sélectionnez votre genre"
              additionalInfos="Choisissez 'homme' si vous êtes un homme, 'femme' si vous êtes une femme, ou 'autre' pour tout autre genre."
              stepNumber={1}
            >
              <GenderStep texts={GENDERS as unknown as Genders} />
            </RegisterStep>

            <RegisterStep
              title="Type de morphologie"
              subtitle="À quelle silhouette vous Identifiez-vous?"
              stepNumber={2}
            >
              <MorphoStep images={MORPHOTYPES} />
            </RegisterStep>

            <RegisterStep
              title="Niveau d'activité physique"
              subtitle="Indiquez votre niveau d'activité quotidienne"
              stepNumber={3}
            >
              <ActivityLevelStep texts={ACTIVITY_LEVELS} />
            </RegisterStep>

            <RegisterStep
              title="Votre âge"
              subtitle="Indiquez votre âge"
              stepNumber={4}
              className="mt-32"
            >
              <AgeStep />
            </RegisterStep>

            <RegisterStep
              title="Poids"
              subtitle="Combien pesez-vous ?"
              stepNumber={5}
              className="mt-32"
            >
              <WeightStep />
            </RegisterStep>

            <RegisterStep
              title="Taille"
              subtitle="Combien mesurez-vous ?"
              stepNumber={6}
              className="mt-32"
            >
              <SizeStep />
            </RegisterStep>

            <RegisterStep
              title="Niveau"
              subtitle="Quel est votre niveau en musculation ?"
              stepNumber={7}
            >
              <LevelStep texts={LEVELS} />
            </RegisterStep>

            <RegisterStep
              title="Disponibilité pour l'entraînement"
              subtitle="Combien de jours par semaine êtes-vous disponible pour vous entraîner ?"
              stepNumber={8}
            >
              <AvailabilitysStep texts={AVAILABILITYS} />
            </RegisterStep>

            <RegisterStep title="Conditions d'utilisation" stepNumber={9}>
              <AccountInfosStep />
            </RegisterStep>

            <RegisterStep
              title="Lancement du programme"
              subtitle="Cliquez ci-dessous pour obtenir votre programme"
              stepNumber={9}
              centerTitle
              className="min-h-dvh flex flex-col text-center justify-center"
            >
              <FinalStep
                generating={generating}
                setGenerating={setGenerating}
              />
            </RegisterStep>
          </RegisterContainer>

          {!generating && <RegisterErrors />}
        </>
      }
    />
  )
}

export default RegisterPage
