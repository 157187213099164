import { IonProgressBar } from "@ionic/react"
import FaAnimatedNumber from "@workoutgen/design-system/FaAnimatedNumber"
import clsx from "clsx"

type JourneyProgressType = "level" | "progress" | "points"

interface JourneyProgressProps {
  type: JourneyProgressType
  partialValue: number
  totalValue: number
}

export const JourneyProgress = ({
  type,
  partialValue,
  totalValue
}: JourneyProgressProps) => {
  const colorDict = {
    level: "text-[#fd4adf]",
    points: "text-[#496afc]",
    progress: "text-[#985EFF]"
  }

  const label = {
    level: "Niveau",
    progress: "Progression",
    points: "WKG Points"
  }

  const customClass = {
    level: "ion-progressbar-level",
    points: "ion-progressbar-points",
    progress: "ion-progressbar-progress"
  }

  const percentage =
    parseFloat(((partialValue / totalValue) * 100).toFixed(2)) / 100

  return (
    <div>
      <div className="flex items-center justify-between">
        <h6 className="text-center flex items-center">
          <span className="text-4xl">
            <FaAnimatedNumber value={partialValue} />
          </span>
          <span className="text-xs">/</span>
          <span className="text-xs">
            <FaAnimatedNumber value={totalValue} />
          </span>
        </h6>

        <p className={clsx("text-xs", colorDict[type])}>{label[type]}</p>
      </div>

      <IonProgressBar className={customClass[type]} value={percentage} />
    </div>
  )
}
