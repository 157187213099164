import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import App from "./features/commons/App"
import { store } from "./features/commons/redux/store"

if (!import.meta.env.DEV)
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      Sentry.browserApiErrorsIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"]
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      }),
      Sentry.httpClientIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/workoutgen\.app\/api/,
      /^https:\/\/staging\.workoutgen\.app\/api/,
      /^https:\/\/production\.workoutgen\.app\/api/,
      /^https:\/\/develop\.workoutgen\.pages\.dev/
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
