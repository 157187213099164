import clsx from "clsx";
import { ElementType } from "react";

export interface FaHeaderProps {
  text?: string;
  size?: "large" | "medium" | "small" | "custom";
  customStyles?: string;
  align?: "left" | "center" | "right";
  mobileAlign?: "left" | "center" | "right";
  className?: string;
  as?: ElementType;
}

const FaTitle = (props: FaHeaderProps) => {
  const {
    text = "",
    size = "medium",
    align = "left",
    mobileAlign = "left",
    className,
    as: Tag = "p",
  } = props;

  return (
    <Tag
      className={clsx(
        "font-title",
        size === "large" && "text-6xl md:text-7xl tracking-tight",
        size === "medium" && "text-4xl tracking-tight",
        size === "small" && "text-3xl",
        align === "left" && "md:text-left",
        align === "center" && "md:text-center",
        align === "right" && "md:text-right",
        mobileAlign === "left" && "text-left",
        mobileAlign === "center" && "text-center",
        mobileAlign === "right" && "text-right",
        className,
      )}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Tag>
  );
};

export default FaTitle;
