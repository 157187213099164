import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonIcon,
  useIonRouter
} from "@ionic/react"
import { calcTotalDuration } from "@workoutgen/design-system/calculateTrainingDuration"
import {
  CYCLES_DICT,
  TRAINING_FORMAT_DICT,
  TRAINING_POINT_DICT,
  TRAINING_TYPE_DICT
} from "@workoutgen/design-system/constants"
import FaAnimatedNumber from "@workoutgen/design-system/FaAnimatedNumber"
import FaButton from "@workoutgen/design-system/FaButton"
import FaChip from "@workoutgen/design-system/FaChip"
import FaSessionPad from "@workoutgen/design-system/FaSessionPad"
import FaSurface from "@workoutgen/design-system/FaSurface"
import {
  CurrentTrainingState,
  UserTracking,
  WorkoutPart
} from "@workoutgen/global-typings/engine-types"
import { TrainingFollowup } from "@workoutgen/global-typings/kiosk-types"
import clsx from "clsx"
import { motion } from "framer-motion"
import { play, playSkipForward } from "ionicons/icons"
import JSConfetti from "js-confetti"
import { APP_ROUTES, WKG_POINTS_DICT } from "../../../commons/constants"
import { useAppDispatch } from "../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../commons/hooks/useAppSelector"
import { useWKGData } from "../../../commons/hooks/useWKGData"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import { selectGlobal } from "../../../commons/redux/globalSlice"
import { calcCompletedBlocks } from "../../../commons/utils/calcCompletedBlocks"
import { getCurrentStringDate } from "../../../commons/utils/getCurrentStringDate"
import { setCurrentSlide } from "../../../player/redux/playerSlice"
import { useTimelineNavigation } from "../../../timeline/hooks/useTimelineNavigation"

const SessionPreviewPage = () => {
  const router = useIonRouter()

  const dispatch = useAppDispatch()

  const { handleSetNextSession } = useTimelineNavigation()

  const { saveUserTracking, saveUserSettings } = useWKGData()

  const { lastUserTracking, userTracking, userWorkout, devMode, userSettings } =
    useAppSelector(selectGlobal)

  if (!userWorkout || !lastUserTracking || !userTracking || !userSettings) {
    console.error("No user tracking or user workout")

    return null
  }

  const { session_index, training_followup } =
    lastUserTracking as UserTracking & {
      training_followup: Record<WorkoutPart, string>
    }

  const currentSession = userWorkout.workout.sessions[(session_index - 1) % 7]

  if (!currentSession || currentSession.sessionType !== "session") return null

  const { warmup, join_unlocking, exercises, abds, stretching } =
    training_followup as TrainingFollowup

  const sessionElements = [
    {
      title: "Mobilité",
      description:
        "Prépare les articulations pour une meilleure amplitude de mouvement",
      content: currentSession.training.join_unlocking,
      workoutGenPoints: 15,
      workoutPart: "join_unlocking",
      state: join_unlocking,
      passTitle: "Mobilité passée",
      successMessage: "Mobilité réussie",
      exercises: currentSession.training.join_unlocking,
      nextWorkoutPart: "warmup"
    },
    {
      title: "Échauffement",
      description:
        "Augmente la température corporelle et réduit le risque de blessure",
      content: currentSession.training.warmup,
      workoutGenPoints: 15,
      workoutPart: "warmup",
      state: warmup,
      passTitle: "Échauffement passé",
      successMessage: "Échauffement réussi",
      exercises: currentSession.training.warmup,
      nextWorkoutPart: "exercises"
    },
    {
      title: "Musculation",
      description: "Séance d'entraînement principale",
      content: currentSession.training.exercises,
      workoutGenPoints: 40,
      workoutPart: "exercises",
      state: exercises,
      passTitle: "Musculation passée",
      successMessage: "Musculation réussie",
      exercises: currentSession.training.exercises,
      nextWorkoutPart: "abds"
    },
    {
      title: "Abdominaux",
      description: "Renforce le tronc pour une meilleure stabilité et posture",
      content: currentSession.training.abds,
      workoutGenPoints: 15,
      workoutPart: "abds",
      state: abds,
      passTitle: "Abdos passés",
      successMessage: "Abdos réussis",
      exercises: currentSession.training.abds,
      nextWorkoutPart: "stretching"
    },
    {
      title: "Étirements",
      description:
        "Améliore la flexibilité et favorise la récupération musculaire",
      content: currentSession.training.stretching,
      workoutGenPoints: 15,
      workoutPart: "stretching",
      state: stretching,
      passTitle: "Étirements passés",
      successMessage: "Étirements réussis",
      exercises: currentSession.training.stretching,
      nextWorkoutPart: "finish"
    },
    {
      title: "Valider mon entraînement",
      description:
        "C'est le moment de valider votre entraînement et de voir votre progression",
      content: [],
      workoutGenPoints: 0,
      workoutPart: "finish",
      state: "todo",
      passTitle: "Entraînement terminé",
      successMessage: "Entraînement terminé",
      exercises: [],
      nextWorkoutPart: "finish"
    }
  ]

  const endedBlocks = Object.values(training_followup).filter(
    (session) => session === "done" || session === "cancelled"
  ).length

  const currentSessionPoint = Object.entries(training_followup).reduce(
    (acc, [key, value]) => {
      if (value === "done") {
        return (
          acc + TRAINING_POINT_DICT[key as keyof typeof TRAINING_POINT_DICT]
        )
      }

      return acc
    },
    0
  )

  const isPwa = window.matchMedia("(display-mode: standalone)").matches

  const followup = {
    join_unlocking: training_followup.join_unlocking,
    warmup: training_followup.warmup,
    exercises: training_followup.exercises,
    abds: training_followup.abds,
    stretching: training_followup.stretching
  }

  const trainingFollowupValues = [...Object.values(followup), "todo"]

  const firstTodoIndex = trainingFollowupValues.indexOf("todo")

  const currentWorkoutPart =
    sessionElements[firstTodoIndex === -1 ? 0 : firstTodoIndex]

  const sessionEnd = endedBlocks === 5

  const checkNonNegativePoints = (points: number) => {
    if (points < 0) {
      return 0
    }

    return points
  }

  const handleReactivateBloc = (workoutPart: WorkoutPart) => {
    const point = WKG_POINTS_DICT[workoutPart]

    const wkgPoint = lastUserTracking.wkg_points - point

    const updatedTracking = {
      ...lastUserTracking,
      wkg_points:
        (lastUserTracking.training_followup as Record<WorkoutPart, string>)[
          workoutPart
        ] === "cancelled"
          ? lastUserTracking.wkg_points
          : checkNonNegativePoints(wkgPoint),
      training_followup: {
        ...lastUserTracking.training_followup,
        [workoutPart]: "todo",
        [`current_${workoutPart}_index`]: 0
      }
    }

    const updatedAchievementRate = calcCompletedBlocks(updatedTracking)

    const restUserTracking = userTracking.slice(0, userTracking.length - 1)

    saveUserTracking([
      ...restUserTracking,
      {
        ...updatedTracking,
        achievement_rate: updatedAchievementRate,
        training_followup: {
          ...updatedTracking.training_followup,
          [`current_${workoutPart}_index`]: 0
        }
      }
    ])

    saveUserSettings({
      ...userSettings,
      playerTracking: {
        ...userSettings.playerTracking,
        [workoutPart]: 0
      }
    })
  }

  const handleOpenPlayer = (workoutPart: WorkoutPart) => {
    router.push(`${APP_ROUTES.PLAYER}/${workoutPart}`, "forward")

    dispatch(setCurrentSlide(0))
  }

  const handlePassBloc = (workoutPart: WorkoutPart) => {
    const updatedTracking = {
      ...lastUserTracking,
      training_followup: {
        ...lastUserTracking.training_followup,
        [workoutPart]: "cancelled"
      }
    }

    const updatedAchievementRate = calcCompletedBlocks(updatedTracking)

    const restUserTracking = userTracking.slice(0, userTracking.length - 1)

    const newFollowup = [
      ...restUserTracking,
      {
        ...updatedTracking,
        achievement_rate: updatedAchievementRate
      }
    ]

    saveUserTracking(newFollowup)
  }

  const handleValidateBloc = (workoutPart: WorkoutPart) => {
    const wkgPoint = lastUserTracking.wkg_points + WKG_POINTS_DICT[workoutPart]

    const updatedTracking = {
      ...lastUserTracking,
      wkg_points: checkNonNegativePoints(wkgPoint),
      training_followup: {
        ...lastUserTracking.training_followup,
        [workoutPart]: "done",
        [`current_${workoutPart}_index`]: 999
      }
    }

    const updatedAchievementRate = calcCompletedBlocks(updatedTracking)

    const restUserTracking = userTracking.slice(0, userTracking.length - 1)

    const newFollowup = [
      ...restUserTracking,
      {
        ...updatedTracking,
        achievement_rate: updatedAchievementRate
      }
    ]

    saveUserTracking(newFollowup)

    saveUserSettings({
      ...userSettings,
      playerTracking: {
        ...userSettings.playerTracking,
        [workoutPart]: 999
      }
    })
  }

  const handleQuit = () => {
    router.push(APP_ROUTES.TIMELINE, "back")
  }

  const handleFinishSession = () => {
    handleSetNextSession()

    dispatch(setCurrentSlide(0))

    const jsConfetti = new JSConfetti()

    jsConfetti.addConfetti()

    handleQuit()
  }

  const { trainingFormat, trainingType, realTargetName } = currentSession

  const metaData = [
    TRAINING_FORMAT_DICT[trainingFormat],
    CYCLES_DICT[realTargetName],
    TRAINING_TYPE_DICT[trainingType],
    calcTotalDuration([
      currentSession.training.join_unlocking,
      currentSession.training.warmup,
      currentSession.training.exercises,
      currentSession.training.abds,
      currentSession.training.stretching
    ])
  ]

  const position =
    userSettings.playerTracking[
      currentWorkoutPart.workoutPart as keyof typeof userSettings.playerTracking
    ] ?? 0
  const currentTrainingCountExercices = currentWorkoutPart.exercises.length

  return (
    <FaPageLayout
      header={
        <>
          <IonButtons slot="start">
            <IonBackButton defaultHref={APP_ROUTES.TIMELINE} text="" />
          </IonButtons>

          <div className="absolute inset-0 w-full flex justify-center gap-4">
            <div className="centered-flex-col">
              <h3 className="leading-none text-2xl flex items-center">
                <FaAnimatedNumber value={currentSessionPoint} />{" "}
                <span className="text-xs">/ 100 WKG</span>
              </h3>
            </div>

            <div className="centered-flex-col">
              <h3 className="leading-none text-2xl flex items-center">
                <FaAnimatedNumber value={endedBlocks} />{" "}
                <span className="text-xs">/ 5 Blocs</span>
              </h3>
            </div>
          </div>
        </>
      }
      content={
        <>
          <section className={clsx("min-h-[calc(100dvh-50px)]", "mb-[92px]")}>
            <div className="relative flex flex-col gap-4">
              <FaSurface className="centered-flex-col">
                <h4 className="text-xl text-center mb-2">
                  Entraînement <span className="italic">{session_index}</span>
                </h4>

                <span className="text-center mb-2">
                  {getCurrentStringDate(0)}
                </span>

                <div className="centered-flex-row flex-wrap gap-2">
                  {metaData.map((item) => (
                    <FaChip item={item} key={item} />
                  ))}
                </div>
              </FaSurface>

              {sessionElements
                .slice(0, -1)
                .map(
                  (
                    {
                      title,
                      content,
                      workoutPart,
                      state,
                      workoutGenPoints,
                      passTitle,
                      exercises,
                      description
                    },
                    index
                  ) => (
                    <div key={title}>
                      <FaSessionPad
                        devMode={devMode}
                        state={state as CurrentTrainingState}
                        title={title}
                        userSettings={userSettings}
                        nbExercises={exercises.length}
                        userTracking={lastUserTracking}
                        workoutGenPoints={workoutGenPoints}
                        passTitle={passTitle}
                        content={content}
                        endedBlocks={endedBlocks}
                        index={index}
                        description={description}
                        workoutPart={workoutPart as WorkoutPart}
                        onReactivateBloc={handleReactivateBloc}
                        onValidateBloc={handleValidateBloc}
                      />
                    </div>
                  )
                )}
            </div>
          </section>

          <IonAlert
            trigger="cancel-bloc"
            message={`Êtes-vous sûr de vouloir passer le bloc ${currentWorkoutPart.title.toLowerCase()} ?`}
            buttons={[
              {
                text: "Oui",
                handler: () =>
                  handlePassBloc(currentWorkoutPart.workoutPart as WorkoutPart)
              },
              {
                text: "Non"
              }
            ]}
          />

          <nav
            className={clsx(
              "mt-4 fixed inset-x-3 z-50",
              "flex gap-3",
              isPwa ? "training-button-mobile-native" : "training-button"
            )}
          >
            <div className="flex-1">
              <FaButton
                onClick={() =>
                  sessionEnd
                    ? handleFinishSession()
                    : handleOpenPlayer(
                        currentWorkoutPart.workoutPart as WorkoutPart
                      )
                }
                animated={sessionEnd}
                className={clsx("w-full flex-1 shadow-2xl")}
              >
                <motion.div
                  key={position}
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  className="centered-flex-row gap-3 items-between w-full"
                >
                  {!sessionEnd && (
                    <div className="centered-flex-row gap-6 items-center">
                      <IonIcon className="text-xl" icon={play} />
                    </div>
                  )}

                  <div
                    className={clsx(
                      "flex flex-1 items-center gap-2 w-full",
                      sessionEnd && "justify-center"
                    )}
                  >
                    <div>{currentWorkoutPart.title}</div>
                  </div>

                  {!sessionEnd && (
                    <div className="centered-flex-col w-[40px]">
                      <span className="leading-none text-xl flex items-center">
                        <FaAnimatedNumber value={position} />

                        <span className="text-xs">
                          / {currentTrainingCountExercices}
                        </span>
                      </span>
                    </div>
                  )}
                </motion.div>
              </FaButton>
            </div>

            <div id="cancel-bloc">
              <FaButton
                className={clsx(
                  "shadow-2xl centered-flex-col h-[67px]",
                  sessionEnd && "opacity-0 pointer-events-none absolute h-0 w-0"
                )}
              >
                <div className="centered-flex-row gap-6 items-center">
                  <IonIcon className="text-xl" icon={playSkipForward} />
                </div>
              </FaButton>
            </div>
          </nav>

          <div id="bloc-final" />
        </>
      }
    />
  )
}

export default SessionPreviewPage
