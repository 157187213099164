import { yupResolver } from "@hookform/resolvers/yup"
import { IonIcon, IonInput, IonItem, IonRouterLink } from "@ionic/react"
import FaButton from "@workoutgen/design-system/FaButton"
import FaGlitchTitle from "@workoutgen/design-system/FaGlitchTitle"
import FaVerticalTitle from "@workoutgen/design-system/FaVerticalTitle"
import { ApiResponse } from "@workoutgen/global-typings/kiosk-types"
import clsx from "clsx"
import { eye, eyeOff } from "ionicons/icons"
import { SyntheticEvent, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import * as yup from "yup"
import { usePostAuthResetPasswordMutation } from "../../../api/WorkoutGenApi"
import { APP_ROUTES } from "../../../commons/constants"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import useAuth from "../../hooks/useAuth"

type UpdatePasswordForm = {
  password: string
  passwordConfirmation: string
}

const ResetPasswordPage = () => {
  const [resetPassword, { isLoading: resetPasswordLoading }] =
    usePostAuthResetPasswordMutation()

  const { login } = useAuth()

  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Le mot de passe est requis")
      .min(8, "Le mot de passe doit contenir au moins 8 caractères")
      .matches(
        /[A-Z]/,
        "Le mot de passe doit contenir au moins une lettre majuscule"
      )
      .matches(
        /[a-z]/,
        "Le mot de passe doit contenir au moins une lettre minuscule"
      )
      .matches(/[0-9]/, "Le mot de passe doit contenir au moins un chiffre")
      .matches(
        /[@$!%*?&#]/,
        "Le mot de passe doit contenir au moins un caractère spécial (@$!%*?&#)"
      ),
    passwordConfirmation: yup
      .string()
      .required("La confirmation du mot de passe est requise")
      .oneOf([yup.ref("password")], "Les mots de passe doivent correspondre")
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm<UpdatePasswordForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: ""
    }
  })

  const onSubmit = async ({
    passwordConfirmation,
    password
  }: UpdatePasswordForm): Promise<void> => {
    try {
      const params = new URLSearchParams(location.search)

      const code = params.get("code")

      if (!code) {
        throw new Error("Une erreur est survenue")
      }

      const payload = {
        body: { password, passwordConfirmation, code }
      }

      const response = await resetPassword(payload)

      if ("error" in response) {
        const apiError = response as ApiResponse

        console.log(apiError.error.data.error)

        throw new Error(apiError.error.data.error.message)
      }

      await login(response.data)
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    }
  }

  const handelTogglePassword = (e: SyntheticEvent) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  return (
    <FaPageLayout
      padding={false}
      paddingHorizontal
      content={
        <div
          className={clsx(
            "flex flex-col justify-center h-screen",
            "max-w-[400px] mx-auto"
          )}
        >
          <div>
            <div className="centered-flex-col gap-3 mb-8">
              <FaGlitchTitle text="WKG." />

              <h1 className="text-xl">Récupération de compte</h1>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <IonItem lines="full">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <IonInput
                      label="Nouveau mot de passe"
                      labelPlacement="floating"
                      type={showPassword ? "text" : "password"}
                      onIonInput={field.onChange}
                      required
                      {...field}
                    />
                  )}
                />

                <button
                  onClick={handelTogglePassword}
                  className="h-full centered-flex-col mt-[30px]"
                >
                  <IonIcon
                    slot="end"
                    className="text-black"
                    icon={showPassword ? eyeOff : eye}
                  />
                </button>
              </IonItem>

              <div className="h-[30px] flex items-center">
                {errors?.password?.message && (
                  <p className="text-danger text-xs mt-2 ml-5" slot="error">
                    {errors?.password?.message}
                  </p>
                )}
              </div>

              <IonItem lines="full">
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  render={({ field }) => (
                    <IonInput
                      label="Confirmez le mot de passe"
                      labelPlacement="floating"
                      type={showPassword ? "text" : "password"}
                      onIonInput={field.onChange}
                      required
                      {...field}
                    />
                  )}
                />

                <button
                  onClick={handelTogglePassword}
                  className="h-full centered-flex-col mt-[30px]"
                >
                  <IonIcon
                    slot="end"
                    className="text-black"
                    icon={showPassword ? eyeOff : eye}
                  />
                </button>
              </IonItem>

              <div className="h-[30px] flex items-center">
                {errors?.passwordConfirmation?.message && (
                  <p className="text-danger text-xs mt-2 ml-5" slot="error">
                    {errors?.passwordConfirmation?.message}
                  </p>
                )}
              </div>

              <div className="mt-4 relative flex flex-col items-center justify-center text-center">
                <FaButton
                  type="submit"
                  loading={resetPasswordLoading}
                  disabled={!isDirty || !isValid || resetPasswordLoading}
                  animated={isValid}
                  className={clsx(
                    "mb-10 mt-4",
                    isValid && "animate-blue-purple-pink-cycle-animation-fast"
                  )}
                >
                  Mettre à jour le mot de passe
                </FaButton>

                <IonRouterLink
                  routerLink={APP_ROUTES.LOGIN}
                  routerDirection="root"
                  className="text-subtitle text-xs underline"
                >
                  Retour à la connexion
                </IonRouterLink>
              </div>
            </form>
          </div>

          <FaVerticalTitle />
        </div>
      }
    />
  )
}

export default ResetPasswordPage
