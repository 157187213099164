import { IoLogoInstagram, IoLogoLinkedin, IoLogoTiktok } from "react-icons/io5";

export interface SocialNetworksLinks {
  instagram: string;
  tiktok: string;
  linkedin: string;
}

interface FaSocialNetworksProps {
  socialNetworksLinks: SocialNetworksLinks;
}

const FaSocialNetworks = ({ socialNetworksLinks }: FaSocialNetworksProps) => (
  <aside className="flex gap-x-8 lg:gap-x-5 text-3xl lg:text-4xl items-center">
    <a
      className="transition-all duration-200 ease-in-out hover:scale-110"
      href={socialNetworksLinks?.tiktok}
      rel="noopener noreferrer"
      target="_blank"
    >
      <IoLogoTiktok />
    </a>

    <a
      className="transition-all duration-200 ease-in-out hover:scale-110"
      href={socialNetworksLinks?.instagram}
      rel="noopener noreferrer"
      target="_blank"
      title="instagram"
    >
      <IoLogoInstagram />
    </a>

    <a
      className="transition-all duration-200 ease-in-out hover:scale-110"
      href={socialNetworksLinks?.linkedin}
      rel="noopener noreferrer"
      target="_blank"
    >
      <IoLogoLinkedin />
    </a>
  </aside>
);

export default FaSocialNetworks;
