import { IonRouterLink, IonSpinner } from "@ionic/react";
import clsx from "clsx";
import { type ReactNode } from "react";

export interface FaButtonProps {
  size?: "large" | "small" | "default" | undefined;
  onClick?: () => void;
  className?: string;
  expand?: "full" | "block" | undefined;
  to?: string;
  type?: "submit" | "reset" | "button";
  isActive?: boolean;
  children?: ReactNode;
  routerDirection?: "forward" | "back" | "root";
  disabled?: boolean;
  loading?: boolean;
  animated?: boolean;
  id?: string;
}

const FaButton = (props: FaButtonProps) => {
  const {
    type,
    onClick,
    size,
    className,
    expand,
    to,
    isActive = false,
    routerDirection,
    loading = false,
    disabled = false,
    children,
    animated,
    id,
  } = props;

  const handleClick = (): void => {
    if (onClick !== undefined) onClick();
  };

  return (
    <IonRouterLink
      routerLink={to}
      routerDirection={routerDirection}
      className={clsx("relative", disabled && "opacity-50 pointer-events-none")}
    >
      <button
        id={id}
        disabled={disabled}
        type={type}
        onClick={handleClick}
        className={clsx(
          "font-title rounded-full",
          disabled ? "bg-secondary/5" : "bg-black",
          "transition-colors ease-in-out duration-300 text-base leading-normal",
          size === "small" ? " px-4 py-3" : " px-6 py-5 text-lg",
          expand && "w-full",
          isActive && "activate-button",
          animated && "animate-blue-purple-pink-cycle-animation-fast",
          className,
        )}
      >
        {loading && (
          <IonSpinner className="absolute right-1/2 translate-x-1/2" />
        )}

        <span
          className={clsx(
            "centered-flex-col h-full w-full",
            disabled ? "text-black" : "text-white",
            loading && "opacity-0",
          )}
        >
          {children}
        </span>
      </button>
    </IonRouterLink>
  );
};

export default FaButton;
