import { motion } from "framer-motion";
import { SyntheticEvent } from "react";
import { type FaFormErrorProps } from "./FaFormError.types";

const FaFormError = (props: FaFormErrorProps) => {
  const { error } = props;

  const preventClickPropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  return error !== undefined && error !== "" ? (
    <motion.i
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="text-danger not-italic text-base animate-pulse"
      onClick={preventClickPropagation}
    >
      {error}
    </motion.i>
  ) : null;
};

export default FaFormError;
