import { APP_ROUTES } from "../../commons/constants"
import PlayerPage from "../pages/PlayerPage/PlayerPage"

const playerRoutes = [
  {
    path: `${APP_ROUTES.PLAYER}/:workoutPart`,
    component: <PlayerPage />
  }
]

export default playerRoutes
