import FaTimelineSchedule from "@workoutgen/design-system/FaTimelineSchedule"
import { useEffect, useRef, useState } from "react"
import { SwiperClass } from "swiper/react"
import { selectAuth } from "../../../authentification/redux/authentication"
import { useAppSelector } from "../../../commons/hooks/useAppSelector"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import { selectGlobal } from "../../../commons/redux/globalSlice"
import { StartButton } from "./partial/StartButton/StartButton"
import { Timeline } from "./partial/Timeline/Timeline"

const TimelinePage = () => {
  const { userWorkout, lastUserTracking, userTracking, userSettings } =
    useAppSelector(selectGlobal)

  const { user } = useAppSelector(selectAuth)

  const swiperRef = useRef<SwiperClass | null>(null)

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const handleSlideTo = (index: number) => {
    swiperRef?.current?.slideTo(index)
  }

  const handleSetSession = (index: number) => {
    setCurrentSlideIndex(index)
  }

  const handleSetSwiper = (swiper: SwiperClass) => {
    swiperRef.current = swiper
  }

  if (!userWorkout || !lastUserTracking || !userTracking || !userSettings) {
    console.error("No userWorkout or lastUserTracking or userTracking")

    return null
  }

  const { session_index } = lastUserTracking

  const isCurrentSession = currentSlideIndex === session_index - 1

  useEffect(() => {
    const slideIndex = (session_index - 1) % 7
    handleSlideTo(slideIndex)
    setCurrentSlideIndex(slideIndex)
  }, [session_index])

  useEffect(() => {
    swiperRef?.current?.updateAutoHeight()
  }, [session_index, user?.jwt, userWorkout, userTracking, userSettings])

  const shownOnboarding =
    lastUserTracking.session_index === 2 && user?.jwt === "testmode"

  return (
    <FaPageLayout
      padding={false}
      header={
        <FaTimelineSchedule
          userWorkout={userWorkout}
          userTracking={lastUserTracking}
          currentSlideIndex={currentSlideIndex}
          shownOnboarding={shownOnboarding}
          onSlideTo={handleSlideTo}
        />
      }
      content={
        <>
          <Timeline
            handleSetSession={handleSetSession}
            handleSetSwiper={handleSetSwiper}
            shownOnboarding={shownOnboarding}
            currentSlideIndex={currentSlideIndex}
            isCurrentSession={isCurrentSession}
            userWorkout={userWorkout}
            userTracking={userTracking}
            lastUserTracking={lastUserTracking}
          />

          {!shownOnboarding && (
            <StartButton
              currentSlideIndex={currentSlideIndex}
              lastUserTracking={lastUserTracking}
            />
          )}
        </>
      }
    />
  )
}

export default TimelinePage
