import { ButtonItems } from "@workoutgen/design-system/ButtonItemsTypes"
import FaSelectButtons from "@workoutgen/design-system/FaSelectButtons"
import { type AvailableDays } from "@workoutgen/global-typings/engine-types"
import { RegisterStepProps } from "@workoutgen/global-typings/kiosk-types"
import { useCallback } from "react"
import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setAvailableDays,
  setCurrentStep
} from "../../../redux/registerSlice"

export const AvailabilitysStep = (
  props: RegisterStepProps<ButtonItems<number>>
) => {
  const { texts } = props

  const dispatch = useAppDispatch()

  const { availableDays } = useAppSelector(selectRegisterForm)

  const handleAvailableDaysChange = useCallback(
    (availableDays: AvailableDays): void => {
      if (availableDays === undefined) return

      dispatch(setAvailableDays(availableDays))

      dispatch(setCurrentStep(9))
    },
    [dispatch]
  )

  return (
    <FaSelectButtons<AvailableDays>
      items={texts}
      currentValue={availableDays}
      cols={3}
      onChange={handleAvailableDaysChange}
    />
  )
}
