import clsx from "clsx";

interface FaSubtitleProps {
  text: string;
  size?: "large" | "medium" | "small";
  align?: "left" | "center" | "right";
  mobileAlign?: "left" | "center" | "right";
  className?: string;
}

const FaSubtitle = (props: FaSubtitleProps) => {
  const {
    text,
    size = "medium",
    align = "left",
    className,
    mobileAlign,
  } = props;

  return (
    <span
      className={clsx(
        "text-subtitle not-italic block !font-text",
        size === "large" && "text-2xl",
        size === "medium" && "text-lg",
        size === "small" && "text-base md:text-lg",
        align === "left" && "md:text-left",
        align === "center" && "md:text-center",
        align === "right" && "md:text-right",
        mobileAlign === "left" && "text-left",
        mobileAlign === "center" && "text-center",
        mobileAlign === "right" && "text-right",
        className,
      )}
    >
      {text}
    </span>
  );
};

export default FaSubtitle;
