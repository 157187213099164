interface FaInfosProps {
  version: string;
}

const FaInfos = ({ version }: FaInfosProps) => {
  return (
    <i className="not-italic text-xs md:text:base text-subtitle font-bold">
      {version}
    </i>
  );
};

export default FaInfos;
