import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import {
  Profile,
  UsersPermissionsUserRegistration
} from "../../api/WorkoutGenApi"
import type { RootState } from "../../commons/redux/store"

export interface AuthState {
  user?: UsersPermissionsUserRegistration
  profile?: Profile
}

const initialState: AuthState = {
  user: undefined,
  profile: undefined
}

export const AuthSlice = createSlice({
  name: "auth",

  initialState,

  reducers: {
    setUser: (
      state,
      action: PayloadAction<UsersPermissionsUserRegistration | undefined>
    ) => {
      state.user = action.payload
    },

    setProfile: (state, action: PayloadAction<Profile | undefined>) => {
      state.profile = action.payload
    }
  }
})

export const { setUser, setProfile } = AuthSlice.actions

export const selectAuth = (state: RootState): AuthState => state.auth

export default AuthSlice.reducer
