import { IonIcon, IonImg } from "@ionic/react";
import { FinalSessionExerciseParamsWithExercise } from "@workoutgen/global-typings/engine-types";
import clsx from "clsx";
import { checkmark } from "ionicons/icons";
import { useEffect } from "react";

interface FaPlayerTrainingOverviewProps {
  baseCurrentTraining: FinalSessionExerciseParamsWithExercise[];
  currentExerciseIndex: number;
  isTrainingEnd: boolean;
}

const FaPlayerTrainingOverview = ({
  baseCurrentTraining,
  currentExerciseIndex,
  isTrainingEnd,
}: FaPlayerTrainingOverviewProps) => {
  useEffect(() => {
    const currentExercise = document.getElementById(
      `exercise-overview-${currentExerciseIndex}`,
    );

    currentExercise?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [currentExerciseIndex, isTrainingEnd]);
  return (
    <div className="lg:sticky top-0 px-3 lg:px-10 h-screen overflow-auto lg:py-5">
      <div className="grid lg:grid-cols-1 gap-3 lg:gap-5 mb-6">
        {baseCurrentTraining.map((exercise, index) => (
          <div
            id={`exercise-overview-${index}`}
            className="relative w-full lg:max-w-[180px] 2xl:max-w-[220px]"
            key={exercise.name}
          >
            <div className="relative rounded-3xl h-[200px]">
              <div
                className={clsx(
                  "absolute inset-0 rounded-3xl px-4 py-6",
                  currentExerciseIndex > index || isTrainingEnd
                    ? "bg-white/90"
                    : "player-desktop-cards-gradient",
                )}
              >
                <div className="absolute top-3 right-3">
                  <p className="text-xs text-subtitle text-center mb-2">
                    {index + 1}
                  </p>
                  <div
                    className={clsx(
                      "h-[10px] w-[10px] rounded-full",
                      (currentExerciseIndex > index || isTrainingEnd) &&
                        "bg-success",
                      currentExerciseIndex === index &&
                        !isTrainingEnd &&
                        "animate-blue-purple-pink-cycle-animation-fast",
                      currentExerciseIndex < index &&
                        !isTrainingEnd &&
                        "bg-black",
                    )}
                  />
                </div>

                {(currentExerciseIndex > index || isTrainingEnd) && (
                  <div className="centered-flex-col h-full gap-1">
                    <IonIcon className="text-xl" icon={checkmark} />

                    <p className="text-sm">Réussi</p>
                  </div>
                )}

                {currentExerciseIndex <= index && !isTrainingEnd && (
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="text-shadow text-center text-white text-xs">
                        {exercise.sets.length} Séries
                      </p>

                      <p className="text-shadow text-center text-white text-xs mt-1">
                        {exercise.sets[0].reps} Reps
                      </p>
                    </div>

                    <p className="text-shadow text-center text-xs text-white mt-2"></p>
                  </div>
                )}
              </div>

              <IonImg
                className="rounded-3xl object-cover h-[200px]"
                src={exercise.move_start.url}
                alt={exercise.name}
              />
            </div>

            <p className="text-center mt-2 text-subtitle text-xs">
              {exercise.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaPlayerTrainingOverview;
