import { yupResolver } from "@hookform/resolvers/yup"
import { IonInput, IonItem, IonRouterLink } from "@ionic/react"
import FaButton from "@workoutgen/design-system/FaButton"
import FaGlitchTitle from "@workoutgen/design-system/FaGlitchTitle"
import FaVerticalTitle from "@workoutgen/design-system/FaVerticalTitle"
import { ApiResponse } from "@workoutgen/global-typings/kiosk-types"
import clsx from "clsx"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import * as yup from "yup"
import { usePostAuthForgotPasswordMutation } from "../../../api/WorkoutGenApi"
import { APP_ROUTES } from "../../../commons/constants"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

type ResetRequestForm = {
  email: string
}

const ResetPasswordRequest = () => {
  const [forgotPassword, { isLoading: forgotPasswordLoading }] =
    usePostAuthForgotPasswordMutation()

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, "L'email doit être valide")
      .email("L'email doit être valide")
      .required("L'email est requis")
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm<ResetRequestForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: ""
    }
  })

  const onSubmit = async ({ email }: ResetRequestForm): Promise<void> => {
    try {
      const payload = {
        body: { email }
      }

      const response = await forgotPassword(payload)

      if ("error" in response) {
        const apiError = response as ApiResponse

        console.log(apiError.error.data.error)

        throw new Error(apiError.error.data.error.message)
      }

      toast.success(
        "Un email contenant un lien de réinitialisation a été envoyé"
      )
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    }
  }

  return (
    <FaPageLayout
      padding={false}
      paddingHorizontal
      content={
        <div
          className={clsx(
            "flex flex-col justify-center h-screen",
            "max-w-[400px] mx-auto"
          )}
        >
          <div>
            <div className="centered-flex-col gap-3 mb-8">
              <FaGlitchTitle text="WKG." />

              <h1 className="text-xl">Récupération de compte</h1>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <IonItem lines="full">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <IonInput
                      label="Entrez votre email"
                      labelPlacement="floating"
                      type="email"
                      inputmode="email"
                      onIonInput={field.onChange}
                      required
                      {...field}
                    />
                  )}
                />
              </IonItem>

              <div className="h-[30px] flex items-center">
                {errors?.email?.message && (
                  <p className="text-danger text-xs ml-5" slot="error">
                    {errors?.email?.message}
                  </p>
                )}
              </div>

              <div className="mt-4 relative flex flex-col items-center justify-center text-center">
                <FaButton
                  type="submit"
                  loading={forgotPasswordLoading}
                  disabled={!isDirty || !isValid || forgotPasswordLoading}
                  animated={isValid}
                  className={clsx(
                    "mb-10 mt-4",
                    isValid && "animate-blue-purple-pink-cycle-animation-fast"
                  )}
                >
                  Demander une réinitialisation
                </FaButton>

                <IonRouterLink
                  routerLink={APP_ROUTES.LOGIN}
                  routerDirection="root"
                  className="text-subtitle text-xs underline"
                >
                  Retour à la connexion
                </IonRouterLink>
              </div>
            </form>
          </div>

          <FaVerticalTitle />
        </div>
      }
    />
  )
}

export default ResetPasswordRequest
