import { APP_ROUTES } from "@workoutgen/design-system/constants"
import FaButton from "@workoutgen/design-system/FaButton"
import FaCounter from "@workoutgen/design-system/FaCounter"
import { UserTracking } from "@workoutgen/global-typings/engine-types"
import clsx from "clsx"
import { differenceInMilliseconds } from "date-fns/differenceInMilliseconds"
import { motion } from "framer-motion"
import { useEffect, useMemo, useState } from "react"
import { useAppSelector } from "../../../../../commons/hooks/useAppSelector"
import { selectGlobal } from "../../../../../commons/redux/globalSlice"
import { useTimelineNavigation } from "../../../../../timeline/hooks/useTimelineNavigation"

interface StartButtonProps {
  lastUserTracking: UserTracking
  currentSlideIndex: number
}

export const StartButton = ({
  lastUserTracking,
  currentSlideIndex
}: StartButtonProps) => {
  const { devMode, userWorkout, userTracking } = useAppSelector(selectGlobal)

  const { handleSetNextSession } = useTimelineNavigation()

  const { session_index, countdown, achievement_rate } = lastUserTracking

  const currentSession = userWorkout?.workout.sessions[(session_index - 1) % 7]
  const isRest = currentSession?.sessionType === "rest"
  const isCurrentSession = currentSlideIndex === (session_index - 1) % 7

  const currentCountDown = useMemo(() => {
    const targetDate = new Date(countdown)

    const now = new Date()
    return differenceInMilliseconds(targetDate, now) / 1000
  }, [countdown])

  const [isTrainingReady, setIsTrainingReady] = useState(currentCountDown <= 0)

  const isPwa = window.matchMedia("(display-mode: standalone)").matches

  const trainingPath = isRest
    ? undefined
    : currentCountDown >= 0 && !devMode
      ? undefined
      : APP_ROUTES.TRAINING_PREVIEW + "/" + session_index

  useEffect(() => {
    if (currentCountDown <= 0) {
      setIsTrainingReady(true)
    } else {
      setIsTrainingReady(false)
    }
  }, [session_index, currentCountDown])

  const handleCounterEnd = async () => {
    setIsTrainingReady(true)

    if (isRest && userTracking) {
      handleSetNextSession(true)
    }
  }

  const handleDevModePassRest = () => {
    if (devMode && isRest) {
      handleCounterEnd()
    }
  }

  return (
    <motion.nav
      initial={{ y: isCurrentSession ? 100 : 0 }}
      animate={{ y: isCurrentSession ? 0 : 100 }}
      transition={{ duration: 0.1 }}
      className={clsx(
        "mt-4 fixed inset-x-3 z-50",
        isPwa ? "training-button-mobile-native" : "training-button",
        !isCurrentSession && !isTrainingReady && "!pointer-events-none"
      )}
    >
      <FaButton
        to={trainingPath}
        animated={isTrainingReady}
        className={clsx(
          "w-full shadow-2xl flex justify-between items-center",
          !isTrainingReady && "!bg-black"
        )}
        size="large"
        onClick={handleDevModePassRest}
      >
        {isCurrentSession && (
          <>
            {isTrainingReady ? (
              <div className="flex justify-between items-center w-full">
                {achievement_rate > 0 ? (
                  <span className="flex-1 block">
                    Continuer l&apos;entrainement
                  </span>
                ) : (
                  <span className="flex-1 block">S&apos;entrainer</span>
                )}
              </div>
            ) : (
              <FaCounter
                fullDate
                leftTime={currentCountDown}
                onCounterEnd={handleCounterEnd}
              />
            )}
          </>
        )}

        {devMode && isCurrentSession && !isTrainingReady && (
          <span className="text-white text-xs absolute -top-7 right-0 bg-black rounded-full p-1">
            {"</>"} Lancer la session
          </span>
        )}
      </FaButton>
    </motion.nav>
  )
}
