import { IonIcon, IonImg, IonSpinner } from "@ionic/react";
import { ExercisePlayerCard } from "@workoutgen/global-typings/kiosk-types";
import { useInterval } from "ahooks";
import clsx from "clsx";
import { warning } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";

interface FaPlayerVideoProps {
  exercise: ExercisePlayerCard;
}

const FaPlayerVideo = ({ exercise }: FaPlayerVideoProps) => {
  const { move_video, move_start, move_end, name } = exercise;

  const [isLoading, setIsLoading] = useState(true);
  const [displayFallback, setDisplayFallback] = useState(false);
  const [animate, setAnimate] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useInterval(
    () => {
      setAnimate((prev) => !prev);
    },
    displayFallback ? 1000 : undefined,
  );

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setDisplayFallback(true);
      setIsLoading(false);
    }, 10000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleVideoCanPlay = () => {
    setIsLoading(false);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleVideoError = () => {
    setDisplayFallback(true);
    setIsLoading(false);
  };

  return (
    <div className="relative">
      {isLoading && (
        <div className="fixed bg-white inset-0 flex items-center justify-center z-10 h-full">
          <IonSpinner />
        </div>
      )}

      {displayFallback && (
        <>
          <div className="absolute top-2 left-1/2 transform -translate-x-1/2 z-50 flex flex-col items-center gap-1">
            <IonIcon className="text-white text-xl" icon={warning} />

            <p className="text-white text-xs">Connexion instable</p>
          </div>

          <IonImg
            className={clsx(
              "fixed inset-0 object-cover h-full",
              animate ? "opacity-0" : "opacity-100",
            )}
            src={move_start.url}
            alt={name}
          />

          <IonImg
            className={clsx(
              "fixed inset-0 object-cover h-full",
              animate ? "opacity-100" : "opacity-0",
            )}
            src={move_end.url}
            alt={name}
          />
        </>
      )}

      <video
        ref={videoRef}
        className={clsx(
          "fixed inset-0 object-cover h-full",
          displayFallback && "opacity-0",
        )}
        style={{ zIndex: -1 }}
        loop
        playsInline
        muted
        autoPlay
        controlsList="nodownload"
        onCanPlay={handleVideoCanPlay}
        onError={handleVideoError}
      >
        <source src={move_video.url} type="video/webm" />
        Votre navigateur ne supporte pas la balise vidéo.
      </video>
    </div>
  );
};

export default FaPlayerVideo;
