import { selectAuth } from "../../authentification/redux/authentication"
import { selectGlobal } from "../redux/globalSlice"
import { useAppSelector } from "./useAppSelector"

export const useAppIsReady = () => {
  const { user, profile } = useAppSelector(selectAuth)

  const { userTracking, userSettings, userWorkout, lastUserTracking } =
    useAppSelector(selectGlobal)

  const appIsReady = [
    user?.jwt,
    profile,
    userTracking,
    userSettings,
    userWorkout,
    lastUserTracking
  ].every((value) => value !== undefined)

  return appIsReady
}
