import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { AuthState } from "../authentification/redux/authentication"

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_WORKOUTGEN_API_BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as { auth: AuthState }

      if (state.auth.user?.jwt && state.auth.user.jwt !== "testmode") {
        headers.set("authorization", `Bearer ${state.auth.user.jwt}`)
      }

      return headers
    }
  }),

  keepUnusedDataFor: 60 * 60 * 2,

  endpoints: () => ({})
})
