import { APP_ROUTES } from "../../commons/constants"
import SettingsHome from "../pages/ProfileSettings/ProfileSettings"
import { TrainingSettings } from "../pages/TrainingSettings/TrainingSettings"

const settingsRoutes = [
  {
    path: APP_ROUTES.PROFILE_SETTINGS,
    component: <SettingsHome />
  },
  {
    path: APP_ROUTES.TRAINING_SETTINGS,
    component: <TrainingSettings />
  }
]

export default settingsRoutes
