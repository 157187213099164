import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { IonIcon } from "@ionic/react";
import {
  ExercisePlayerCard,
  UserSettings,
} from "@workoutgen/global-typings/kiosk-types";
import clsx from "clsx";
import { pause, volumeHigh } from "ionicons/icons";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FILE_SYSTEM_KEYS } from "../../../../constants";

const synth = window.speechSynthesis;

interface AudioProps {
  isCurrentSlide: boolean;
  exercisePlayerCard: ExercisePlayerCard;
  userSettings: UserSettings;
  onUpdateUserSettings: (userSettings: UserSettings) => void;
}

export const Audio = ({
  exercisePlayerCard,
  isCurrentSlide,
  userSettings,
  onUpdateUserSettings,
}: AudioProps) => {
  const { speedSpeech: savedSpeedSpeech } = userSettings;

  const [isTextToSpeechEnabled, setIsTextToSpeechEnabled] = useState(false);
  const [speedSpeech, setSpeedSpeech] = useState(savedSpeedSpeech);

  const cleanedMarkdown = exercisePlayerCard.execution.replace(/[*_]/g, "");

  const handleEnableTextToSpeech = () => {
    try {
      if (isTextToSpeechEnabled) {
        synth.cancel();
        setIsTextToSpeechEnabled(false);
      } else {
        const utterThis = new SpeechSynthesisUtterance(cleanedMarkdown);
        utterThis.rate = speedSpeech;
        synth.speak(utterThis);
        setIsTextToSpeechEnabled(true);
      }
    } catch (error) {
      console.error("Error enabling text to speech", error);

      toast.error("Erreur lors de l'activation de la synthèse vocale");
    }
  };

  const toggleSpeedSpeech = async () => {
    const newSpeed = speedSpeech === 1 ? 1.5 : 1;

    const newUserSettings = { ...userSettings, speedSpeech: newSpeed };

    await Filesystem.writeFile({
      path: FILE_SYSTEM_KEYS.SECRETS.USER_SETTINGS,
      data: JSON.stringify(newUserSettings),
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    });

    setSpeedSpeech(newSpeed);

    onUpdateUserSettings(newUserSettings);

    if (isTextToSpeechEnabled) {
      synth.cancel();
      const utterThis = new SpeechSynthesisUtterance(cleanedMarkdown);
      utterThis.rate = newSpeed;
      synth.speak(utterThis);
      utterThis.onend = () => {
        setIsTextToSpeechEnabled(false);
      };
    }
  };

  useEffect(() => {
    if (!isCurrentSlide) {
      synth.cancel();
      setIsTextToSpeechEnabled(false);
    }
  }, [isCurrentSlide]);

  return (
    <>
      <div
        className={clsx(
          "w-14 h-14 flex flex-col items-center justify-center",
          synth.speaking && "animate-pulse",
        )}
      >
        <button onClick={handleEnableTextToSpeech}>
          {isTextToSpeechEnabled ? (
            <IonIcon
              className="text-3xl xl:text-4xl text-white ui-player-text-element"
              slot="icon-only"
              icon={pause}
            />
          ) : (
            <IonIcon
              className="text-3xl xl:text-4xl text-white ui-player-text-element"
              slot="icon-only"
              icon={volumeHigh}
            />
          )}
        </button>

        <i className="text-[0.7rem] ui-player-text-element xl:text-xs text-white not-italic">
          {isTextToSpeechEnabled ? "Pause" : "Lire"}
        </i>
      </div>

      {synth.speaking && (
        <button
          onClick={toggleSpeedSpeech}
          className="w-14 h-5 flex flex-col items-center justify-center"
        >
          <h6 className="text-xl italic text-black not-italic ui-player-text-element tracking-tight">
            x{speedSpeech === 1.5 ? 1 : 2}
          </h6>
        </button>
      )}
    </>
  );
};
