import { FinalSessionExerciseParamsWithExercise } from "@workoutgen/global-typings/engine-types";

export const calculateTrainingDuration = (
  exercises: FinalSessionExerciseParamsWithExercise[],
  notFormated = false,
): string | number => {
  let totalSeconds = 0;

  exercises.forEach((exercise) => {
    totalSeconds += 1;

    exercise.sets.forEach((set) => {
      if (set.unit === "seconds") {
        totalSeconds += set.reps;
      } else {
        totalSeconds += set.reps * 2;
      }

      totalSeconds += set.setsRecups;
    });

    totalSeconds += exercise.exerciseRecup;
  });

  if (notFormated) {
    return totalSeconds;
  }

  if (totalSeconds >= 60) {
    return `Durée ≈ ${Math.floor(totalSeconds / 60)} min`;
  } else {
    return `Durée ≈ 1 min`;
  }
};

export const calcTotalDuration = (
  exercisesList: FinalSessionExerciseParamsWithExercise[][],
) => {
  const totalDuration = exercisesList.reduce((acc, exercises) => {
    return (calculateTrainingDuration(exercises, true) as number) + acc;
  }, 0);

  return `Durée totale ≈ ${Math.floor(totalDuration / 60)} min`;
};
