import { IonIcon } from "@ionic/react";
import clsx from "clsx";
import {
  analytics,
  bonfire,
  checkmark,
  options,
  settings,
} from "ionicons/icons";
import { useMemo } from "react";
import {
  APP_ROUTES,
  CYCLES_DICT,
  TRAINING_FORMAT_DICT,
  TRAINING_TYPE_DICT,
} from "../../../constants";
import FaChip from "../../commons/FaChip/FaChip";
import FaEmojiAnimation from "../../commons/FaEmojiAnimation/FaEmojiAnimation";
import FaSurface from "../../commons/FaSurface/FaSurface";
import FaAnimatedNumber from "../../typography/FaAnimatedNumber/FaAnimatedNumber";
import FaBodypartCard from "../FaBodypartCard/FaBodypartCard";
import { FaFollowup } from "../FaFollowup/FaFollowup";
import { FaThink } from "../FaThink/FaThink";
import { useTimelineContext } from "../providers/TimelineProvider";

export const FaTrainingSlide = () => {
  const {
    session,
    currentDate,
    lastUserTracking,
    userTracking,
    currentSlideIndex,
  } = useTimelineContext();

  const { session_index } = lastUserTracking;

  const {
    sessionIndex,
    bodyparts,
    trainingFormat,
    trainingType,
    realTargetName,
  } = session;

  const currentBodyparts = Array.from(new Set(bodyparts));

  const metaData = [
    TRAINING_FORMAT_DICT[trainingFormat],
    CYCLES_DICT[realTargetName],
    TRAINING_TYPE_DICT[trainingType],
  ];

  const isSession = session.sessionType === "session";

  const isCurrentSession = useMemo(
    () => session_index === sessionIndex,
    [session_index],
  );

  const isDoneSession = useMemo(
    () => session_index > sessionIndex,
    [session_index],
  );

  const isFutureSession = useMemo(
    () => session_index < sessionIndex,
    [session_index],
  );

  const currentUserTracking = useMemo(
    () => userTracking.find((item) => item.session_index === sessionIndex),
    [userTracking, sessionIndex],
  );

  const previousUserTracking = useMemo(
    () => userTracking.find((item) => item.session_index === sessionIndex - 1),
    [userTracking, sessionIndex],
  );

  const nextCurrentUserTracking = useMemo(
    () => userTracking.find((item) => item.session_index === sessionIndex + 1),
    [userTracking, sessionIndex],
  );

  const countBlocs = Object.values(
    currentUserTracking?.training_followup ?? {},
  ).filter((item) => item === "done").length;

  const levelGain =
    (nextCurrentUserTracking?.level ?? 0) - (currentUserTracking?.level ?? 0);

  const pointGain =
    (currentUserTracking?.wkg_points ?? 0) -
    (previousUserTracking?.wkg_points ?? 0);

  const endSpacing = window.matchMedia("(display-mode: standalone)").matches
    ? "h-[115px]"
    : "h-[85px]";

  return (
    <article className={clsx("flex flex-col gap-6")}>
      <section>
        <header className="mb-3">
          <h1 className="text-xl">
            {isSession ? "Entrainement" : "Récupération"}{" "}
            <span className="italic">{sessionIndex}</span>
          </h1>

          <p className="mt-1 text-subtitle">{currentDate}</p>
        </header>

        {!isDoneSession && isSession && (
          <div className="relative">
            <div className={clsx(isDoneSession && "opacity-10")}>
              <div className="flex gap-1.5 mb-3 flex-wrap hide-scroll">
                {metaData.map((item) => (
                  <FaChip item={item} key={item} />
                ))}
              </div>

              <FaSurface>
                <div className="w-full centered-flex-col">
                  <FaBodypartCard bodyparts={bodyparts ?? []} />
                </div>
              </FaSurface>

              <div className="flex gap-1.5 mt-3 flex-wrap hide-scroll">
                {currentBodyparts.map((item, index) => (
                  <FaChip item={item} key={item} />
                ))}
              </div>
            </div>
          </div>
        )}

        {isDoneSession &&
          nextCurrentUserTracking?.level &&
          currentUserTracking?.wkg_points && (
            <FaSurface
              className={clsx(
                "!bg-black !text-white",
                !isSession && "h-[116px]",
              )}
            >
              <div className="centered-flex-col h-full">
                <h6 className="text-xl !text-white">Réussi</h6>

                <div className="grid grid-cols-3 gap-5">
                  <div className="centered-flex-col gap-1">
                    <h6 className="flex items-center text-3xl !text-white">
                      <span className="text-base">+</span>
                      <FaAnimatedNumber
                        key={currentSlideIndex}
                        value={levelGain}
                      />
                    </h6>

                    <p className="text-xs">Niveau</p>
                  </div>

                  <div className="centered-flex-col gap-1">
                    <h6 className="flex items-center text-3xl !text-white">
                      <span className="text-base">+</span>
                      <FaAnimatedNumber
                        key={currentSlideIndex}
                        value={pointGain}
                      />
                    </h6>

                    <p className="text-xs">WKG</p>
                  </div>

                  {isSession && (
                    <div className="centered-flex-col gap-1">
                      <h6 className="flex items-center text-3xl !text-white">
                        <FaAnimatedNumber
                          key={currentSlideIndex}
                          value={countBlocs}
                        />
                        <span className="text-base">/ 5</span>
                      </h6>

                      <p className="text-xs">Bloc</p>
                    </div>
                  )}

                  {!isSession && (
                    <div className="centered-flex-col gap-1">
                      <h6 className="flex items-center text-3xl gap-1 !text-white">
                        1 <span className="text-base"> / 1</span>
                      </h6>

                      <p className="text-xs">Repos</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="absolute right-4 top-3">
                <IonIcon className="text-2xl" icon={checkmark} />
              </div>
            </FaSurface>
          )}

        {!isSession && !isDoneSession && (
          <section
            className={clsx(
              "flex flex-col gap-3",
              !isCurrentSession && "opacity-30 pointer-events-none",
            )}
          >
            <FaSurface>
              <div className="centered-flex-col gap-3 h-[150px]">
                <div className="text-3xl">
                  <FaEmojiAnimation
                    interval={isCurrentSession ? 1500 : undefined}
                  />
                </div>

                <p>
                  La récupération fait partie intégrante de votre entrainement.
                  Vous avez bien mérité ce moment de repos.
                </p>
              </div>
            </FaSurface>
          </section>
        )}
      </section>

      {isCurrentSession && (
        <section className={clsx(!isCurrentSession && "opacity-30")}>
          <h2 className="text-xl mb-3">Pensée du jour</h2>

          <FaSurface>
            <div className="h-[100px] centered-flex-col">
              <div className="flex justify-center mb-3">
                <IonIcon className="text-2xl" icon={bonfire} />
              </div>

              {isCurrentSession && <FaThink />}
            </div>
          </FaSurface>
        </section>
      )}

      <section className="flex flex-col gap-3">
        <header>
          <h2 className="text-xl">Suivi</h2>

          {(isFutureSession || isCurrentSession) && (
            <p>Finissez votre entrainement pour voir votre progression</p>
          )}
        </header>

        <FaSurface>
          <div
            className={clsx(
              (isFutureSession || isCurrentSession) && "opacity-30",
            )}
          >
            <FaFollowup
              key={currentSlideIndex}
              isFutureSession={isFutureSession}
              isCurrentSession={isCurrentSession}
            />
          </div>
        </FaSurface>
      </section>

      <section className="flex flex-col gap-3">
        <header>
          <h2 className="text-xl">Hub</h2>
        </header>

        <FaSurface href={APP_ROUTES.JOURNEY}>
          <div className="h-full flex gap-3">
            <IonIcon className="text-2xl" icon={analytics} />

            <p>Mon parcours</p>
          </div>
        </FaSurface>

        <FaSurface href={APP_ROUTES.TRAINING_SETTINGS}>
          <div className="h-full flex gap-3">
            <IonIcon className="text-2xl" icon={options} />

            <p>Gerer mon programme</p>
          </div>
        </FaSurface>

        <FaSurface href={APP_ROUTES.PROFILE_SETTINGS}>
          <div className="h-full flex gap-3">
            <IonIcon className="text-2xl" icon={settings} />

            <p>Gerer mon profil</p>
          </div>
        </FaSurface>
      </section>

      <div className={clsx(isCurrentSession ? endSpacing : "h-1")} />
    </article>
  );
};
