import {
  ExercisePlayerCard,
  PlayerTracking
} from "@workoutgen/global-typings/kiosk-types"

export const generateTracking = (
  exercises?: ExercisePlayerCard[]
): PlayerTracking => {
  if (!exercises) return []

  let count = 0

  return exercises
    .map((exercise) => ({
      index: count++,
      name: exercise.name,
      category: exercise.category
    }))
    .filter((exercise) => exercise.category !== "setRecup")
}
