import { useEffect } from "react"
import { FocusedStep, StepValidation } from "../../register/redux/registerSlice"

interface UseScrollToParams {
  currentStep: number
  stepValidation: StepValidation
  focusedStep?: FocusedStep
  formEnded: boolean
}

const scrollEngine = (currentStep: number): void => {
  const element: HTMLElement | null = document.getElementById(
    `step-${currentStep}`
  )

  const isMobile = window.innerWidth < 768

  if (element !== null) {
    element.scrollIntoView({
      behavior: "smooth",
      block: isMobile ? "center" : "start",
      inline: "center"
    })
  }
}

export const useScrollTo = (params: UseScrollToParams): void => {
  const { currentStep, stepValidation, focusedStep, formEnded } = params

  useEffect(() => {
    if (!focusedStep) return

    if (currentStep === null || currentStep === undefined) return

    // @ts-expect-error - This is a hack to remove the focus from the active element
    document?.activeElement?.blur()

    const formHasErrorsIndex = Object.values(stepValidation).findIndex(
      (step) => step === false
    )

    if (formHasErrorsIndex !== -1) scrollEngine(formHasErrorsIndex)
    else if (stepValidation[focusedStep.value as keyof StepValidation])
      scrollEngine(currentStep)
  }, [currentStep, focusedStep, stepValidation, formEnded])
}
