import {
  FinalSessionExerciseParamsWithExercise,
  SetValuesUnits
} from "@workoutgen/global-typings/engine-types"
import {
  ExerciseCategory,
  ExercisePlayerCard
} from "@workoutgen/global-typings/kiosk-types"

const createRecupExercise = (
  setsRecups: number,
  exerciseRecup: number,
  unit: SetValuesUnits,
  category: ExerciseCategory
): ExercisePlayerCard | null => {
  if (setsRecups === 0 && exerciseRecup === 0) return null

  return {
    name: "Récupération",
    category,
    reps: 0,
    setsRecups,
    unit,
    bodypart: "tout le corps",
    exerciseRecup,
    rm: 0,
    sets: [],
    is_poly_articular: false,
    levels: [],
    is_main: true,
    execution: "",
    move_start: { url: "" },
    move_end: { url: "" },
    move_video: { url: "" },
    categories: [],
    bodyparts: [],
    equipments: [],
    id: 0,
    documentId: "",
    execution_instructions: []
  }
}

export const generatePlayerCards = (
  exercises: FinalSessionExerciseParamsWithExercise[] = []
): ExercisePlayerCard[] => {
  if (exercises.length === 0) return []

  if (exercises.length === 1) {
    const exercise = exercises[0]
    const exerciseCard: ExercisePlayerCard = {
      ...exercise,
      category: "training",
      reps: exercise.sets[0].reps,
      setsRecups: exercise.sets[0].setsRecups,
      unit: exercise.sets[0].unit
    }
    return [exerciseCard]
  }

  return exercises.flatMap((exercise, exerciseIndex) => {
    const exerciseCards = exercise.sets.flatMap((set, setIndex) => {
      const exerciseCard: ExercisePlayerCard = {
        ...exercise,
        category: "training",
        reps: set.reps,
        setsRecups: set.setsRecups,
        unit: set.unit
      }

      const setRecupCard =
        setIndex < exercise.sets.length - 1
          ? createRecupExercise(set.setsRecups, 0, set.unit, "setRecup")
          : null

      return setRecupCard ? [exerciseCard, setRecupCard] : [exerciseCard]
    })

    const exerciseRecupCard =
      exerciseIndex < exercises.length - 1
        ? createRecupExercise(
            0,
            exercise.exerciseRecup,
            "seconds",
            "exercisesRecup"
          )
        : null

    return exerciseRecupCard
      ? [...exerciseCards, exerciseRecupCard]
      : exerciseCards
  })
}
