import { ButtonItems } from "@workoutgen/design-system/ButtonItemsTypes"
import { SelectImage } from "@workoutgen/design-system/FaSelectImageTypes"
import {
  Genders,
  Morphotypes,
  UserLevel,
  WorkoutTarget
} from "@workoutgen/global-typings/engine-types"

export const TARGETS: ButtonItems<WorkoutTarget> = [
  {
    title: "Masse musculaire",
    icon: "💪",
    value: "gain_muscle"
  },
  {
    title: "Être en forme",
    icon: "❤️",
    value: "be_fit"
  },
  {
    title: "Perdre du poids",
    icon: "🏃‍",
    value: "lose_weight",
    disabled: true
  },
  {
    title: "Gagner en force",
    icon: "🏋️",
    value: "strength",
    disabled: true
  }
]

export const GENDERS: ButtonItems<Genders> = [
  {
    title: "Homme",
    icon: "👨",
    value: "men"
  },
  {
    title: "Femme",
    icon: "👩‍🦰",
    value: "women"
  },
  {
    title: "Autre",
    icon: "🦄",
    value: "all"
  }
]

export const LEVELS: ButtonItems<UserLevel> = [
  {
    title: "Novice",
    slot: <p className="text-xs mt-1">Moins de 6 mois de pratique</p>,
    value: "beginner"
  },
  {
    title: "Moyen",
    slot: <p className="text-xs mt-1">Entre 6 mois et 4 ans</p>,
    value: "intermediate"
  },
  {
    title: "Expert",
    slot: <p className="text-xs mt-1">Plus de 4 ans de pratique</p>,
    value: "advanced"
  }
]

export const MORPHOTYPES: Array<SelectImage<Morphotypes>> = [
  {
    title: "Ectomorphe",
    value: "ectomorph",
    description: "Personne mince, avec un métabolisme rapide"
  },
  {
    title: "Mésomorphe",
    value: "mesomorph",
    description: "Personne musclée, avec un métabolisme rapide"
  },
  {
    title: "Endomorphe",
    value: "endomorph",
    description: "Personne ronde, avec un métabolisme lent"
  }
]

export const AVAILABILITYS: ButtonItems<number> = [
  { title: `1 jour`, icon: undefined, value: 1 },
  { title: `2 jours`, icon: undefined, value: 2 },
  { title: `3 jours`, icon: undefined, value: 3 },
  { title: `4 jours`, icon: undefined, value: 4 },
  { title: `5 jours`, icon: undefined, value: 5 },
  { title: `6 jours`, icon: undefined, value: 6 }
]

export const ACTIVITY_LEVELS = [
  {
    title: "Sédentaire",
    value: "sedentary",
    icon: "🦥"
  },
  {
    title: "Faible activité",
    value: "slightly_active",
    icon: "🧍"
  },
  {
    title: "Modérée",
    value: "active",
    icon: "️🚶"
  },
  {
    title: "Très actif",
    value: "very_active",
    icon: "🏃"
  },
  {
    title: "Athlète",
    value: "extremly_active",
    icon: "🏋️‍"
  }
]
