/* LOCAL STORAGE
 *********************************************** */
export const EMAIL_FOR_SIGN_IN = "emailForSignIn"

/* TOAST
 *********************************************** */
export const TOAST_DURATION = 4000
export const TOAST_POSITION = "top"

/* ROUTES
 *********************************************** */
export enum APP_ROUTES {
  HOME = "/",
  LOGIN = "/login",
  GENERATE_WKG_WORKOUT = "/generate-wkg-workout",
  TRAINING_PREVIEW = "/training-preview",
  PLAYER = "/player",
  TIMELINE = "/timeline",
  JOURNEY = "/journey",
  PROFILE_SETTINGS = "/profile-settings",
  TRAINING_SETTINGS = "/training-settings",
  RESET_PASSWORD_REQUEST = "/reset-password-request",
  RESET_PASSWORD = "/reset-password"
}

/* GLOBAL
 *********************************************** */
export const EMAIL_VALIDATION_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
export const TOTAL_SESSION_WORKOUTGEN_POINTS = 50

/* FILE KEYS
 *********************************************** */
export const SECRETS_FOLDER = "secrets"

export const FILE_SYSTEM_KEYS = {
  SECRETS: {
    USER_WORKOUT: `${SECRETS_FOLDER}/userWorkout.json`,
    USER_PATH: `${SECRETS_FOLDER}/userPath.json`,
    USER: `${SECRETS_FOLDER}/user.json`,
    USER_TRACKING: `${SECRETS_FOLDER}/userTracking.json`,
    CURRENT_WORKOUT_PROGRESS: `${SECRETS_FOLDER}/currentWorkoutProgress.json`,
    PROFILE: `${SECRETS_FOLDER}/profile.json`,
    USER_SETTINGS: `${SECRETS_FOLDER}/userSettings.json`
  }
}

export const CYCLE_NAME_DICT = {
  toning: "tonification",
  hypertrophy: "hypertrophie",
  mass_gain: "prise de masse",
  reinforcement: "renforcement",
  cardio1: "cardio 1",
  cardio2: "cardio 2",
  cardio3: "cardio 3"
}

export const TARGET_NAME_DICT = {
  lose_weight: "perte de poids",
  gain_muscle: "prise de masse",
  be_fit: "être en forme",
  strength: "force"
}

export const WKG_POINTS_DICT: { [key: string]: number } = {
  join_unlocking: 15,
  warmup: 15,
  exercises: 40,
  abds: 15,
  stretching: 15
}
