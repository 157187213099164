import { IonBackButton, IonButtons } from "@ionic/react"
import FaAnimatedNumber from "@workoutgen/design-system/FaAnimatedNumber"
import FaButton from "@workoutgen/design-system/FaButton"
import FaChip from "@workoutgen/design-system/FaChip"
import FaSurface from "@workoutgen/design-system/FaSurface"
import FaTrainingProgress from "@workoutgen/design-system/FaTrainingProgress"
import { FaProgressState } from "@workoutgen/design-system/FaTrainingProgressTypes"
import { maxLevelsFromSessions } from "@workoutgen/design-system/calc-level"
import clsx from "clsx"
import { useMemo } from "react"
import {
  APP_ROUTES,
  CYCLE_NAME_DICT,
  TARGET_NAME_DICT
} from "../../../commons/constants"
import { useAppSelector } from "../../../commons/hooks/useAppSelector"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"
import { selectGlobal } from "../../../commons/redux/globalSlice"
import { generateWeekSlice } from "../../../commons/utils/generateWeekSlice"
import { JourneyProgress } from "../../components/JourneyProgress/JourneyProgress"

const JourneyPage = () => {
  const { userWorkout, lastUserTracking, userTracking } =
    useAppSelector(selectGlobal)

  if (!userWorkout || !lastUserTracking || !userTracking) {
    console.error("No user tracking or user workout")

    return null
  }

  const { metadata } = userWorkout
  const { session_index, cycle_index, level, wkg_points } = lastUserTracking

  const { cyclesSummary, sessionsCount } = metadata

  const cyclesWithStartIndex = useMemo(() => {
    let sessionCounter = 1

    return cyclesSummary.map((cycle) => {
      const cycleWithStartIndex = {
        ...cycle,
        startSessionIndex: sessionCounter
      }
      sessionCounter += cycle.sessionCount

      return cycleWithStartIndex
    })
  }, [cyclesSummary])

  return (
    <FaPageLayout
      header={
        <IonButtons slot="start">
          <IonBackButton
            defaultHref={APP_ROUTES.TIMELINE}
            text="Mon parcours"
          />
        </IonButtons>
      }
      content={
        <div className="max-w-[500px] mx-auto">
          <header className="centered-flex-col gap-3">
            <h2 className="text-2xl tracking-tight flex gap-1">Synthèse</h2>

            <FaChip
              item={`Objectif ${
                TARGET_NAME_DICT[
                  metadata.workoutTarget as keyof typeof TARGET_NAME_DICT
                ]
              }`}
            />

            <FaSurface>
              <div className="grid grid-cols-2 gap-3 w-full rounded-2xl">
                <div className="centered-flex-col text-center">
                  <div className="font-title text-4xl tracking-tighter">
                    <FaAnimatedNumber value={metadata.cyclesCount} />
                  </div>

                  <p className="text-xs text-black">Cycles</p>
                </div>

                <div className="centered-flex-col text-center">
                  <div className="font-title text-4xl tracking-tighter">
                    <FaAnimatedNumber value={metadata.sessionsCount} />
                  </div>

                  <p className="text-xs text-black">Sessions</p>
                </div>
              </div>

              <div className="mt-4 flex items-center justify-center gap-2">
                <div className="flex items-center gap-2">
                  <p className="text-xs text-subtitle">0 bloc</p>
                  <div className="flex gap-1">
                    <div className="bg-black w-[15px] h-[15px] rounded" />

                    <div className="bg-secondary/35 w-[15px] h-[15px] rounded" />

                    <div className="bg-secondary/50 w-[15px] h-[15px] rounded" />

                    <div className="bg-secondary/70 w-[15px] h-[15px] rounded" />

                    <div className="bg-secondary/90 w-[15px] h-[15px] rounded" />

                    <div className="bg-secondary w-[15px] h-[15px] rounded" />
                  </div>

                  <p className="text-xs text-subtitle">5 blocs</p>
                </div>
              </div>
            </FaSurface>

            <FaSurface>
              <JourneyProgress
                type="level"
                partialValue={level}
                totalValue={maxLevelsFromSessions(sessionsCount)}
              />
            </FaSurface>

            <FaSurface>
              <JourneyProgress
                type="points"
                partialValue={wkg_points}
                totalValue={sessionsCount * 100}
              />
            </FaSurface>

            <FaSurface>
              <JourneyProgress
                type="progress"
                partialValue={session_index - 1}
                totalValue={sessionsCount}
              />
            </FaSurface>
          </header>

          {cyclesWithStartIndex.map((cycle, loopCycleIndex) => (
            <>
              <div className={clsx("centered-flex-col")}>
                <div
                  className={clsx(
                    "w-1 rounded-full",
                    "h-16 mt-5 mb-4",
                    loopCycleIndex < cycle_index
                      ? "animate-blue-purple-pink-cycle-animation-slow"
                      : "bg-black"
                  )}
                />
              </div>

              <article
                key={
                  cycle.cycleIndex + loopCycleIndex + cycle.startSessionIndex
                }
                className={clsx(
                  "flex flex-col gap-1",
                  loopCycleIndex === 0 && "mt-2"
                )}
              >
                <header className="mb-3">
                  <div className="flex flex-col items-center">
                    <h2 className="text-2xl tracking-tight flex gap-1 mb-2">
                      Cycle
                      <span className="font-title">
                        <FaAnimatedNumber value={cycle.cycleIndex} />
                      </span>
                    </h2>

                    <div className="flex justify-center flex-wrap gap-2 mb-4">
                      <FaChip
                        item={`Objectif ${
                          CYCLE_NAME_DICT[
                            cycle.cycleName as keyof typeof CYCLE_NAME_DICT
                          ]
                        }`}
                      />
                    </div>

                    <div className="centered-flex-col w-[115px]">
                      <div className="text-4xl font-title tracking-tighter flex items-end gap-1">
                        <FaAnimatedNumber value={121} />
                      </div>

                      <p className="text-xs text-black">Session</p>
                    </div>
                  </div>
                </header>

                <FaSurface>
                  <div className="w-full">
                    {generateWeekSlice({
                      totalLength: cycle.sessionCount,
                      sliceLength: 12,
                      startCounter: cycle.startSessionIndex
                    }).map((week, weekIndexLoop) => (
                      <div
                        key={weekIndexLoop + cycle.cycleIndex + loopCycleIndex}
                      >
                        <section className="grid grid-cols-12 gap-1">
                          {week.map((sessionIndex, sessionIndexLoop) => (
                            <>
                              <FaTrainingProgress
                                key={
                                  sessionIndex +
                                  weekIndexLoop +
                                  sessionIndexLoop
                                }
                                sessionIndex={sessionIndex}
                                progress={
                                  sessionIndex < session_index
                                    ? FaProgressState.COMPLETED
                                    : sessionIndex === session_index
                                      ? FaProgressState.IN_PROGRESS
                                      : FaProgressState.NOT_STARTED
                                }
                                intensity={
                                  userTracking[sessionIndex - 1]
                                    ?.achievement_rate ?? 0
                                }
                              />
                            </>
                          ))}
                        </section>
                      </div>
                    ))}
                  </div>
                </FaSurface>
              </article>
            </>
          ))}

          <div className="centered-flex-col">
            <div
              className={clsx(
                "w-1 h-24  my-5 rounded-full",
                metadata.cyclesCount === cycle_index
                  ? "animate-blue-purple-pink-cycle-animation-slow"
                  : "bg-black"
              )}
            />

            <div className="centered-flex-col">
              <FaButton disabled>Generation du parcours suivant</FaButton>
            </div>
          </div>
        </div>
      }
    />
  )
}
export default JourneyPage
