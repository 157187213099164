import { APP_ROUTES } from "../../commons/constants"
import JourneyPage from "../pages/JourneyPage/JourneyPage"

const profileRoutes = [
  {
    path: APP_ROUTES.JOURNEY,
    component: <JourneyPage />
  }
]

export default profileRoutes
