import { IonSpinner } from "@ionic/react";
import { ReactNode } from "react";
import FaAnimatedBackground from "../FaAnimatedBackground/FaAnimatedBackground";
import FaGlitchTitle from "../FaGlitchTitle/FaGlitchTitle";
import FaModal from "../FaModal/FaModal";

export interface AppLoaderProps {
  children: ReactNode;
  open: boolean;
}

const FaAppLoader = ({ children, open }: AppLoaderProps) => {
  return (
    <>
      <FaModal open={open} noAnimations hideCloseButton className="w-screen">
        <>
          <FaAnimatedBackground />

          <header className="h-full centered-flex-col lg:pt-[48px]">
            <section className="centered-flex-col">
              <FaGlitchTitle text="WKG." />

              <p className="animate-pulse text-subtitle mb-5">
                Initialisation de WorkoutGen
              </p>

              <IonSpinner />
            </section>
          </header>
        </>
      </FaModal>

      {children}
    </>
  );
};

export default FaAppLoader;
