import { FinalSessionExerciseParamsWithExercise } from "@workoutgen/global-typings/engine-types";
import FaPlayerTrainingOverview from "../FaPlayerTrainingOverview/FaPlayerTrainingOverview";
import FaDesktopInfos from "./FaDesktopInfos";

interface FaPlayerDesktopCardProps {
  currentExerciseIndex: number;
  isTrainingEnd: boolean;
  currentExercises: FinalSessionExerciseParamsWithExercise;
  baseCurrentTraining: FinalSessionExerciseParamsWithExercise[];
}

const FaPlayerDesktopCard = ({
  currentExerciseIndex,
  currentExercises,
  isTrainingEnd,
  baseCurrentTraining,
}: FaPlayerDesktopCardProps) => {
  return (
    <div className="hidden md:flex-1 pl-10 xl:pl-20 h-screen overflow-auto">
      <div className="flex justify-around">
        <FaDesktopInfos
          key={currentExerciseIndex}
          currentExercises={currentExercises}
        />

        <FaPlayerTrainingOverview
          baseCurrentTraining={baseCurrentTraining}
          currentExerciseIndex={currentExerciseIndex}
          isTrainingEnd={isTrainingEnd}
        />
      </div>
    </div>
  );
};

export default FaPlayerDesktopCard;
