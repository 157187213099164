import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import {
  type ActivityLevel,
  type AvailableDays,
  type Genders,
  type Locales,
  type Morphotypes,
  type Practice,
  type UserLevel,
  type WorkoutTarget
} from "@workoutgen/global-typings/engine-types"
import { RootState } from "../../commons/redux/store"

type LocalesDict = {
  fr: "fr"
  en: "en"
  es: "es"
}

export interface StepValidation {
  0: boolean
  1: boolean
  2: boolean
  3: boolean
  4: boolean
  5: boolean
  6: boolean
  7: boolean
  8: boolean
  9: boolean
  10: boolean
}

export interface FocusedStep {
  value: number
  timestamp: Date
}

export interface RegisterFormState {
  size?: number
  sizeIsValid: boolean
  weight?: number
  weightIsValid: boolean
  focusedStep?: FocusedStep
  age?: number
  ageIsValid: boolean
  gender?: Genders
  morphotype?: Morphotypes
  workoutTarget?: WorkoutTarget
  availableDays?: AvailableDays
  practice?: Practice
  non_available_equipments: string[]
  userLevel?: UserLevel
  activityLevel?: ActivityLevel
  country?: Locales
  firstName?: string
  email?: string
  acceptTerms?: boolean
  infosFormIsValid: boolean
  currentStep: number
  formEnded: boolean
  formAdvancement: number
  registerLoading: boolean
  stepValidation: StepValidation
  turnstile?: string
}

/*
const testInitialState: RegisterFormState = {
  size: 177,
  sizeIsValid: true,
  weight: 75,
  weightIsValid: true,
  age: 32,
  ageIsValid: true,
  gender: "men",
  focusedStep: {
    value: 10,
    timestamp: new Date()
  },
  morphotype: "mesomorph",
  workoutTarget: "gain_muscle",
  availableDays: 5,
  practice: "indoor",
  userLevel: "advanced",
  non_available_equipments: [],
  activityLevel: "slightly_active",
  country: "fr",
  email: "prospect",
  acceptTerms: true,
  infosFormIsValid: true,
  currentStep: 10,
  formEnded: true,
  formAdvancement: 100,
  registerLoading: false,
  stepValidation: {
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true
  }
}
*/

const initialState: RegisterFormState = {
  size: undefined,
  sizeIsValid: false,
  weight: undefined,
  weightIsValid: false,
  age: undefined,
  ageIsValid: false,
  gender: undefined,
  morphotype: undefined,
  workoutTarget: undefined,
  availableDays: undefined,
  practice: "indoor",
  userLevel: undefined,
  non_available_equipments: [],
  activityLevel: undefined,
  country: undefined,
  email: undefined,
  acceptTerms: false,
  infosFormIsValid: false,
  currentStep: 0,
  focusedStep: {
    value: 0,
    timestamp: new Date()
  },
  formEnded: false,
  formAdvancement: 0,
  registerLoading: false,
  stepValidation: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false
  },
  turnstile: undefined
}

export const registerFormSlice = createSlice({
  name: "register",

  initialState,

  reducers: {
    setTarget: (state, action: PayloadAction<WorkoutTarget>) => {
      state.workoutTarget = action.payload

      state.stepValidation[0] = true
    },

    setGender: (state, action: PayloadAction<Genders>) => {
      state.gender = action.payload

      state.stepValidation[1] = true
    },

    setMorphotypes: (state, action: PayloadAction<Morphotypes | undefined>) => {
      state.morphotype = action.payload

      state.stepValidation[2] = true
    },

    setAvailableDays: (state, action: PayloadAction<AvailableDays>) => {
      state.availableDays = action.payload

      state.stepValidation[8] = true
    },

    setActivityLevel: (state, action: PayloadAction<ActivityLevel>) => {
      state.activityLevel = action.payload

      state.stepValidation[3] = true
    },

    setAge: (state, action: PayloadAction<number>) => {
      state.age = Number(action.payload)
    },

    setAgeIsValid: (state, action: PayloadAction<boolean>) => {
      state.weightIsValid = action.payload

      state.stepValidation[4] = action.payload
    },

    setWeight: (state, action: PayloadAction<number>) => {
      state.weight = Number(action.payload)
    },

    setWeightIsValid: (state, action: PayloadAction<boolean>) => {
      state.weightIsValid = action.payload

      state.stepValidation[5] = action.payload
    },

    setSize: (state, action: PayloadAction<number>) => {
      state.size = Number(action.payload)
    },

    setSizeIsValid: (state, action: PayloadAction<boolean>) => {
      state.weightIsValid = action.payload

      state.stepValidation[6] = action.payload
    },

    setNonAvailableEquipment: (state, action: PayloadAction<string[]>) => {
      state.non_available_equipments = action.payload

      state.stepValidation[9] = true
    },

    setUserLevel: (state, action: PayloadAction<UserLevel>) => {
      state.userLevel = action.payload

      state.stepValidation[7] = true
    },

    setPractice: (state, action: PayloadAction<Practice>) => {
      state.practice = action.payload
    },

    setFocusedStep: (state, action: PayloadAction<FocusedStep>) => {
      state.focusedStep = action.payload
    },

    setLocale: (state, action: PayloadAction<Locales>) => {
      const localDict: LocalesDict = {
        en: "en",
        es: "es",
        fr: "fr"
      }

      state.country = localDict[action.payload]
    },

    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },

    setAcceptTerms: (state, action: PayloadAction<boolean>) => {
      state.acceptTerms = action.payload
    },

    setCurrentStep: (state, action: PayloadAction<number>) => {
      if (action.payload > state.currentStep) state.currentStep = action.payload

      const validatedStepsCount = Object.values(state.stepValidation).filter(
        Boolean
      ).length

      state.formAdvancement = Math.round((validatedStepsCount / 10) * 100)
    },

    setInfosFormIsValid: (state, action: PayloadAction<boolean>) => {
      state.infosFormIsValid = action.payload

      state.stepValidation[9] = action.payload
      state.stepValidation[10] = action.payload

      state.formEnded = action.payload

      if (action.payload) state.currentStep = 10
    },

    resetCurrentStep: (state) => {
      state.currentStep = 0
    },

    setFormEnded: (state, action: PayloadAction<boolean>) => {
      state.formEnded = action.payload
    },

    setTurnstile: (state, action: PayloadAction<string>) => {
      state.turnstile = action.payload
    }
  }
})

export const {
  setTarget,
  setGender,
  setMorphotypes,
  setAvailableDays,
  setActivityLevel,
  setNonAvailableEquipment,
  setUserLevel,
  setAge,
  setAgeIsValid,
  setWeight,
  setWeightIsValid,
  setSize,
  setSizeIsValid,
  setLocale,
  setEmail,
  setAcceptTerms,
  setCurrentStep,
  setInfosFormIsValid,
  setFormEnded,
  setPractice,
  setFocusedStep,
  setTurnstile
} = registerFormSlice.actions

export const selectRegisterForm = (state: RootState): RegisterFormState =>
  state.register

export default registerFormSlice.reducer
