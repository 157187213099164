import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import {
  ApiWKGWorkout,
  UserTracking
} from "@workoutgen/global-typings/engine-types"
import {
  BeforeInstallPromptEvent,
  UserSettings
} from "@workoutgen/global-typings/kiosk-types"
import type { RootState } from "./store"

interface GlobalState {
  userWorkout?: ApiWKGWorkout
  userTracking?: UserTracking[]
  lastUserTracking?: UserTracking
  isRegisterRequired: boolean
  pwaPromptEvent?: BeforeInstallPromptEvent
  userSettings?: UserSettings
  devMode: boolean
  appLoading: boolean
}

const initialState: GlobalState = {
  userWorkout: undefined,
  userTracking: undefined,
  lastUserTracking: undefined,
  isRegisterRequired: false,
  pwaPromptEvent: undefined,
  userSettings: undefined,
  devMode: false,
  appLoading: true
}

export const globalSlice = createSlice({
  name: "global",

  initialState,

  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload
    },

    setUserWorkout: (
      state,
      action: PayloadAction<ApiWKGWorkout | undefined>
    ) => {
      state.userWorkout = action.payload
    },

    setUserTracking: (
      state,
      action: PayloadAction<UserTracking[] | undefined>
    ) => {
      if (!action.payload) return

      state.userTracking = action.payload

      state.lastUserTracking = state.userTracking[state.userTracking.length - 1]
    },

    setIsRegisterRequired: (state, action: PayloadAction<boolean>) => {
      state.isRegisterRequired = action.payload
    },

    setPwaPromptEvent: (
      state,
      action: PayloadAction<BeforeInstallPromptEvent | undefined>
    ) => {
      state.pwaPromptEvent = action.payload
    },

    setUserSettings: (
      state,
      action: PayloadAction<UserSettings | undefined>
    ) => {
      state.userSettings = action.payload
    },

    setDevMode: (state, action: PayloadAction<boolean>) => {
      state.devMode = action.payload
    }
  }
})

export const {
  setAppLoading,
  setUserWorkout,
  setUserTracking,
  setIsRegisterRequired,
  setPwaPromptEvent,
  setUserSettings,
  setDevMode
} = globalSlice.actions

export const selectGlobal = (state: RootState): GlobalState => state.global

export default globalSlice.reducer
