import { IonIcon } from "@ionic/react"
import FaSpacer from "@workoutgen/design-system/FaSpacer"
import { useClickAway } from "ahooks"
import clsx from "clsx"
import { checkmark, close, trophy } from "ionicons/icons"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useMedia } from "react-use"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import { selectRegisterForm } from "../../../redux/registerSlice"

interface StepDict {
  [key: number]: string
}

export const RegisterErrors = () => {
  const { stepValidation, formEnded } = useAppSelector(selectRegisterForm)

  const isMobile = useMedia("(max-width: 767px)")
  const formHasErrors = Object.values(stepValidation).some(
    (step) => step === false
  )

  const ref = useRef(null)

  const [open, setOpen] = useState(false)

  const stepDict: StepDict = {
    0: "Target",
    1: "Gender",
    2: "Morpho",
    3: "Activity",
    4: "Age",
    5: "Weight",
    6: "Size",
    7: "Level",
    8: "Availabilitys",
    9: "Terms"
  }

  const handleScrollToStep = (step: number) => {
    const stepElement = document.getElementById(`step-${step}`)

    if (stepElement !== null) {
      stepElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      })
    }
  }

  useEffect(() => {
    if (isMobile === false) setOpen(true)
  }, [isMobile])

  useClickAway(() => {
    if (isMobile === false) return

    setOpen(false)
  }, ref)

  return (
    <>
      {open &&
        createPortal(
          <div
            ref={ref}
            className="fixed right-3 md:right-6 bottom-3 md:bottom-6 z-[8888] bg-white/30 backdrop-blur-3xl rounded-3xl py-3 px-4 shadow-2xl"
          >
            {Object.entries(stepValidation)
              .slice(0, Object.entries(stepValidation).length - 1)
              .map(([key, value]) => (
                <button
                  type="button"
                  key={key}
                  className={clsx(
                    "text-xs md:text-sm py-2 flex justify-between items-center w-full"
                  )}
                  onClick={() => handleScrollToStep(Number(key))}
                >
                  <span className="mr-3">{stepDict[Number(key)]}</span>{" "}
                  {value ? (
                    <span className="animate-blue-purple-pink-cycle-animation-slow rounded-full h-[30px] w-[30px] centered-flex-col">
                      <IonIcon
                        className="text-white text-lg"
                        icon={checkmark}
                      />
                    </span>
                  ) : (
                    <span className="bg-black rounded-full h-[30px] w-[30px] centered-flex-col">
                      <IonIcon className="text-white text-lg" icon={close} />
                    </span>
                  )}
                </button>
              ))}

            <button
              type="button"
              className={clsx(
                formHasErrors && "pointer-events-none opacity-50",
                "text-xs mt-2 md:text-sm w-full flex-1 py-2 flex justify-center items-center bg-primary rounded-full"
              )}
              onClick={() => handleScrollToStep(10)}
            >
              <span
                className={clsx(!formHasErrors && formEnded && "animate-pulse")}
              >
                GO!
              </span>

              <FaSpacer size="small" />

              <IonIcon icon={trophy} />
            </button>
          </div>,
          document.body
        )}
    </>
  )
}
