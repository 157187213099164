import { IonChip } from "@ionic/react"
import FaButton from "@workoutgen/design-system/FaButton"
import FaGlitchTitle from "@workoutgen/design-system/FaGlitchTitle"
import FaHomeAnimation from "@workoutgen/design-system/FaHomeAnimation"
import FaInfos from "@workoutgen/design-system/FaInfos"
import FaVerticalTitle from "@workoutgen/design-system/FaVerticalTitle"
import clsx from "clsx"
import { APP_ROUTES } from "../../../commons/constants"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"

const HomePage = () => {
  return (
    <FaPageLayout
      padding={false}
      paddingHorizontal
      content={
        <>
          <header
            className={clsx(
              "relative min-h-dvh centered-flex-col",
              "transition-all duration-1000 ease-in-out"
            )}
          >
            <section
              className={clsx(
                "centered-flex-col gap-2 lg:gap-3",
                "small-phone:max-w-xl max-w-3xl mx-auto"
              )}
            >
              <FaHomeAnimation />

              <FaGlitchTitle text="WKG." />

              <div
                className={clsx(
                  "flex flex-wrap md:flex-nowrap items-center justify-center gap-1",
                  "small-phone:gap-1 md:gap-3"
                )}
              >
                {["Gratuit", "Sur mesure", "Suivi IA"].map((item) => (
                  <IonChip
                    key={item}
                    className={clsx(
                      "px-4 py-4 md:px-5 md:py-4",
                      "small-phone:text-sm text-sm md:text-base bg-secondary/5"
                    )}
                  >
                    {item}
                  </IonChip>
                ))}
              </div>

              <p className="text-subtitle font-bold max-w-[600px] text-center text-sm lg:text-base small-phone:my-1 my-2">
                WorkoutGen la borne en libre service qui génère des programmes
                de musculation et un suivi gratuit assisté par intelligence
                artificielle
              </p>

              <div className="relative flex items-center justify-center flex-col gap-2">
                <FaButton
                  size="large"
                  className={clsx(
                    "animate-blue-purple-pink-cycle-animation-slow",
                    "hover:animate-blue-purple-pink-cycle-animation-fast"
                  )}
                  to={APP_ROUTES.GENERATE_WKG_WORKOUT}
                  animated
                >
                  Commencer
                </FaButton>

                <FaButton size="large" to={APP_ROUTES.LOGIN}>
                  Connexion
                </FaButton>

                <a
                  className={clsx(
                    "text-black font-title small-phone:text-xs mt-3 lg:mt-5 text-sm"
                  )}
                  href={import.meta.env.VITE_WORKOUTGEN_LANDING_URL}
                >
                  En savoir plus
                </a>
              </div>
            </section>

            <FaVerticalTitle />
          </header>

          <aside className="absolute bottom-6 left-8">
            <FaInfos version={import.meta.env.VITE_WORKOUTGEN_VERSION} />
          </aside>
        </>
      }
    />
  )
}

export default HomePage
