import { useEffect } from "react"
import { toast } from "react-toastify"
import { useRegisterSW } from "virtual:pwa-register/react"

export const useUpdateApp = () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered: " + r)
    },
    onRegisterError(error) {
      console.log("SW registration error", error)
    }
  })

  const close = () => {
    setNeedRefresh(false)
  }

  const handleClick = async () => {
    if (!needRefresh) return

    await updateServiceWorker(true)

    close()
  }

  useEffect(() => {
    if (needRefresh)
      toast(
        <div className="centered-flex-col">
          <p className="text-black font-bold text-center lg:text-sm mb-2">
            Une nouvelle version de <br /> WorkoutGen est disponible !
          </p>

          <button className="px-3 py-5 text-sm rounded-full animate-blue-purple-pink-cycle-animation-fast">
            Mettre à jour
          </button>
        </div>,
        {
          onClick: () => handleClick(),
          autoClose: false,
          closeButton: false,
          position: "bottom-center"
        }
      )
  }, [needRefresh])
}
