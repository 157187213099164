interface GenerateWeekSlice {
  totalLength: number
  sliceLength: number
  startCounter: number
}

export const generateWeekSlice = ({
  totalLength,
  sliceLength,
  startCounter
}: GenerateWeekSlice) => {
  const result = []
  let counter = startCounter

  for (let i = 0; i < totalLength; i += sliceLength) {
    const end = Math.min(i + sliceLength, totalLength)
    const currentSliceLength = end - i

    const slice = Array.from({ length: currentSliceLength }, () => counter++)

    result.push(slice)
  }

  return result
}
