import { ExercisePlayerCard } from "@workoutgen/global-typings/kiosk-types";
import { useSwiper } from "swiper/react";
import FaCounter from "../../commons/FaCounter/FaCounter";

export interface FaPlayerUnitProps {
  exercise: ExercisePlayerCard;
  isCurrentSlide: boolean;
  isPlaying: boolean;
  handleTogglePlay: () => void;
}

const FaPlayerUnit = (props: FaPlayerUnitProps) => {
  const { exercise, isCurrentSlide, isPlaying, handleTogglePlay } = props;

  const { unit, reps } = exercise;

  const swiper = useSwiper();

  const handleCounterEnd = () => {
    swiper.slideNext();
  };

  return unit === "seconds" && isCurrentSlide ? (
    <>
      {!isPlaying ? (
        <div className="fixed z-10 backdrop-blur-md inset-0 bg-white/90 flex flex-col gap-4 items-center justify-center px-3">
          <p>
            Placez vous sur comme sur l'image{" "}
            <strong className="font-title">{exercise.name}</strong>, puis
            cliquez sur commencer
          </p>

          <div className="flex justify-center gap-5 w-[35vw] md:w-[40%]">
            <img
              className="w-full object-cover rounded-xl"
              src={exercise.move_start.url}
              alt={exercise.name}
            />

            <img
              className="w-full object-cover rounded-xl"
              src={exercise.move_end.url}
              alt={exercise.name}
            />
          </div>

          <button
            className="bg-black text-white px-5 py-6 rounded-full text-lg tracking-normal"
            onClick={handleTogglePlay}
          >
            Commencer
          </button>
        </div>
      ) : (
        <h3 className="text-7xl -tracking-[3px] flex items-end justify-center">
          <FaCounter leftTime={reps} onCounterEnd={handleCounterEnd} />
        </h3>
      )}
    </>
  ) : (
    <div>
      <h3 className="text-7xl lg:text-8xl 2x:text-9xl text-white ui-player-text-element">
        {reps}

        <span className="text-2xl xl:text-3xl">x</span>
      </h3>
    </div>
  );
};

export default FaPlayerUnit;
