import { ResponsiveRadialBar } from "@nivo/radial-bar";
import {
  calculateLevelProgressPercentage,
  maxLevelsFromSessions,
} from "../../../utils/calcLevel";
import FaAnimatedNumber from "../../typography/FaAnimatedNumber/FaAnimatedNumber";
import { useTimelineContext } from "../providers/TimelineProvider";

const dataColors = ["#fd4adf", "#496afc", "#985EFF"];

interface FaFollowupProps {
  isFutureSession: boolean;
  isCurrentSession: boolean;
}

interface StatDisplayProps {
  label: string;
  value: number;
  maxValue: number;
  color: string;
}

const StatDisplay = ({ label, value, maxValue, color }: StatDisplayProps) => (
  <div>
    <p className={`text-sm text-center mb-1 ${color}`}>{label}</p>

    <h6 className="text-center flex items-center text-4xl">
      <span>
        <FaAnimatedNumber value={value} />
      </span>
      <span className="text-xs">/</span>
      <span className="text-xs">
        <FaAnimatedNumber value={maxValue} />
      </span>
    </h6>
  </div>
);

export const FaFollowup = ({
  isFutureSession,
  isCurrentSession,
}: FaFollowupProps) => {
  const { userTracking, userWorkout, session } = useTimelineContext();
  const { sessionIndex } = session;
  const { sessionsCount } = userWorkout.metadata;

  const currentUserTracking = userTracking.find(
    (item) => item.session_index === sessionIndex,
  );

  const nextUserTracking = userTracking.find(
    (item) => item.session_index === sessionIndex + 1,
  );

  const localSessionIndex =
    nextUserTracking?.session_index ?? currentUserTracking?.session_index ?? 0;
  const localWkgPoints = currentUserTracking?.wkg_points ?? 0;
  const localLevel = nextUserTracking?.level ?? currentUserTracking?.level ?? 0;

  const progression = Math.ceil((localSessionIndex / sessionsCount) * 100);
  const totalPoints = 100 * sessionsCount;
  const progressionPoints = Math.ceil((localWkgPoints / totalPoints) * 100);
  const progressionLevel = calculateLevelProgressPercentage(
    localLevel,
    sessionsCount,
  );

  const hideData = isFutureSession || isCurrentSession;

  const chartDataItems = [
    {
      id: "level",
      value: !hideData ? progressionLevel : 0,
    },
    {
      id: "points",
      value: !hideData ? progressionPoints : 0,
    },
    {
      id: "progression",
      value: !hideData ? progression : 0,
    },
  ];

  const chartData = chartDataItems.map((item, index) => ({
    id: item.id,
    data: [{ x: String.fromCharCode(97 + index), y: item.value }],
  }));

  const maxLevel = maxLevelsFromSessions(sessionsCount);

  return (
    <>
      {!hideData ? (
        <div className="flex justify-around">
          <StatDisplay
            label="Progression"
            value={session.sessionIndex}
            maxValue={sessionsCount}
            color="text-[#985EFF]"
          />
          <StatDisplay
            label="Niveau"
            value={localLevel}
            maxValue={maxLevel}
            color="text-[#fd4adf]"
          />
        </div>
      ) : (
        <div className="h-[40px]" />
      )}

      <div className="relative w-full h-[250px]">
        <ResponsiveRadialBar
          data={chartData}
          colors={dataColors}
          valueFormat=" >-.2f"
          padding={0.15}
          endAngle={360}
          cornerRadius={60}
          innerRadius={0.3}
          padAngle={0}
          enableTracks={true}
          tracksColor="black"
          maxValue={100}
          enableRadialGrid={false}
          enableCircularGrid={false}
          isInteractive={false}
          layers={["tracks", "bars"]}
          motionConfig="slow"
        />
      </div>

      {!hideData ? (
        <div className="flex justify-center mt-3">
          <StatDisplay
            label="Points WKG"
            value={localWkgPoints}
            maxValue={sessionsCount * 100}
            color="text-[#496afc]"
          />
        </div>
      ) : (
        <div className="h-[40px]" />
      )}
    </>
  );
};
