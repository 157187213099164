import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonReorder,
  IonReorderGroup,
  ItemReorderEventDetail
} from "@ionic/react"
import { APP_ROUTES } from "@workoutgen/design-system/constants"
import FaSurface from "@workoutgen/design-system/FaSurface"
import FaPageLayout from "../../../commons/layouts/FaPageLayout/FaPageLayout"

export const TrainingSettings = () => {
  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    console.log("Dragged from index", event.detail.from, "to", event.detail.to)

    event.detail.complete()
  }

  return (
    <FaPageLayout
      header={
        <IonButtons slot="start">
          <IonBackButton
            defaultHref={APP_ROUTES.TIMELINE}
            text="Gérer mon parcours"
          />
        </IonButtons>
      }
      content={
        <article className="max-w-[500px] mx-auto flex flex-col gap-6">
          <section>
            <header className="mb-3">
              <h2 className="text-xl" id="organization">
                Organisation
              </h2>
            </header>

            <FaSurface>
              <IonList inset className="!m-0">
                <IonReorderGroup
                  disabled={false}
                  onIonItemReorder={handleReorder}
                >
                  <IonItem>
                    <IonLabel>Item 1</IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Item 2</IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Item 3</IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Item 4</IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Item 5</IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>
                </IonReorderGroup>
              </IonList>
            </FaSurface>
          </section>
        </article>
      }
    />
  )
}
