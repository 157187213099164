import { WorkoutPart } from "@workoutgen/global-typings/engine-types";
import FaButton from "../../commons/FaButton/FaButton";
import FaTitle from "../../commons/FaTitle/FaTitle";

import { IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import JSConfetti from "js-confetti";
import { useEffect } from "react";

const getNextWorkoutPart = (workoutPart: WorkoutPart) => {
  switch (workoutPart) {
    case "exercises":
      return "Abdominaux";
    case "stretching":
      return undefined;
    case "join_unlocking":
      return "Échauffement";
    case "warmup":
      return "Session principale";
    case "abds":
      return "Étirements";
  }
};

const getWorkoutPoints = (workoutPart: WorkoutPart) => {
  switch (workoutPart) {
    case "exercises":
      return 40;
    case "stretching":
      return 15;
    case "join_unlocking":
      return 15;
    case "warmup":
      return 15;
    case "abds":
      return 15;
  }
};

interface FaEndWorkoutPartProps {
  currentSlide: number;
  slideIndex: number;
  workoutPart: WorkoutPart;
  onEndSession: () => void;
}

const FaEndWorkoutPart = ({
  currentSlide,
  workoutPart,
  slideIndex,
  onEndSession,
}: FaEndWorkoutPartProps) => {
  useEffect(() => {
    if (currentSlide !== slideIndex) return;

    const jsConfetti = new JSConfetti();

    jsConfetti.addConfetti();
  }, [currentSlide, slideIndex]);

  const workoutPoints = getWorkoutPoints(workoutPart);

  return (
    <div className="flex flex-col items-center justify-around h-full">
      <IonIcon icon={checkmark} className="text-4xl" />

      <div className="flex flex-col justify-center items-center">
        <FaTitle size="large" text="Bravo" />

        <p className="my-6 text-subtitle">Block d'entraînement terminé !</p>

        <FaButton
          className="animate-blue-purple-pink-cycle-animation-fast"
          onClick={onEndSession}
          animated
        >
          Continuer
        </FaButton>
      </div>

      <header className="text-center">
        <h3 className="text-2xl flex items-center gap-1 tracking-tight">
          + {workoutPoints} WKG
        </h3>

        <p>Gagnés</p>
      </header>
    </div>
  );
};

export default FaEndWorkoutPart;
