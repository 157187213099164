import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import type { RootState } from "../../commons/redux/store"

interface PlayerState {
  currentSlide: number
  currentExerciseIndex: number
}

const initialState: PlayerState = {
  currentSlide: 0,
  currentExerciseIndex: 0
}

export const playerSlice = createSlice({
  name: "player",

  initialState,

  reducers: {
    setCurrentSlide: (state, action: PayloadAction<number>) => {
      state.currentSlide = action.payload
    },

    setCurrentExerciseIndex: (state, action: PayloadAction<number>) => {
      state.currentExerciseIndex = action.payload
    }
  }
})

export const { setCurrentSlide, setCurrentExerciseIndex } = playerSlice.actions

export const selectPlayer = (state: RootState): PlayerState => state.player

export default playerSlice.reducer
