import FaSelectButtons from "@workoutgen/design-system/FaSelectButtons"
import SelectButton from "@workoutgen/design-system/SelectButton"
import { type ActivityLevel } from "@workoutgen/global-typings/engine-types"
import { RegisterStepProps } from "@workoutgen/global-typings/kiosk-types"
import { useCallback } from "react"
import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setActivityLevel,
  setCurrentStep
} from "../../../redux/registerSlice"

export const ActivityLevelStep = (props: RegisterStepProps<SelectButton[]>) => {
  const { texts } = props

  const dispatch = useAppDispatch()

  const { activityLevel } = useAppSelector(selectRegisterForm)

  const handleActivityLevel = useCallback(
    (activityLevel: ActivityLevel): void => {
      if (activityLevel === undefined) return

      dispatch(setActivityLevel(activityLevel))

      dispatch(setCurrentStep(4))
    },
    [dispatch]
  )

  return (
    <FaSelectButtons<ActivityLevel>
      items={texts}
      currentValue={activityLevel}
      cols={2}
      onChange={handleActivityLevel}
    />
  )
}
