export enum FaProgressState {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface FaTrainingProgressProps {
  progress: FaProgressState;
  intensity: number;
  sessionIndex: number;
}
