import { APP_ROUTES } from "../../commons/constants"
import TimelinePage from "../pages/TimelinePage/TimelinePage"

const timelineRoutes = [
  {
    path: APP_ROUTES.TIMELINE,
    component: <TimelinePage />
  }
]

export default timelineRoutes
