import { IonIcon, IonRouterLink } from "@ionic/react";
import clsx from "clsx";
import { chevronForward } from "ionicons/icons";
import { ReactNode } from "react";

interface WrapperProps {
  href?: string;
  children: ReactNode;
}

const Wrapper = ({ href, children }: WrapperProps) =>
  href ? (
    <IonRouterLink
      className="relative"
      routerLink={href}
      routerDirection="forward"
    >
      {children}

      <div
        className={clsx(
          "absolute w-[35px] h-[35px] centered-flex-col transform",
          "top-1/2 -translate-y-1/2",
          "right-1",
        )}
      >
        <IonIcon className="text-2xl text-subtitle" icon={chevronForward} />
      </div>
    </IonRouterLink>
  ) : (
    <>{children}</>
  );

interface FaSurfaceProps {
  children: ReactNode;
  href?: string;
  className?: string;
}

const FaSurface = ({ children, className, href }: FaSurfaceProps) => {
  return (
    <Wrapper href={href}>
      <div
        className={clsx(
          "bg-secondary/5 rounded-2xl p-4 relative w-full",
          className,
        )}
      >
        {children}
      </div>
    </Wrapper>
  );
};

export default FaSurface;
