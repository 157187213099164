import { useIonRouter } from "@ionic/react"
import { APP_ROUTES } from "@workoutgen/design-system/constants"
import FaButton from "@workoutgen/design-system/FaButton"
import FaWorkoutGenerating from "@workoutgen/design-system/FaWorkoutGenerating"
import {
  ApiWKGWorkout,
  SessionType
} from "@workoutgen/global-typings/engine-types"
import clsx from "clsx"
import JSConfetti from "js-confetti"
import { useEffect, useMemo } from "react"
import { toast } from "react-toastify"
import {
  Profile,
  usePostGenerateTestWkgWorkoutMutation,
  WkgArgs
} from "../../../../api/WorkoutGenApi"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import { useWKGData } from "../../../../commons/hooks/useWKGData"
import { selectRegisterForm } from "../../../redux/registerSlice"

interface FinalStepProps {
  generating: boolean
  setGenerating: (generating: boolean) => void
}

export const FinalStep = ({ generating, setGenerating }: FinalStepProps) => {
  const [genTestWorkout] = usePostGenerateTestWkgWorkoutMutation()

  const router = useIonRouter()

  const {
    saveUserWorkout,
    saveUser,
    saveProfile,
    saveUserTracking,
    saveUserSettings
  } = useWKGData()

  const { stepValidation, currentStep, focusedStep, turnstile } =
    useAppSelector(selectRegisterForm)

  const registerIsValid = useMemo(
    () =>
      Object.values(stepValidation).every((step) => step === true) &&
      !!turnstile,
    [stepValidation, focusedStep, currentStep, turnstile]
  )

  const userFormData = useAppSelector(selectRegisterForm)

  const handleRegister = async () => {
    try {
      if (
        !userFormData.gender ||
        !userFormData.morphotype ||
        !userFormData.userLevel ||
        !userFormData.workoutTarget ||
        !userFormData.practice ||
        !userFormData.activityLevel ||
        !userFormData.acceptTerms ||
        !userFormData.availableDays
      ) {
        throw new Error("Missing user data")
      }

      const wkgArgs: Profile = {
        size: userFormData.size!,
        weight: userFormData.weight!,
        age: userFormData.age!,
        gender: userFormData.gender,
        morphotype: userFormData.morphotype,
        level: userFormData.userLevel,
        workoutTarget: userFormData.workoutTarget,
        practice: userFormData.practice,
        availableDays: userFormData.availableDays,
        non_available_equipments: [],
        activityLevel: userFormData.activityLevel,
        country: "fr",
        acceptTerms: userFormData.acceptTerms
      }

      const previewWorkoutResponse = await genTestWorkout({
        createTestWkgWorkoutRequestBody: {
          wkgArgs: {
            ...wkgArgs,
            non_available_equipments: []
          } as unknown as WkgArgs,
          turnstileToken: turnstile!
        }
      })

      if ("error" in previewWorkoutResponse)
        throw new Error(
          "Une erreur est survenue lors de la génération du programme"
        )

      const { data: workout } = previewWorkoutResponse

      if (!workout || !workout.workout?.sessions)
        throw new Error("No workout data")

      await saveUserSettings({
        speedSpeech: 1,
        playerTracking: {
          join_unlocking: 0,
          warmup: 0,
          exercises: 0,
          abds: 0,
          stretching: 0
        }
      })

      await saveProfile(wkgArgs)

      await saveUserWorkout(workout as unknown as ApiWKGWorkout)

      await saveUserTracking([
        {
          cycle_index: 1,
          week_index: 1,
          session_index: 1,
          wkg_points: 0,
          training_followup: {
            exercises: "todo",
            stretching: "todo",
            join_unlocking: "todo",
            warmup: "todo",
            abds: "todo",
            current_exercises_index: 0,
            current_abds_index: 0,
            current_join_unlocking_index: 0,
            current_stretching_index: 0,
            current_warmup_index: 0
          },
          session_type: workout.workout.sessions[0].sessionType as SessionType,
          countdown: new Date().toISOString(),
          achievement_rate: 0,
          level: 1
        }
      ])

      await saveUser({
        jwt: "testmode"
      })

      router.push(APP_ROUTES.TIMELINE)
    } catch (error) {
      console.error(error)

      toast.error("Une erreur est survenue lors de la génération du programme")

      setGenerating(false)
    }
  }

  const handleLaunchGeneration = async () => setGenerating(true)

  useEffect(() => {
    if (!registerIsValid) return

    const jsConfetti = new JSConfetti()

    jsConfetti.addConfetti()
  }, [registerIsValid])

  if (generating) return <FaWorkoutGenerating onFinished={handleRegister} />

  return (
    <div className="flex justify-center items-center relative" id="step-10">
      <div className="flex flex-col justify-center items-center">
        <div
          id="step-final"
          className={clsx(
            "text-center",
            registerIsValid && "up-down-animation"
          )}
        >
          <FaButton
            onClick={handleLaunchGeneration}
            disabled={!registerIsValid}
            animated={registerIsValid}
            className={clsx(
              "mt-5",
              registerIsValid && "animate-blue-purple-pink-cycle-animation-fast"
            )}
          >
            Créer mon programme
          </FaButton>
        </div>
      </div>
    </div>
  )
}
