import clsx from "clsx";
import styles from "./FaGlitchTitle.module.css";

interface FaGlitchTitleProps {
  text: string;
  big?: boolean;
  letterSpacing?: boolean;
}

export const FaGlitchTitle = (props: FaGlitchTitleProps) => {
  const { text, big, letterSpacing } = props;

  const stackClasses = [
    styles.homeTitleIndex1,
    styles.homeTitleIndex2,
    styles.homeTitleIndex3,
  ];

  return (
    <h1
      className={clsx(
        styles.homeTitleStack,
        "w-full m-auto text-center overflow-hidden",
      )}
    >
      {stackClasses.map((css, index) => (
        <span
          key={index}
          className={clsx(
            css,
            "font-title",
            big
              ? "text-[10rem] md:text-[11rem]"
              : "small-phone:text-[5.5rem] text-[7rem] lg:text-[8rem]",
            "leading-none",
            !letterSpacing && big && "tracking-[-12px] md:tracking-[-15px]",
            !letterSpacing &&
              !big &&
              "tracking-[-5px] small-phone:tracking-[-5px] md:tracking-[-10px]",
          )}
        >
          {text}
        </span>
      ))}
    </h1>
  );
};

export default FaGlitchTitle;
