import authenticationRoutes from "../../authentification/routes"
import profileRoutes from "../../journey/routes"
import playerRoutes from "../../player/routes"
import registerRoutes from "../../register/routes"
import settingsRoutes from "../../settings/routes"
import timelineRoutes from "../../timeline/routes"
import trainingPreviewRoutes from "../../training-preview/routes"

export const publicRoutes = [...authenticationRoutes, ...registerRoutes]

export const privateRoutes = [
  ...profileRoutes,
  ...timelineRoutes,
  ...trainingPreviewRoutes,
  ...playerRoutes,
  ...settingsRoutes
]
