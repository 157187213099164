import {
  ApiWKGWorkout,
  FinalWorkoutSession,
  UserTracking,
} from "@workoutgen/global-typings/engine-types";
import { createContext, ReactNode, useContext } from "react";

interface FaTimelineSlideState {
  userTracking: UserTracking[];
  lastUserTracking: UserTracking;
  session: FinalWorkoutSession;
  userWorkout: ApiWKGWorkout;
  currentDate: string;
  isCurrentSession: boolean;
  currentSlideIndex: number;
}

type TimelineContext = FaTimelineSlideState | undefined;

const TimelineContext = createContext<TimelineContext>(undefined);

export const TimelineProvider = ({
  userTracking,
  lastUserTracking,
  session,
  userWorkout,
  currentDate,
  isCurrentSession,
  currentSlideIndex,
  children,
}: FaTimelineSlideState & {
  children: ReactNode;
}) => {
  return (
    <TimelineContext.Provider
      value={{
        userTracking,
        lastUserTracking,
        session,
        userWorkout,
        currentDate,
        currentSlideIndex,
        isCurrentSession,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export const useTimelineContext = () => {
  const context = useContext(TimelineContext);

  if (!context)
    throw new Error(
      "useTimelineContext must be used within a TimelineProvider",
    );

  return context;
};
