import FaSelectButtons from "@workoutgen/design-system/FaSelectButtons"
import SelectButton from "@workoutgen/design-system/SelectButton"
import { Genders } from "@workoutgen/global-typings/engine-types"
import { RegisterStepProps } from "@workoutgen/global-typings/kiosk-types"
import { useCallback } from "react"
import { useAppDispatch } from "../../../../commons/hooks/useAppDispatch"
import { useAppSelector } from "../../../../commons/hooks/useAppSelector"
import {
  selectRegisterForm,
  setCurrentStep,
  setGender
} from "../../../redux/registerSlice"

export const GenderStep = (props: RegisterStepProps<Genders>) => {
  const { texts } = props

  const dispatch = useAppDispatch()

  const { gender } = useAppSelector(selectRegisterForm)

  const handleGendersChange = useCallback(
    (gender: Genders): void => {
      if (gender === undefined) return

      dispatch(setGender(gender))

      dispatch(setCurrentStep(2))
    },
    [dispatch]
  )

  return (
    <FaSelectButtons<Genders>
      items={texts as unknown as SelectButton[]}
      currentValue={gender}
      onChange={handleGendersChange}
    />
  )
}
